import React, { useEffect } from 'react';
import {
  Button,
  Grid,
  TextField,
} from '@material-ui/core';

import { generateHandleChange } from '../common/state-setter-generator';
import { DealershipGroup } from '../_models/DealershipGroups';

export interface DealershipGroupFormProps {
  dealershipGroupToEdit?: DealershipGroup;
  handleSubmit(dealershipGroup: DealershipGroup): void;
}

export default function DealershipGroupForm(props: DealershipGroupFormProps) {
  // primary state ////////////////////////////////////////////////////////////
  const [state, setState] = React.useState<DealershipGroup>({} as DealershipGroup);
  useEffect(() => {
    if (props.dealershipGroupToEdit != null)
      setState(props.dealershipGroupToEdit)
    else
      setState({} as DealershipGroup);
  }, [props.dealershipGroupToEdit]);

  // fetch stuff //////////////////////////////////////////////////////////////

  // handlers /////////////////////////////////////////////////////////////////
  const handleChange = generateHandleChange(state, setState);

  // JSX.Element preprocessing /////////////////////////////////////////////////
  
  // return ///////////////////////////////////////////////////////////////////
  return (
    <div className="dealership-groups-profile-form">
      <Grid className="form-container" spacing={2} container>
        <Grid item xs={4} className="label-margin">
          Group Name
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            margin="dense"
            onChange={(event: any) => handleChange('name')(event.target.value)}
            placeholder={'Dealership Group Name'}
            value={state.name || ''}
          />
        </Grid>
      </Grid>
      <Grid container justify="flex-end" direction="row">
        <Button
          variant="outlined"
          className="add-button"
          onClick={() => props.handleSubmit(state)}
        >
          Save
        </Button>
      </Grid>
    </div>
    /*
    <div className="dealership-groups-profile-form"> 
      <Grid spacing={2} container>
        <Grid item xs={4} className="label-margin">
          Name
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="nameField"
            placeholder="Name"
            margin="dense"
            onChange={(event) => handleChange('name')(event.target.value)}
            required
            value={state.name || ''}
          />
        </Grid>
      </Grid>
      <Grid container justify="flex-end" direction="row">
        <Button
          className="blue-button" 
          onClick={() => props.handleSubmit(state)}
          variant="outlined"
        >
          {props.dealershipGroupToEdit == null ? 'Create' : 'Edit'}
        </Button>
      </Grid>
    </div>
    */
  );
}
