import React, { useEffect } from 'react';
import { API } from 'aws-amplify';
import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import Dealership from '../_models/Dealership';
import { getAPIName } from '../config';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import {
  faEdit,
  faCalendarAlt,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCheck,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import TollAgency from '../_models/TollAgency';
library.add(
  faEdit as IconDefinition ,
  faCalendarAlt as IconDefinition,
  faAngleLeft as IconDefinition,
  faAngleRight as IconDefinition,
  faAngleDoubleLeft as IconDefinition,
  faAngleDoubleRight as IconDefinition,
  faCheck as IconDefinition,
  faTimes as IconDefinition
);

function compareTollAgencies(a: Dealership, b: Dealership) {
  return a.name > b.name ? 1 : ( a.name < b.name ? -1 : 0 )
}

export interface TollAgencySelectProperties {
  readonly tollAgencyId?: number;
  readonly onChangeTollAgency: (id: number) => void;
};

export default function TollAgencySelect(props: TollAgencySelectProperties) {

  const [tollAgencies, setTollAgencies] = React.useState<TollAgency[]>([]);

  useEffect(() => { getTollAgnecies(); }, []);

  async function getTollAgnecies() {
    let path = '/tollagencies';
    try {
      const response: any = await API.get(getAPIName(), path, {});
      setTollAgencies(response.body.sort(compareTollAgencies));
    } catch (e) {
      console.log(e);
    }
  }

  const handleTollAgencySelect = (e: React.ChangeEvent<{ name?: string; value: unknown; }>): void => {
    const tollAgencyId: number = parseInt(e.target.value as string, 10);
    props.onChangeTollAgency(tollAgencyId);
  };

  const toll_agency_id: number = props.tollAgencyId == null
    ? 0
    : props.tollAgencyId;

  const tollAgencyMenuItems = tollAgencies.map(d =>
    <MenuItem key={d.id} value={d.id}>
      {d.name || '—'}
    </MenuItem>
  );
  tollAgencyMenuItems.unshift(
    <MenuItem key={'0'} value={'0'}>
      Select Toll Agency
    </MenuItem>
  );
  
  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel shrink={false}>
        Toll Agency
      </InputLabel>
      {/* We were using "zero width space" AKA \\u200B as a hidden break here. */}
      {/* Adding explicit breaks until we fix with CSS */}
      <br />
      <br />
      <br />
      <Select
        onChange={handleTollAgencySelect}
        value={toll_agency_id}
      >
        {tollAgencyMenuItems}
      </Select>
    </FormControl>
  );
}
