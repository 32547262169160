import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import useDebounce from './helpers/useDebounce';
import { API } from 'aws-amplify';
import { getAPIName } from '../config';
import { QuickContractState } from '../_models/QuickContract';
interface Customer {
  emailaddress: string;
  firstname: string;
  lastname: string;
}
interface CustomerAutocompleteProps {
  quickContractState: QuickContractState;
  setQuickContractState: React.Dispatch<React.SetStateAction<QuickContractState>>;
  handleChange: (field: keyof QuickContractState) => (value: string) => void;
}

export const CustomerAutocomplete: React.FC<CustomerAutocompleteProps> = ({
  quickContractState,
  setQuickContractState,
  handleChange
}) => {
  const [customerSuggestions, setCustomerSuggestions] = useState<Customer[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const debouncedSearch = useDebounce(quickContractState.email, 500);

  useEffect(() => {
    const fetchCustomers = async () => {
      if (!debouncedSearch) {
        setCustomers([]);
        return;
      }

      try {
        const queryParams = new URLSearchParams({ 'search-term': debouncedSearch });
        const response = await API.get(getAPIName(), `/customers?${queryParams}`, {});
        setCustomers(response.body || []);
      } catch (error) {
        console.error('Error fetching customers:', error);
        setCustomers([]);
      }
    };

    fetchCustomers();
  }, [debouncedSearch]);

  const filterOptions = createFilterOptions<Customer>();

  const handleCustomerSelect = (customer: Customer | null) => {
    if (customer) {
      setQuickContractState((prev: QuickContractState) => ({
        ...prev,
        email: customer.emailaddress,
        firstName: customer.firstname,
        lastName: customer.lastname,
      }));
    }
  };

  return (
    <Autocomplete
      freeSolo
      options={customerSuggestions.length ? customerSuggestions : customers}
      getOptionLabel={(option: Customer) => 
        typeof option === 'string' ? option : option.emailaddress
      }
      onChange={(event, newValue) => { 
        if (newValue === null) {
          setQuickContractState((prev: QuickContractState) => ({
            ...prev,
            email: '',
            firstName: '', 
            lastName: '',
          }));
        } else if (typeof newValue === "string") {
          setQuickContractState((prev: QuickContractState) => ({
            ...prev,
            email: newValue,
            firstName: '',
            lastName: '',
          }));
        } else {
          handleCustomerSelect(newValue);
        }
      }}
      filterOptions={(options, { inputValue, getOptionLabel }) => {
        return inputValue ? filterOptions(options, { inputValue, getOptionLabel }) : [];
      }}
      renderOption={(option: Customer) => (
        `${option.emailaddress} - ${option.firstname ? option.firstname : ''} ${option.lastname ? option.lastname : ''}`
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          id="emailField"
          placeholder="Email"
          margin="dense"
          onChange={(event) => {
            const emailValue = event.target.value;
            handleChange('email')(emailValue);
            if (emailValue === '') {
              setQuickContractState((prev: QuickContractState) => ({
                ...prev,
                firstName: '',
                lastName: '',
              }));
              setCustomerSuggestions([]);
            }
          }}
          required
          value={quickContractState.email}
        />
      )}
    />
  );
};