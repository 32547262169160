import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import MerchantLogin from '../_models/MerchantLogin';
import React, { useEffect } from 'react';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { PaymentGateway } from '../_models/PaymentGateway';
import { generateHandleChange } from '../common/state-setter-generator';

interface MerchantLoginFormProps {
  readonly merchantLoginToEdit?: MerchantLogin;
  readonly handleSubmit: (tollAccount: MerchantLogin) => void;
  //tollAuthorities: Option[];
}

export const MerchantLoginForm: React.FC<MerchantLoginFormProps> = (
  props: MerchantLoginFormProps
) => {
  // primary state ////////////////////////////////////////////////////////////
  const [state, setState] = React.useState<MerchantLogin>({} as MerchantLogin);
  useEffect(() => {
    if (props.merchantLoginToEdit != null) setState(props.merchantLoginToEdit);
    else setState({ paymentgateway: PaymentGateway.none } as MerchantLogin);
  }, [props.merchantLoginToEdit]);

  // fetch stuff //////////////////////////////////////////////////////////////

  // handlers /////////////////////////////////////////////////////////////////
  const handleChange = generateHandleChange(state, setState);

  // JSX.Element preprocessing /////////////////////////////////////////////////

  console.log(`paymentgateway: ${state.paymentgateway}`);

  return (
    <div className="toll-account">
      <Grid container>
        <Grid item xs={4}>
          Payment Gateway
        </Grid>
        <Grid item xs={8}>
          <Select
            labelWidth={100}
            margin="dense"
            onChange={(event: any) =>
              handleChange('paymentgateway')(event.target.value)
            }
            required
            style={{ width: '100%' }}
            value={state.paymentgateway || PaymentGateway.none}
          >
            <MenuItem value={PaymentGateway.none}>No payment gateway</MenuItem>
            <MenuItem value={PaymentGateway.cenpos}>
              {PaymentGateway.cenpos}
            </MenuItem>
            <MenuItem value={PaymentGateway.tempus}>
              {PaymentGateway.tempus}
            </MenuItem>
            <MenuItem value={PaymentGateway.stripe}>
              {PaymentGateway.stripe}
            </MenuItem>
            <MenuItem value={PaymentGateway.windcave}>
               {PaymentGateway.windcave}
             </MenuItem>
          </Select>
          <br />
        </Grid>
      </Grid>

      {(state.paymentgateway !== PaymentGateway.stripe && state.paymentgateway !== PaymentGateway.windcave) && (        
        <Grid container spacing={2}>
          <Grid item xs={4}>
            Merchant ID
          </Grid>
          <Grid item xs={8}>
            <TextField
              margin="dense"
              onChange={(event) =>
                handleChange('merchantid')(event.target.value)
              }
              style={{ width: '100%' }}
              value={state.merchantid || ''}
            />
          </Grid>
        </Grid>
      )}

      {(state.paymentgateway !== PaymentGateway.stripe && state.paymentgateway !== PaymentGateway.windcave) && (        
        <Grid container spacing={2}>
          <Grid item xs={4}>
            Merchant Name
          </Grid>
          <Grid item xs={8}>
            <TextField
              margin="dense"
              onChange={(event) =>
                handleChange('merchantname')(event.target.value)
              }
              style={{ width: '100%' }}
              value={state.merchantname || ''}
            />
          </Grid>
        </Grid>
      )}

      {(state.paymentgateway !== PaymentGateway.stripe && state.paymentgateway !== PaymentGateway.windcave) && (        
        <Grid container spacing={2}>
          <Grid item xs={4}>
            Primary URL
          </Grid>
          <Grid item xs={8}>
            <TextField
              margin="dense"
              onChange={(event) =>
                handleChange('primaryurl')(event.target.value)
              }
              style={{ width: '100%' }}
              value={state.primaryurl || ''}
            />
          </Grid>
        </Grid>
      )}

      {state.paymentgateway !== PaymentGateway.stripe && (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            Username
          </Grid>
          <Grid item xs={8}>
            <TextField
              margin="dense"
              onChange={(event) => handleChange('username')(event.target.value)}
              required
              style={{ width: '100%' }}
              value={state.username || ''}
            />
          </Grid>
        </Grid>
      )}

      {state.paymentgateway !== PaymentGateway.stripe && (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            Password
          </Grid>
          <Grid item xs={8}>
            <TextField
              margin="dense"
              onChange={(event) => handleChange('password')(event.target.value)}
              required
              style={{ width: '100%' }}
              value={state.password || ''}
            />
          </Grid>
        </Grid>
      )}

      {state.paymentgateway === PaymentGateway.stripe && (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            Stripe Key
          </Grid>
          <Grid item xs={8}>
            <TextField
              margin="dense"
              onChange={(event) => handleChange('token')(event.target.value)}
              required
              style={{ width: '100%' }}
              value={state.token || ''}
            />
          </Grid>
        </Grid>
      )}

      <Grid container>
        <Grid item xs={4}></Grid>
        <Grid item xs={8}>
          <Button
            className="blue-button"
            onClick={() => props.handleSubmit(state)}
            type="submit"
            variant="outlined"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
