import {
  OptionsObject,
  SnackbarMessage,
} from 'notistack';

export default function handleApiErrors(
  e: any,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => OptionsObject['key'] | null,
  recordType: string = 'record',
): void {
  if (e.response != null) {
    switch (e.response.status) {
      case 400:
        if (e.response.data != null && e.response.data.error != null)
          enqueueSnackbar(`Error : ${e.response.data.error}`, { variant: 'warning' });
        else
          enqueueSnackbar(`Failed to save ${recordType}!`, { variant: 'warning' });
        break;
      case 401:
        enqueueSnackbar('You are unauthorized!', { variant: 'warning' });
        break;
      case 403:
        enqueueSnackbar('Access to this resource is forbidden!', { variant: 'warning' });
        break;
      case 404:
        enqueueSnackbar(e.response?.data?.error ? e.response.data.error : "Network Error, please try again.", { variant: 'warning' });
        break;
      default:
        enqueueSnackbar('Network Error, please try again.', { variant: 'error' });
    }
  }
  else
    enqueueSnackbar('Network Error, please try again.', { variant: 'error' });
  console.log(e);
};