import React from 'react';
import {
    MenuItem
  } from '@material-ui/core';

export const renderOptions = (options: any[], firstOptionText: string) => {
    let renderedOptions = options.map((option: any) => (
      <MenuItem key={option.id} value={option.id}>
        {option.name}
      </MenuItem>
    ));
    renderedOptions.unshift(
      <MenuItem key={'0'} value={0}>
        {firstOptionText}
      </MenuItem>
    );
    return renderedOptions;
  };
  
  export const renderDealershipOptions = (dealershipOptions: any) => {
    return renderOptions(dealershipOptions, 'Select Dealership');
  };
  
  export const renderDealershipGroupOptions = (dealershipOptions: any) => {
    return renderOptions(dealershipOptions, 'Select Dealership Group');
  };
