import * as React from 'react';
import { Router, RouteComponentProps } from '@reach/router';
import Contracts from './Pages/Contracts';
import ContractCreate from './Pages/ContractCreate';
import Dashboard from './Pages/Dashboard';
import DealershipGroups from './Pages/DealershipGroups';
import Dealerships from './Pages/Dealerships';
import Employees from './Pages/Employees';
import Fleet from './Pages/Fleet';
import HelpSupport from './Pages/HelpSupport';
import Invoices from './Pages/Invoices';
import PaperViolations from './Pages/PaperViolations';
import Reports from './Pages/Reports';
import Tolls from './Pages/Tolls';
import TollsReport from './Pages/TollsReport';
import Users from './Pages/Users';
import Vehicles from './Pages/Vehicles';
import { RouteDefinitions } from '../common/route-definitions';
import AuthorisePayment from "./Pages/AuthorisePayment";
import AuthorisePaymentSuccess from './Pages/AuthorisePaymentSuccess';
import AuthorisePaymentFail from './Pages/AuthorisePaymentFail';
import TollCreate from './Pages/AddTolls';
import TollsRates from './Pages/TollRates';
import SubscriptionInvoices from './Pages/SubscriptionInvoice';
import FinancialReport from './Pages/FinancialReport';
import ParkingTickets from './Pages/ParkingTickets';
import DealershipControls from './Pages/dealershipControls'

export const ApplicationRouter:React.FC<{userRole : string}> = ({userRole}) => (
  <Router primary={false}>
    <RouterPage
      path={RouteDefinitions.Contracts}
      pageComponent={<Contracts />}
    />
    <RouterPage
      path={RouteDefinitions.ContractCreate}
      pageComponent={<ContractCreate />}
    />
    <RouterPage
      path={RouteDefinitions.Dashboard}
      pageComponent={<Dashboard />}
    />
    {userRole === 'Admin' && (
      <React.Fragment>
        <RouterPage path={RouteDefinitions.DealershipGroups} pageComponent={<DealershipGroups />}/>
        <RouterPage path={RouteDefinitions.TollsReport} pageComponent={<TollsReport />} />
        <RouterPage path={RouteDefinitions.TollRate} pageComponent={<TollsRates />} />
        <RouterPage path={RouteDefinitions.DealershipControls} pageComponent={<DealershipControls />} />
      </React.Fragment>
    )}
    
    {(userRole === 'Admin' || userRole === 'Group') && (
      <React.Fragment>
        <RouterPage path={RouteDefinitions.Dealerships} pageComponent={<Dealerships />}/>
        <RouterPage path={RouteDefinitions.Users} pageComponent={<Users />} />
      </React.Fragment>
    )}
    <RouterPage
      path={RouteDefinitions.Employees}
      pageComponent={<Employees />}
    />
    <RouterPage path={RouteDefinitions.Fleet} pageComponent={<Fleet />} />
    <RouterPage
      path={RouteDefinitions.HelpSupport}
      pageComponent={<HelpSupport />}
    />
    <RouterPage path={RouteDefinitions.Invoices} pageComponent={<Invoices />} />
    <RouterPage
      path={RouteDefinitions.PaperViolations}
      pageComponent={<PaperViolations />}
    />
    <RouterPage path={RouteDefinitions.Reports} pageComponent={<Reports />} />
    <RouterPage path={RouteDefinitions.Tolls} pageComponent={<Tolls />} />
    <RouterPage path={RouteDefinitions.FinancialReport} pageComponent={<FinancialReport />} />
    <RouterPage path={RouteDefinitions.Vehicles} pageComponent={<Vehicles />} />
    <RouterPage path={RouteDefinitions.AuthorisePayment} pageComponent={<AuthorisePayment />} />
    <RouterPage path={RouteDefinitions.AuthorisePaymentSuccess} pageComponent={<AuthorisePaymentSuccess />} />
    <RouterPage path={RouteDefinitions.AuthorisePaymentFail} pageComponent={<AuthorisePaymentFail />} />
    <RouterPage path={RouteDefinitions.TollCreate} pageComponent={<TollCreate />} />
    <RouterPage path={RouteDefinitions.SubscriptionInvoices} pageComponent={<SubscriptionInvoices />} />
    <RouterPage path={RouteDefinitions.ParkingTickets} pageComponent={<ParkingTickets />} />
  </Router>
);

const RouterPage = (
  props: { pageComponent: JSX.Element } & RouteComponentProps
) => props.pageComponent;
