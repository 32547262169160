import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { VariantType } from 'notistack';
const useCustomSnak = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleCustomSnak = (description:string, variant:string) => {
    enqueueSnackbar(description, {
      variant: variant as VariantType,
      autoHideDuration: 20000,
      action: (key) => (
        <IconButton onClick={() => closeSnackbar(key)} style={{ color: 'white' }}>
          <FontAwesomeIcon icon={faClose} size="xs" />
        </IconButton>
      )
    });
  };
  return handleCustomSnak;
};

export default useCustomSnak;
