import React, { useEffect } from 'react';
import {
  AppBar,
  Button,
  Card,
  Divider,
  Grid,
  Tab,
  Toolbar,
  Typography,
} from '@material-ui/core';
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import UsersProfileForm from '../users-profile-form';
import { API } from 'aws-amplify';
import { getAPIName } from '../../config';
import { useSnackbar } from 'notistack';
import handleApiErrors from '../../common/handleApiErrors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    card: {
      padding: 60
    }
  })
);

export default function Users() {
  const { enqueueSnackbar } = useSnackbar();

  const [users, setUsers] = React.useState<any[]>([]);
  const [user, setUser] = React.useState<any>({});
  const [mode, setMode] = React.useState<string>('Create');

  const callOnce = true;
  useEffect(() => {
    getUsers();
  }, [callOnce]);

  const getUsers = () => {
    let apiName = getAPIName();
    let path = '/users';
    let myInit = {};
    API.get(apiName, path, myInit)
      .then(response => {
        const usersList = response.body;
        setUsers(usersList);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const editUser = (user: any) => {
    setMode('Edit');
    setUser(user);
  };

  const clearUser = () => {
    setUser({ email: '', name: '', phone_number: '', userType: user.userType });
    setMode('Create');
  };

  const createUser = async (newUser: any): Promise<void> => {
    let apiName = getAPIName();
    let path = '/users';
    try {
      const response = await API.post(apiName, path, { body: newUser });
      const usersList = response.body;
      enqueueSnackbar('User saved!', { variant: 'success' });
    } catch (e) { 
      handleApiErrors(e, enqueueSnackbar, 'user');
    }
  };

  const updateUser = async (existingUser: any): Promise<void> => {
    let apiName = getAPIName();
    let path = `/users/${existingUser.id}`;
    try {
      const response = await API.put(apiName, path, { body: existingUser });
      console.log('here are the users', response);
      const usersList = response.body;
      enqueueSnackbar('User saved!', { variant: 'success' });
    } catch (e) {
      handleApiErrors(e, enqueueSnackbar, 'user');
    }
  };

  const deleteUser = async (existingUser: any): Promise<void> => {
    let apiName = getAPIName();
    let path = `/users/${existingUser.id}`;
    try {
      const response = await API.del(apiName, path, { body: existingUser });

      setUsers(users.filter(function( obj ) {
        return obj.id !== existingUser.id;
      })); 
      
      enqueueSnackbar('User deleted!', { variant: 'success' });
    } catch (e) {
      handleApiErrors(e, enqueueSnackbar, 'user');
    }
  };

  const handleSubmit = (state: any) => {
    if (mode === 'Create') {
      createUser({
        ...state
      });
    } else {
      updateUser({
        ...state
      });
    }
  };

  const handleDelete = (state: any) => {
    deleteUser({
        ...state
      });
  };

  const userButtons = users.map(x =>
    <div key={x.id}>
      <Button
        className="button-label"
        type="button"
        disableFocusRipple={true}
        onClick={() => editUser(x)}
        color={user != null && x.id === user.id ? "primary" : undefined}
      >
        {x.email}
      </Button>
      <Divider />
    </div>
  );

  // render ///////////////////////////////////////////////////////////////////
  return (
    <div className="Users">
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Grid container>
            <Grid item xs={9}>
              <Typography variant={'h4'} align={'left'}>
                Users
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Button
                className="add-button"
                onClick={clearUser}
                size={'small'}
              >
                + Add
              </Button>
            </Grid>
          </Grid>
          <br />
          <Divider />
          {userButtons}
        </Grid>

        <Grid item md={6}>
          <Card>
            <AppBar position="static">
              <Toolbar>
                <Tab className="tab-label" label="Users"></Tab>
              </Toolbar>
            </AppBar>
            <UsersProfileForm
              mode={mode}
              user={user}
              handleSubmit={handleSubmit}
              handleDelete={handleDelete}
            />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
