import React, { ChangeEvent }  from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radiodiv: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    }
  }),
);

export interface RadioSearchOptions {
  readonly label: string;
  readonly value: string;
}

interface RadioFormSearchProps {
  readonly onSearchTypeChange: (searchType: RadioSearchOptions) => void;
  readonly radioSearchOptions: RadioSearchOptions[];
};

export default function RadioFormSearch(props: RadioFormSearchProps) {
  const classes = useStyles();

  const [ searchType, setSearchType ] = React.useState<RadioSearchOptions>(props.radioSearchOptions[0]);

  const handleSearchTypeChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const searchType: RadioSearchOptions = props.radioSearchOptions.filter(so => so.value === event.target.value)[0];
    setSearchType(searchType);
    props.onSearchTypeChange(searchType);
  };

  const searchOptions: JSX.Element[] = props.radioSearchOptions.map(so =>
    <FormControlLabel
      control={<Radio color="primary" />}
      key={so.value}
      label={so.label}
      value={so.value}
    />
  );

  return (
    <div className="RadioFormSearch">
        <Grid item xs={12} className="contracts-search">
        <Grid xs={12} className="radios">
          <RadioGroup
            className={classes.radiodiv}
            aria-label="top-form"
            flex-direction="row"
            name="top-form"
            onChange={handleSearchTypeChange}
            value={searchType.value}
          >
            {searchOptions}
          </RadioGroup>
        </Grid>
        </Grid>
    </div>
  );
}