import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import { API } from 'aws-amplify';
import { TollAccount } from '../_models/TollAccount';
import TollAgency from '../_models/TollAgency';
import { getAPIName } from '../config';
import { generateHandleChange } from '../common/state-setter-generator';

interface TollAccountFormProps {
  readonly tollAccountToEdit?: TollAccount;
  readonly handleSubmit: (tollAccount: TollAccount) => void;
  //tollAuthorities: Option[];
}

export const TollAccountForm: React.FC<TollAccountFormProps> = (props: TollAccountFormProps) => {
  // primary state ////////////////////////////////////////////////////////////
  const [state, setState] = React.useState<TollAccount>({} as TollAccount);
  useEffect(() => {
    if (props.tollAccountToEdit != null)
      setState(props.tollAccountToEdit)
    else
      setState({} as TollAccount);
  }, [props.tollAccountToEdit]);

  // fetch stuff //////////////////////////////////////////////////////////////
  // toll agencies
  const [tollAgencies, setTollAgencies] = useState<TollAgency[]>([]);
  const getTollAgencies = async (): Promise<void> => {
    try {
      const response: any = await API.get(getAPIName(), '/tollagencies', {});
      setTollAgencies(response.body);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => { getTollAgencies(); }, []);

  // handlers /////////////////////////////////////////////////////////////////
  const handleChange = generateHandleChange(state, setState);

  // JSX.Element preprocessing /////////////////////////////////////////////////
  const tollAgencyMenuItems: JSX.Element[] = tollAgencies.map(ta =>
    <MenuItem key={ta.id} value={ta.id}>
      {ta.name}
    </MenuItem>
  );
  tollAgencyMenuItems.unshift(
    <MenuItem key={0} value={0}>Select one...</MenuItem>
  );

  const scrapeIntervalMenuItems: JSX.Element[] = [
  <MenuItem key={0} value={0}>
    Select One...
  </MenuItem>, 
  <MenuItem key="1" value="daily">
    Daily
  </MenuItem>,
  <MenuItem key="2" value="frequent">
    15 Minutes
  </MenuItem>
  ]
  
  // render ///////////////////////////////////////////////////////////////////
  return (
    <div className="toll-account">
      <Grid container>
        <Grid item xs={4}>
          Toll Authority
        </Grid>
        <Grid item xs={8}>
          <Select
            id="TollAuthority"
            labelWidth={100}
            margin="dense"
            onChange={(event: any) =>
              handleChange('tollagencyid')(event.target.value)
            }
            style={{ width: '100%' }}
            value={state.tollagencyid || 0}
          >
            {tollAgencyMenuItems}
          </Select>
          <br />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4}>
          Scrape Interval
        </Grid>
        <Grid item xs={8}>
          <Select
            id="ScrapeInterval"
            labelWidth={100}
            margin="dense"
            onChange={(event: any) =>
              handleChange('scrapeinterval')(event.target.value)
            }
            style={{ width: '100%' }}
            value={state.scrapeinterval || 0}
          >
            {scrapeIntervalMenuItems}
          </Select>
          <br />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          Username
        </Grid>
        <Grid item xs={8}>
          <TextField
            id="TollUsernameField"
            margin="dense"
            onChange={event => handleChange('username')(event.target.value)}
            style={{ width: '100%' }}
            value={state.username || ''}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4}>
          Password
        </Grid>
        <Grid item xs={8}>
          <TextField
            id="TollPasswordField"
            margin="dense"
            onChange={event => handleChange('password')(event.target.value)}
            style={{ width: '100%' }}
            type="password"
            value={state.password || ''}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4}></Grid>
        <Grid item xs={8}>
          <Button
            className="blue-button"
            onClick={() => props.handleSubmit(state)}
            type="submit"
            variant="outlined"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
