import React, { useEffect } from 'react';
import {
  AppBar,
  Button,
  Card,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { getAPIName } from '../../config';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { TollAccountForm } from '../toll-account.form';
import { MerchantLoginForm } from '../merchant-login.form';
import MerchantLogin from '../../_models/MerchantLogin';
// import AddIcon from '@material-ui/icons/Add';
import DealershipForm from '../dealership.form';
import Dealership from '../../_models/Dealership';
import { API } from 'aws-amplify';
import TabPanel from '../tab-panel.component';
import { TollAccount } from '../../_models/TollAccount';
import { useSnackbar } from 'notistack';
import handleApiErrors from '../../common/handleApiErrors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    paper: {
      padding: 10
    }
  })
);

function compareDealerships(a: Dealership, b: Dealership) {
  if (a.name > b.name)
    return 1;
  if (b.name > a.name)
    return -1;
  return 0;
}

export default function DealershipsPage() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [ selectedDealership, setSelectedDealership ] = React.useState<Dealership>();
  const [ tab, setTab ] = React.useState<number>(0);

  // fetch stuff //////////////////////////////////////////////////////////////
  // dealerships
  const [ dealerships, setDealerships ] = React.useState<Dealership[]>([]);
  const getDealerships = async () => {
    try {
      const response: any = await API.get(getAPIName(), '/dealerships', {});
      setDealerships(response.body.sort(compareDealerships));
    } catch (e) {
      handleApiErrors(e, enqueueSnackbar);
    }
  }
  useEffect(() => { getDealerships(); }, []);
  

  // merchant login
  const [ merchantLogin, setMerchantLogin ] = React.useState<MerchantLogin>();
  const getMerchantLogin = async (): Promise<void> => {
    if (selectedDealership == null || selectedDealership.merchant_login_id == null) {
      setMerchantLogin(undefined);
      return;
    }

    try {
      const response: any = await API.get(getAPIName(), `/merchantlogins/${selectedDealership.merchant_login_id}`, {});
      setMerchantLogin(response.body);
    } catch (e) {
      handleApiErrors(e, enqueueSnackbar);
    }
  }
  useEffect(() => { getMerchantLogin() }, [selectedDealership]);

  // toll account
  const [ tollAccount, setTollAccount ] = React.useState<TollAccount>();
  const getTollAccount = async (): Promise<void> => {
    if (selectedDealership == null) {
      setTollAccount(undefined);
      return;
    }

    try {
      const response: any = await API.get(getAPIName(), `/tollaccounts?dealershipid=${selectedDealership.id}`, {});
      if (response.totalRecordCount == null || response.totalRecordCount <= 0) {
        setTollAccount(undefined);
        return;
      }

      response.body.sort((a: TollAccount, b: TollAccount) => a.id > b.id ? 1 : (b.id > a.id ? -1 : 0));
      setTollAccount(response.body[0]);
    } catch (e) {
      handleApiErrors(e, enqueueSnackbar);
    }
  }
  useEffect(() => { getTollAccount() }, [selectedDealership]);
  
  // handlers /////////////////////////////////////////////////////////////////
  const handleDealershipButton = (dealership?: Dealership): void => {
    setSelectedDealership(dealership);
    setTab(0);
    setMerchantLogin(undefined);
  }
  const handleSubmitDealershipForm = async (dealership: Dealership): Promise<void> => {
    try {
      if (selectedDealership == null) {
        const response = await API.post(getAPIName(), '/dealerships', { body: dealership });
        setDealerships([...dealerships, response].sort(compareDealerships));
        setSelectedDealership(response);
      } else {
        const response = await API.put(getAPIName(), `/dealerships/${dealership.id}`, { body: dealership });
        updateDealershipInCollection(response);
      }
      enqueueSnackbar('Dealership saved!', { variant: 'success' });
    } catch (e) {
      handleApiErrors(e, enqueueSnackbar, 'dealership');
    }
  };
  const handleSubmitMerchantLoginForm = async (ml: MerchantLogin) => {
    if (selectedDealership == null)
      return;
    
    try {
      if (merchantLogin == null) {
        const response = await API.post(getAPIName(), '/merchantlogins', { body: ml });
        const updateDealershipResponse = await API.put(
          getAPIName(),
          `/dealerships/${selectedDealership.id}`,
          { body: {...selectedDealership, merchant_login_id: response.id } },
        );
        setMerchantLogin(response);

        updateDealershipInCollection(updateDealershipResponse);
        setSelectedDealership(updateDealershipResponse);
      } else {
        const response = await API.put(getAPIName(), `/merchantlogins/${merchantLogin.id}`, { body: ml });
        setMerchantLogin(response);
      }
      enqueueSnackbar('Merchant login saved!', { variant: 'success' });
    } catch (e) {
      handleApiErrors(e, enqueueSnackbar, 'payment gateway');
    }
  };
  const handleSubmitTollAccountForm = async (ta: TollAccount) => {
    if (selectedDealership == null)
      return;

    ta.dealershipid = selectedDealership.id;

    try {
      if (tollAccount == null) {
        const response = await API.post(getAPIName(), '/tollaccounts', { body: ta });
        setTollAccount(response);
      } else {
        const response = await API.put(getAPIName(), `/tollaccounts/${tollAccount.id}`, { body: ta });
        setTollAccount(response);
      }
      enqueueSnackbar('Toll agency saved!', { variant: 'success' });
    } catch (e) {
      handleApiErrors(e, enqueueSnackbar, 'toll account');
    }
  };
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    setTab(newValue);
  };

  const updateDealershipInCollection = (dealership: Dealership): void => {
    if (selectedDealership == null)
      return;
    const newDealerships: Dealership[] = [...dealerships].filter(d => d.id != selectedDealership.id);
    newDealerships.push(dealership);
    newDealerships.sort(compareDealerships);
    setDealerships(newDealerships);
  };

  // JSX.Element preprocessing /////////////////////////////////////////////////
  const dealershipButtons: JSX.Element[] = dealerships.map(d =>
    <div key={d.id}>
      <Button
        className="button-label"
        color={selectedDealership != null && d.id === selectedDealership.id ? "primary" : undefined}
        //disableFocusRipple={true}
        onClick={() => handleDealershipButton(d)}
        type="button"
      >
        {d.name || '—'}
      </Button>
      <Divider />
    </div>
  );

  const selectedDealershipLabel: JSX.Element = selectedDealership == null
    ? <></>
    : (
      <Typography
        className="profile-name"
        variant={'h6'}
        align={'center'}
      >
        {selectedDealership.name}
      </Typography>
    );

  // render ///////////////////////////////////////////////////////////////////
  return (
    <div className="Users">
      <Grid container spacing={2}>

        <Grid item md={6}>
          <div>
            <Grid container>
              <Grid item xs={9}>
                <Typography variant={'h4'} align={'left'}>
                  Dealerships
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Button
                  className="add-button"
                  onClick={() => handleDealershipButton(undefined)}
                  size={'small'}
                >
                  + Add
                </Button>
              </Grid>
            </Grid>
            <br />
            <Divider />
            {dealershipButtons}
          </div>
        </Grid>

        <Grid item md={6}>
          <Card>
            <AppBar position="static">
              <Tabs value={tab} onChange={handleTabChange}>
                <Tab id="tab-0" label="Store"/>
                <Tab
                  disabled={selectedDealership == null}
                  id="tab-1"
                  label="Toll Account"
                />
                <Tab
                  disabled={selectedDealership == null}
                  id="tab-1"
                  label="Payment Gw"
                />
              </Tabs>
            </AppBar>

            {selectedDealershipLabel}

            <TabPanel index={0} value={tab}>
              <DealershipForm
                dealershipToEdit={selectedDealership}
                handleSubmit={handleSubmitDealershipForm}
              />
            </TabPanel>
            <TabPanel index={1} value={tab}>
              <TollAccountForm
                handleSubmit={handleSubmitTollAccountForm}
                tollAccountToEdit={tollAccount}
              />
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <MerchantLoginForm
                handleSubmit={handleSubmitMerchantLoginForm}
                merchantLoginToEdit={merchantLogin}
              />
            </TabPanel>
          </Card>
        </Grid>

      </Grid>
    </div>
  );
}