import React, { useEffect, useState, ChangeEvent } from 'react';
import { API } from 'aws-amplify';
import { library } from '@fortawesome/fontawesome-svg-core';
import handleApiErrors from '../../common/handleApiErrors';
import { useSnackbar } from 'notistack';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getAPIName } from '../../config';
import SetupForm from '../Setup.form';
import DealershipSelect from '../dealership-select.component';
import validateEmail from '../../common/helper';
import HoldForm from '../HoldForm';
import { makeStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import {
    Paper,
    Typography,
    TextField,
    Grid,
    FormControl,
    Button
} from '@material-ui/core';
import ConfirmationPopup from '../ConfirmationPopup';
import moment from 'moment';
import {
    faEdit,
    faCalendarAlt,
    faAngleLeft,
    faAngleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faCheck,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
library.add(
    faEdit,
    faCalendarAlt,
    faAngleLeft,
    faAngleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faCheck,
    faTimes
);
const useStyles = makeStyles({
    type_select: {
        justifyContent: "center",
        marginTop: "15px"
    },
    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "8px",
        marginTop: "10px",
    },
    holdtitle: {
        fontSize: 'larger',
        marginTop: "10px"
    },
    card_element: {
        paddingBottom: '30px'

    },
    snackbarContent: {
        width: "1000px"
    }
});

const GreenSwitch = withStyles({
    switchBase: {
        color: red[300],
        '&$checked': {
            color: green[500],
        },
        '&$checked + $track': {
            backgroundColor: green[600],
        },
    },
    checked: {},
    track: {},
    disabled: {},
})(Switch);

export default function AuthorisePayment() {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [dealershipId, setdealershipId] = useState(0)
    const [dealershipLoading, setDealershipLoading] = useState(false)
    const [contractNumber, setContractNumber] = useState('')
    const [emailaddress, setEmailaddress] = useState('')
    const [holdMode, setHoldMode] = useState(false)
    const [dealersHasActiveHold, setDealersHasActiveHold] = useState({})
    const [defaultHold, setDefaultHold] = useState('')
    const [showConfirmation, setShowConfirmation] = React.useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false)
    const [createHoldClicked, setCreateHoldClicked] = useState(false)
    const [timelimit, setTimeLimit] = useState('')

    const handleContractNumberInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setContractNumber(event.target.value.toUpperCase());
    };
    const handleEmailInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setEmailaddress(event.target.value);
    };
    const resetform = () => {
        setdealershipId(0)
        setContractNumber('')
        setEmailaddress('')
        setHoldMode(false)
        setCreateHoldClicked(false)
    }
    const handleCheckoutPayment = () => {
        if (!dealershipId) {
            enqueueSnackbar('select the dealership', { variant: 'error' });
            return
        }
        if (!contractNumber) {
            enqueueSnackbar('enter a contract number', { variant: 'error' });
            return
        }
        if (!validateEmail(emailaddress)) {
            enqueueSnackbar('enter a valid email address', { variant: 'error' });
            return
        }
        else {
            if (!holdMode)
                getSetupIntent()
            else
                setCreateHoldClicked(true)
        }
        return
    }
    const [setupIntentClientSecret, setSetupIntentClientSecret] = useState('')
    const stripePromise = loadStripe('pk_live_51H7RsdJp6SVLhNZmDxSuV3O4BTAE5YkOV1RerH211atGSJHJpA5fiTCisDScJn1lLlocIYGjp6RPFqt0qbRbLtPn00ncjRd5nm');
    var opt = {
        clientSecret: setupIntentClientSecret,
        appearance: {/*...*/ },
    };
    const getDealersDetails = async (id: number) => {
        const response: any = await API.get(getAPIName(), `/dealerships/${id}`, {})
        setDealersHasActiveHold(response.body.dealershipcontrols.is_stripe_hold_active)
        setDefaultHold(response.body.dealershipcontrols.hold_amount)
        setDealershipLoading(false)
    };

    useEffect(() => {
        setSetupIntentClientSecret('')
        setHoldMode(false)
        setIsConfirmed(false)
        if (dealershipId) {
            setDealershipLoading(true)
            getDealersDetails(dealershipId)
        }
        else
            setDealersHasActiveHold(false)
    }, [dealershipId])

    const handleRadioButtonChange = () => {
        setIsConfirmed(false)
        setSetupIntentClientSecret('')
        if (!holdMode)
            toggleConfirmation()
        setHoldMode(false)
        setIsConfirmed(false)
        setCreateHoldClicked(false)
    }
    const getSetupIntent = async () => {
        try {
            const response: any = await API.post(getAPIName(), '/api/v1/payment/setup_customer',
                {
                    body: {
                        dealership_id: dealershipId,
                        referencenumber: contractNumber,
                        emailaddress: emailaddress,
                        paymentgateway: 'Stripe',
                        paymentmethod: ['card'],
                        pgw_version: 'V2'
                    }
                });
            if (response.error) {
                alert(response.error)
            }
            else {
                setSetupIntentClientSecret(response.setup_intent_clientSecret)
            }
        }
        catch (e) {
            handleApiErrors(e, enqueueSnackbar, 'setup intent client');
        }
    }
    const handleConfirm = () => {
        setIsConfirmed(true);
        setHoldMode(true)
    };
    const toggleConfirmation = () => {
        setTimeLimit(`Please make sure the customer returns the vehicle by ${moment().add(15, 'days').format('MMMM Do YYYY, h:mm:ss a')}. Otherwise use regular authorization (not hold)`)
        setShowConfirmation(!showConfirmation);
    }
    return (
        <div>
            <Grid container>
                <Grid item xs={9}>
                    <Typography variant="h4" align="left">
                        AUTHENTICATE PAYMENT FOR TOLLS
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={6} md={3}>
                <FormControl style={{ width: '100%' }}>
                    {/* We were using "zero width space" AKA \\u200B as a hidden break here. */}
                    {/* Adding explicit breaks until we fix with CSS */}
                    <br />
                    <br />
                    <DealershipSelect
                        dealershipId={dealershipId}
                        onChangeDealership={setdealershipId}
                    />

                </FormControl>
            </Grid>
            <Grid container xs={12} className="contracts-search">
                <Grid item xs={6} className="contracts-search">
                    <Typography variant="h3" align={'left'}>
                        <TextField
                            onChange={handleContractNumberInputChange}
                            placeholder="Rental Agreement / Contract Number"
                            style={{ marginTop: 60, width: '90%' }}
                            value={contractNumber}
                        />
                    </Typography>
                </Grid>
                <Grid item xs={6} className="contracts-search">
                    <Typography variant="h3" align={'left'}>
                        <TextField
                            onChange={handleEmailInputChange}
                            placeholder="Email Address"
                            style={{ marginTop: 60, width: '90%' }}
                            value={emailaddress}
                        />
                    </Typography>
                </Grid>
            </Grid>
            {
                (dealersHasActiveHold && !dealershipLoading) &&
                <Grid container xs={12} className={classes.type_select}>
                    <div className={classes.row} >
                        <Typography align="left" className={classes.holdtitle}>
                            Authorization Hold
                        </Typography>
                        <GreenSwitch
                            // disabled={(setupIntentClientSecret || createHoldClicked) ? true : false}
                            checked={holdMode}
                            size="small"
                            id="active"
                            name='active'
                            value={holdMode}
                            onChange={(event) => {
                                handleRadioButtonChange()
                            }}
                        />
                    </div>
                </Grid>

            }
            <Grid item xs={12}>
                <br />
                <br />
                {
                    ((!setupIntentClientSecret) && (!createHoldClicked)) &&
                    <Button variant="contained" color="primary" onClick={() => handleCheckoutPayment()} aria-label={holdMode ? "START HOLD" : "START AUTH"}>
                        START {holdMode ? 'HOLD ' : "AUTH"}
                    </Button>
                }
            </Grid>
            <Grid item xs={12} className="auth_block">
                <br />
                <br />
                {setupIntentClientSecret &&
                    <Elements stripe={stripePromise} options={opt} >
                        <SetupForm dealershipId={dealershipId} contractNumber={contractNumber} />
                    </Elements>
                }
            </Grid>

            <Grid item xs={12} className="hold_block">
                {(isConfirmed && createHoldClicked) &&
                    <Paper className={classes.card_element}>
                        <Elements stripe={stripePromise}>
                            <HoldForm dealershipId={dealershipId} contractNumber={contractNumber} emailaddress={emailaddress} resetform={resetform} defaultHold={defaultHold} />
                        </Elements>
                    </Paper>
                }
            </Grid>
            <div>
                <ConfirmationPopup confirmedAction={handleConfirm} isOpen={showConfirmation} text={timelimit} toggleOpen={toggleConfirmation} />
            </div>
        </div>
    );
}
