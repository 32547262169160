import React, { ChangeEvent } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Divider,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core';
import TopForm from '../TopForm';
import Table from '../Table';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    },
    dense: {
      marginTop: theme.spacing(2),
    },
    menu: {
      width: 200,
    }
  }),
);

interface PageState {
    startDate: Date | null;
    endDate: Date | null;
    dealershipSelection: string;
    statusSelection: string;
    variableInput: string;
  }

export default function Employees() {
    const classes = useStyles();
    const [pageState, setState] = React.useState<PageState>({
        startDate: new Date(),
        endDate: new Date(),
        dealershipSelection: 'Select one',
        statusSelection: 'Select one',
        variableInput: ''
      });
      const handleStartDateChange = (date: Date | null) => {
        setState({
          ...pageState,
          startDate: date
        });
      };
      const handleEndDateChange = (date: Date | null) => {
        setState({
          ...pageState,
          endDate: date
        });
      };
      const handleDealershipDropdown = (event: any) => {
        setState({
          ...pageState,
          dealershipSelection: event.target.value
        });
      };
      const handleStatusDropdown = (event: any) => {
        setState({
          ...pageState,
          statusSelection: event.target.value
        });
      };
      const variableInputUpdate = (event: ChangeEvent<HTMLInputElement>) => {
        setState({
          ...pageState,
          variableInput: event.target.value
        });
      };
    return(
        <div className="Employees">
        <Typography variant="h4" align="left">
          Employees
        </Typography>

        <Divider />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl style={{ width: '100%' }}>
              ​<InputLabel htmlFor="dealershipDropdown" shrink={false}>Dealership</InputLabel>​
              <Select
                value={pageState.dealershipSelection}
                onChange={handleDealershipDropdown}
                inputProps={{
                  name: 'dealership',
                  id: 'dealershipDropdown',
                  shrink: 'false'
                }}
              >
                <MenuItem value={'ten'}>Ten</MenuItem>
                <MenuItem value={'twenty'}>Twenty</MenuItem>
                <MenuItem value={'thirty'}>Thirty</MenuItem>
              </Select>
              ​
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl style={{ width: '100%' }}>
              ​<InputLabel htmlFor="statusDropdown" shrink={false}>Status</InputLabel>​
              <Select
                value={pageState.statusSelection}
                onChange={handleStatusDropdown}
                inputProps={{
                  name: 'status',
                  id: 'statusDropdown'
                }}
              >
                <MenuItem value={'ten'}>Ten</MenuItem>
                <MenuItem value={'twenty'}>Twenty</MenuItem>
                <MenuItem value={'thirty'}>Thirty</MenuItem>
              </Select>
              ​
            </FormControl>
          </Grid>
          ​
        </Grid>
        <div>{<Table rows={[]} columns={columns} />}</div>
        </div>
    );
}

const columns = [
    {
      Header: 'Actions',
      accessor: 'actions'
    },
    {
      Header: 'ID',
      accessor: 'id'
    },
    {
      Header: 'External ID',
      accessor: 'externalId'
    },
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: 'Email',
      accessor: 'email'
    },
    {
        Header: 'Type',
        accessor: 'type'
      },
    {
      Header: 'Status',
      accessor: 'status'
    }
  ];