import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    Button,
    Grid,
    TextField
  } from '@material-ui/core';
export default function EditVehiclePopup(props: any) {

  const [state, setState] = React.useState<any>(props.vehicle);

  const handleClose = () => {
    props.toggleOpen();
  };

  const handleChange = (e: any) => {
    setState({ ...state, [e.target.id]: e.target.value });
  };

  const handleSubmit = () => {
    // if (state.userType === undefined || state.userType === 'Admin') state.roleId = '0';
    // if (state.userType === undefined) state.userType = 'Individual';
    props.handleSave(state);
  };

  React.useEffect(() => {
    setState(props.vehicle);
  }, [props.vehicle]);

  return (
    <div>
      <Dialog
        open={props.isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Edit Vehicle Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {state?.dealership?.name} vehicle with ID {state?.id}
          </DialogContentText>
          <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          VIN
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="vin"
            margin="dense"
            onChange={handleChange}
            placeholder="vin"
            value={state.vin || ''}
            inputProps={{ maxLength: 32 }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          Transponder
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="transponder"
            margin="dense"
            onChange={handleChange}
            placeholder="transponder"
            value={state.transponder || ''}
            inputProps={{ maxLength: 20 }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          License Plate
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="licenseplate"
            margin="dense"
            onChange={handleChange}
            placeholder="plate"
            value={state.licenseplate || ''}
            inputProps={{ maxLength: 20 }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          State Code
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="state"
            margin="dense"
            onChange={handleChange}
            placeholder="state"
            value={state.state || ''}
            inputProps={{ maxLength: 5 }}
          />
        </Grid>
      </Grid>

        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        <Button onClick={handleSubmit} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}