import React from 'react';
import ReactTable, { Column } from 'react-table';
import 'react-table/react-table.css';

interface DisplayTableProps {
  rows: any[];
  columns: Column[];
}

export default function Table(props: DisplayTableProps) {
  console.log('the rows', props.rows, props.columns);
  return (
    <div>
      <ReactTable className="-striped -highlight" columns={props.columns} data={props.rows} />
    </div>
  );
}
