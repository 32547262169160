import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { States } from '../_models/states'
import { green, red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import DealershipSelect from '../components/dealership-select.component';
import { Switch } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { API } from 'aws-amplify';
import { getAPIName } from '../config';
import { generateHandleChange } from '../common/state-setter-generator'
import { useSnackbar } from 'notistack';
import handleApiErrors from '../common/handleApiErrors';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  TextField
} from '@material-ui/core';

const useStyles = makeStyles({
  formSpacing: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px"
  },
  autoCompleteMargin:{
    marginTop : '-10px'
  },
  transparentForm: {
    opacity: 0.5
  },
  loaderContainer:{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 5,
  }
});

interface AddVehiclePopupProps {
  open: boolean;
  onClose: () => void;
}
const AddVehiclePopup: React.FC<AddVehiclePopupProps> = ({ open, onClose }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const stateOptions = Object.keys(States).map((key) => ({
    title: States[key as keyof typeof States],
  }));
  const [openConfirmAlert, setOpenConfirmAlert] = React.useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const GreenSwitch = withStyles({
    switchBase: {
      color: red[300],
      '&$checked': {
        color: green[500],
      },
      '&$checked + $track': {
        backgroundColor: green[600],
      },
    },
    checked: {},
    track: {},
    disabled: {},
  })(Switch);

  interface Vehicle {
    dealershipid: number
    plate: string;
    state: string;
    transponder: string | null;
    vin: string | null;
    active: boolean;
  }

  interface DealershipState {
    readonly dealershipId: number;
    readonly searchInput: string;
  }

  const defaultState: DealershipState = {
    dealershipId: 0,
    searchInput: "",
  };
  const defaultVehicleState:Vehicle = {
      dealershipid: 0,
      plate: '',
      state: '',
      transponder: null,
      vin: null,
      active: true,
  }
  const [state, setState] = React.useState<DealershipState>(defaultState);
  const [vehicle, setVehicle] = useState<Vehicle>(defaultVehicleState);
  const handleChange = generateHandleChange<Vehicle>(vehicle,setVehicle);

  const handleConfirmAlertOpen = () => {
    if (vehicle.dealershipid === 0) {
      enqueueSnackbar('select the dealership', { variant: 'error' });
      return
    }
    if (vehicle.plate === '') {
      enqueueSnackbar('Enter licenseplate', { variant: 'error' });
      return
    }
    if (vehicle.state === '') {
      enqueueSnackbar('select the state', { variant: 'error' });
      return
    }
    setOpenConfirmAlert(true);
  };

  const handleConfirmAlertClose = () => {
    setOpenConfirmAlert(false);
  };

  const handleDealershipSelect = (id: number): void => {
    setState({
      ...state,
      dealershipId: id,
    });
    handleChange('dealershipid')(id);
  };

  const handleSubmit = async () => {
    try {
      let response;
      let addvehicle = {
        "dealershipid": vehicle.dealershipid,
        "licenseplate": vehicle.plate,
        "state": vehicle.state,
        "transponder": vehicle.transponder ? vehicle.transponder : null,
        "vin": vehicle.vin ? vehicle.vin : null,
        "active_status": vehicle.active,
        "make":null,
        "model":null,
        "year":null,
        "color":null,
        "type":null,
        "owner":null,
        "manually_managed":true
      }
      setIsLoading(true)
      response = await API.post(getAPIName(), '/vehicles', {
        body: addvehicle,
      });
      if(response.body.records?.length !== 0){
        setIsLoading(false)
        enqueueSnackbar('Vehicle Inserted', { variant: 'success' });
      }
      else{
        setIsLoading(false)
        enqueueSnackbar('Duplicate Vehicle Found', { variant: 'warning' });
      }
      setVehicle(defaultVehicleState)
      setState(defaultState)
    }
    catch (err) {
      setIsLoading(false)
      handleApiErrors(err, enqueueSnackbar);
    }
    handleConfirmAlertClose()
    onClose()
    setVehicle(defaultVehicleState)
    setState(defaultState)
    setIsLoading(false)
  }

  const handlePopupCancel = () => {
    setVehicle(defaultVehicleState)
    setState(defaultState)
    onClose()
  }

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="xs"
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText className={classes.formSpacing}>
            <div style={{ marginBottom: "-60px", fontSize: "18px", textAlign: "center" }}>Add Vehicle</div>
            {/* Form Starts */}
            <DealershipSelect
              dealershipId={state.dealershipId}
              onChangeDealership={handleDealershipSelect}
            />
            <TextField
              fullWidth
              id="plate"
              name='plate'
              value={vehicle.plate}
              onChange={(event)=>{
                handleChange('plate')(event.target.value.toUpperCase())
              }}
              margin="dense"
              placeholder="Plate"
              inputProps={{ maxLength: 20, style: { textTransform: 'uppercase' }, autoComplete:'off' }}
            />
            <Autocomplete
              className={classes.autoCompleteMargin}
              options={stateOptions}
              getOptionLabel={(option) => option.title}
              id="state"
              onChange={(event,value)=>{
                handleChange('state')(value.title.toUpperCase())
              }}
              disableClearable
              renderInput={(params) =>
                <TextField {...params} label="STATE" 
                  name='state'
                  value={vehicle.state} />}
            />
            <TextField
              fullWidth
              id="transponder"
              name='transponder'
              value={vehicle.transponder}
              onChange={(event)=>{
                handleChange('transponder')(event.target.value.toUpperCase())
              }}
              margin="dense"
              placeholder="Transponder (Optional)"
              inputProps={{ maxLength: 20, style: { textTransform: 'uppercase' }, autoComplete:'off' }}
            />
            <TextField
              fullWidth
              id="vin"
              name='vin'
              value={vehicle.vin}
              onChange={(event)=>{
                handleChange('vin')(event.target.value.toUpperCase())
              }}
              margin="dense"
              placeholder="VIN (Optional)"
              inputProps={{ maxLength: 20, style: { textTransform: 'uppercase' }, autoComplete:'off' }}
            />
            <div className={classes.row} >
              <p>Active</p>
              <GreenSwitch 
                checked={vehicle.active} 
                size="small" 
                id="active" 
                name='active' 
                value={vehicle.active} 
                onChange={(event)=>{
                  handleChange('active')(event.target.checked)
                }}
              />
            </div>
            {/* Form Ends */}
          </DialogContentText>       
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handlePopupCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAlertOpen}  color="primary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Confirm Save Dialog */}
      <Dialog
        open={openConfirmAlert}
        onClose={handleConfirmAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={`${isLoading ? classes.transparentForm : ''}`}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
                Do you want to Submit ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmAlertClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus disabled={isLoading}>
            Save
          </Button>
        </DialogActions>
        {isLoading && <div className={classes.loaderContainer}><CircularProgress /></div>}
      </Dialog>
    </div>
  );
}

export default AddVehiclePopup