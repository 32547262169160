export enum Timezones {
  Chicago = 'America/Chicago',
  Denver = 'America/Denver',
  Detroit = 'America/Detroit',
  Honolulu = 'Pacific/Honolulu',
  Indianapolis = 'America/Indiana/Indianapolis',
  Knox = 'America/Indiana/Knox',
  LosAngeles = 'America/Los Angeles',
  NewYork = 'America/New York',
  Pheonix = 'America/Pheonix',
}
