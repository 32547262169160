import React, { useEffect } from 'react';
import { API } from 'aws-amplify';
import fileDownload from 'js-file-download';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { library } from '@fortawesome/fontawesome-svg-core';
import TopFormSearch from '../TopFormSearch';
import { TopFormSearchOption } from '../TopFormSearch';
import Vehicle from '../../_models/Vehicle';
import { useSnackbar } from 'notistack';
import handleApiErrors from '../../common/handleApiErrors';
import DealershipSelect from '../dealership-select.component';
// import moment from 'moment';
import moment, { tz } from "moment-timezone";
import ReactTable, { Column,  SortingRule } from 'react-table';
import { getAPIName } from '../../config';
import EditVehiclePopup from '../../components/EditVehiclePopup'
import  AddVehiclePopup from '../../components/AddVehiclePopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Auth } from "aws-amplify";

import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  faEdit,
  faCalendarAlt,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCheck,
  faTimes,
  faSortNumericDown,
  faPencilAlt
} from '@fortawesome/free-solid-svg-icons';
library.add(
  faEdit,
  faCalendarAlt,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCheck,
  faTimes
);

const DEFAULT_PAGE_SIZE: number = 10;
const searchOptions: TopFormSearchOption[] = [
  { label: 'License Plate', value: 'license-plate' },
  { label: 'VIN', value: 'vin' },
];

interface PaginationState {
  readonly page: number;
  readonly pageSize: number;
  readonly sortingRules: SortingRule[];
}

interface VehiclesState {
  readonly dealershipId: number;
  readonly searchInput: string;
  readonly searchType: TopFormSearchOption;
}

const defaultPaginationState: PaginationState = {
  page: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  sortingRules: [{
    id: 'id',
    desc: false,
  }],
};

const defaultState: VehiclesState = {
  dealershipId: 0,
  searchInput: '',
  searchType: searchOptions[0],
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto'
    },
    table: {
      minWidth: 650
    },
    paper: {
      padding: 10
    },
    spacedButton: {
      marginRight: '2rem',
      backgroundColor: "#3f51b5",
      color: "white",
      border: '1px solid darkgray',
      '&:hover': {
        color: 'black',
        backgroundColor: 'rgba(0, 0, 0, 0.1)' // You can adjust the background color on hover as per your design
      }
    },
    gapBetweenSearchBtn:{
      marginBottom:'10px'
    }
  })
);

export default function Vehicles() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [csvIsLoading, setCsvIsLoading] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [paginationState, setPaginationState] = React.useState<PaginationState>(defaultPaginationState);
  const [state, setState] = React.useState<VehiclesState>(defaultState);

  const [showVehicleEdit, setshowVehicleEdit] = React.useState(false);
  const [vehicleToEdit, setvehicleToEdit] = React.useState({});

  const [openAddVehiclePopup, setOpenAddVehiclePopup] = React.useState(false);
  const handleClickOpenAddVehiclePopup = () => {
    setOpenAddVehiclePopup(true);
  };

  const handleCloseAddVehiclePopup = () => {
    setOpenAddVehiclePopup(false);
  };
  const toggleVehicleEditPopup = () => {
    setshowVehicleEdit(!showVehicleEdit);
  }
  
  const editVehicle = (vehicle: Vehicle) => {
    setvehicleToEdit(vehicle); 
    setshowVehicleEdit(true);
  }

  const updateVehicle = async (vehicle: any): Promise<void> => {
    let apiName = getAPIName();
    let path = `/vehicles`;
    try {
      var current_session_data = await Auth.currentAuthenticatedUser();
      var user_name = null
      if (current_session_data.attributes.name || current_session_data.attributes.email) {
        user_name = current_session_data.attributes.name || current_session_data.attributes.email
      }
      vehicle.maintain_logs = "true"
      vehicle.updated_by = user_name
      const response = await API.put(apiName, path, { body: vehicle });
      enqueueSnackbar('Vehicle changes saved!', { variant: 'success' });
      var newVehicleArray = vehicles;
      var updatedIndex = newVehicleArray.map(e => e.id).indexOf(vehicle.id);
      newVehicleArray[updatedIndex] = vehicle;
      setVehicles([newVehicleArray, newVehicleArray.length]);
      toggleVehicleEditPopup();
    } catch (e) {
      handleApiErrors(e, enqueueSnackbar, 'vehicle');
    }
  };

  // fetch stuff //////////////////////////////////////////////////////////////
  // vehicles
  const [[ vehicles, totalVehicleCount ], setVehicles] = React.useState<[ Vehicle[], number ]>([ [], 0 ]);
  const searchVehicles = async (): Promise<void> => {
    setIsLoading(true);
    const response = await getVehicles(true, {});
    setVehicles([response.body, response.totalRecordCount]);
    setIsLoading(false);
  }
  useEffect(() => { searchVehicles(); }, [paginationState.page, paginationState.pageSize, paginationState.sortingRules]);

  // handlers /////////////////////////////////////////////////////////////////
  const handleCsvButton = async (): Promise<void> => {
    setCsvIsLoading(true);
    const init = {
      headers: {
        accept: 'text/csv',
        timezone: moment.tz.guess(),
      },
      responseType: 'text',
    };
    const response = await getVehicles(false, init);
    // fileDownload(response, 'data.csv');
    const currentDate = moment().format('YYYYMMDD-hhmmss')
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(response);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "VehicleSummary" + currentDate + fileExtension);
    setCsvIsLoading(false);
  };
  const handleDealershipSelect = (id: number): void => {
    resetPaginationState();
    setState({
      ...state,
      dealershipId: id,
    });
  };
  const handleSearch = (): void => {
    searchVehicles();
  };
  const handleSearchInputChange = (searchInput: string): void => {
    resetPaginationState();
    setState({
      ...state,
      searchInput: searchInput.toUpperCase(),
    });
  };
  const handleSearchTypeChange = (searchType: TopFormSearchOption): void => {
    resetPaginationState();
    setState({
      ...state,
      searchType: searchType,
    });
  };
  const handleTableFetchData = (state: { page: number; pageSize: number, sorted: SortingRule[] }): void => {
    setPaginationState({
      ...paginationState,
      page: state.page,
      pageSize: state.pageSize,
      sortingRules: state.sorted,
    });
  }

  const resetPaginationState = (): void => {
    setPaginationState({
      ...paginationState,
      page: 0,
    });
  }

  const getVehicles = async (paginate: boolean = true, apiInit: any = {}): Promise<any> => {
    const queries: Array<{ name: string; value: string }> = paginate == false
      ? []
      : [
        { name: 'limit', value: `${paginationState.pageSize}` },
        { name: 'offset', value: `${paginationState.pageSize * paginationState.page}` },
      ];

    if (state.dealershipId != 0)
      queries.push({ name: 'dealershipid', value: `${state.dealershipId}`});
    if (state.searchInput !== '') {
      switch (state.searchType.value.toLowerCase()) {
        case 'license-plate':
          queries.push({ name: 'licenseplate', value: state.searchInput });
          break;
        case 'vin':
          queries.push({ name: 'right-vin', value: state.searchInput });
          break;
      }
    }

    // sort
    let i: number = 0;
    for (let sortingRule of paginationState.sortingRules)
      queries.push({ name: `orderBy-${i++}${sortingRule.desc === true ? '-desc' : ''}`, value: `${sortingRule.id}` });

    let path: string = '/vehicles';
    if (queries.length > 0) {
      const paramStrings: string[] = queries.map(q => `${encodeURIComponent(q.name)}=${encodeURIComponent(q.value)}`);
      path = `${path}?${paramStrings.join("&")}`;
    }

    try {
      return await API.get(getAPIName(), path, apiInit);
    } catch (e) {
      handleApiErrors(e, enqueueSnackbar);
      return [[], 0];
    }
  };

  // JSX.Element preprocessing /////////////////////////////////////////////////
  let pages: number = totalVehicleCount === 0 || paginationState.pageSize === 0
    ? 1
    : Math.trunc(totalVehicleCount / paginationState.pageSize)
  if (
    pages > 0
    && totalVehicleCount > paginationState.pageSize
    && totalVehicleCount % paginationState.pageSize > 0
  )
    ++pages;

  // Posted time, transaction time, license plate, VIN, location, amount, type (vehicle or parking), status 
  const columns: Column[] = [
    {
      accessor: 'id',
      Header: 'Id',
    },
    {
      accessor: (vehicle: Vehicle) => vehicle.licenseplate == null
        ? '—'
        : vehicle.licenseplate,
      Header: 'License Plate',
      id: 'licenseplate',
    },
    {
      accessor: (vehicle: Vehicle) => vehicle.state == null
        ? '—'
        : vehicle.state,
      Header: 'State',
      id: 'state',
    },
    {
      accessor: (vehicle: Vehicle) => vehicle.vin == null
        ? '—'
        : vehicle.vin,
      Header: 'VIN',
      id: 'vin',
    },
    {
      accessor: (vehicle: Vehicle) => vehicle.dealership == null || vehicle.dealership.name == null
        ? '—'
        : vehicle.dealership.name,
      Header: 'Dealership',
      id: 'dealership.name',
    },
    {
      accessor: (vehicle: Vehicle) => !vehicle.transponder
        ? '—'
        : vehicle.transponder,
      Header: 'Transponder',
      id: 'transponder',
    },
    {
      accessor: (vehicle: Vehicle) => vehicle.active_status === null
        ? '—'
        : vehicle.active_status?'true':'false',
      Header: 'Active Status',
      id: 'Active Status',
    },
    {
      accessor: (vehicle: Vehicle) => vehicle.insertedtimestamp === null
        ? '—'
        : moment(vehicle.insertedtimestamp).local().format('MM/DD/YYYY h:mm a'),
      Header: 'Inserted Time',
      id: 'Inserted Time',
    },
    {
      accessor: (vehicle: Vehicle) => !vehicle
        ? '—'
        : <Button
        variant="outlined"
        className="blue-button margin-right"
        onClick={() => editVehicle(vehicle)}
      >
        <FontAwesomeIcon icon={faPencilAlt} />
      </Button>,
      Header: "Edit",
      id: 'edit',
    },
    /*
    {
      accessor: (vehicle: Vehicle) => vehicle.posted_time == null
        ? '—'
        : moment(vehicle.posted_time).local().format('MM/DD/YYYY h:mm a'),
      Header: 'Posted',
      id: 'posted_time',
    },
    {
      accessor: (vehicle: Vehicle) => vehicle.transaction_time == null
        ? '—'
        : moment(vehicle.transaction_time).local().format('MM/DD/YYYY h:mm a'),
      Header: 'Transaction',
      id: 'transaction_time',
    },
    {
      accessor: (vehicle: Vehicle) => vehicle.vehicle == null || vehicle.vehicle.licenseplate == null
        ? '—'
        : vehicle.vehicle.licenseplate,
      Header: 'License Plate',
      id: 'vehicle.licenseplate',
    },
    {
      accessor: (vehicle: Vehicle) => vehicle.description_exit != null ? vehicle.description_exit : '—',
      Header: 'Exit Location',
      id: 'description_exit',
    },
    {
      accessor: (vehicle: Vehicle) => vehicle.vehicle == null || vehicle.vehicle.vin == null
        ? '—'
        : vehicle.vehicle.vin,
      Header: 'VIN',
      id: 'vehicle.vin',
    },
    {
      accessor: (vehicle: Vehicle) => vehicle.amount != null ? `$${(+vehicle.amount).toFixed(2)}` : '—',
      Header: 'Amount',
      id: 'amount',
    },
    {
      accessor: (vehicle: Vehicle) => vehicle.vehicle_type != null ? vehicle.vehicle_type.description : '—',
      Header: 'Type',
      id: 'vehicle_type_id',
    },
    {
      accessor: (vehicle: Vehicle) => vehicle.vehicle_status != null ? vehicle.vehicle_status.description : '—',
      Header: 'Status',
      id: 'vehicle_status_id',
    },
    */
  ];

  // render ///////////////////////////////////////////////////////////////////
  return (
    <div>
      <Grid container>
        <Grid item xs={9} sm={8} lg={9}>
          <Typography variant="h4" align="left">
            Vehicles
          </Typography>
        </Grid>
        <Grid item xs={3} sm={4} lg={3} >
          <Button
            className={classes.spacedButton}
            onClick={handleClickOpenAddVehiclePopup}
            size={'small'}
          >
            + Add
          </Button>
          <Button size="small" variant="outlined"  onClick={handleCsvButton}>
            {!csvIsLoading ? 'Download Excel' :
              <CircularProgress size={24} />
            }
          </Button>
        </Grid>
      </Grid>
      <div className="TopForm">
        <Divider />
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <DealershipSelect
              dealershipId={state.dealershipId}
              onChangeDealership={handleDealershipSelect}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <TopFormSearch
              onSearchInputChange={handleSearchInputChange}
              onSearchTypeChange={handleSearchTypeChange}
              searchInput={state.searchInput}
              searchOptions={[
                { label: 'License Plate', value: 'license-plate' },
                { label: 'VIN', value: 'vin' },
              ]}
            />
          </Grid>

          <Grid item xs={12} className={classes.gapBetweenSearchBtn}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
            >
              Search
            </Button>
          </Grid>

        </Grid>

      </div>
      <Grid container>
        <Grid item xs={12}>
          <ReactTable
            className="-striped -highlight"
            columns={columns}
            data={vehicles}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            defaultSorted={defaultPaginationState.sortingRules}
            loading={isLoading}
            manual
            onFetchData={handleTableFetchData}
            page={paginationState.page}
            pages={pages}
          />
        </Grid>
      </Grid>
      <EditVehiclePopup  vehicle={vehicleToEdit} isOpen={showVehicleEdit} text="Edit Vehicle" handleSave={updateVehicle} toggleOpen={toggleVehicleEditPopup}/>
      <AddVehiclePopup  open={openAddVehiclePopup} onClose={handleCloseAddVehiclePopup}/>
    </div>
  );
}
