import { Auth } from 'aws-amplify';

const environments = {
  localhost: 'Local',
  'dev.dggeq6y7mtf3z.amplifyapp.com': 'Dev',
  'dev.portal.aws.dfwtollaid.com': 'Dev',
  'www.dev.portal.aws.dfwtollaid.com': 'Dev',
  'dfauthpayment.dggeq6y7mtf3z.amplifyapp.com': 'Dev',
  'master.dggeq6y7mtf3z.amplifyapp.com': 'Prod',
  'portal.aws.dfwtollaid.com': 'Prod',
  'www.portal.aws.dfwtollaid.com': 'Prod',
};

export const getAPIName = () => {
  const host = document.location.hostname;
  return environments[host];
};

const awsmobile = {
  aws_project_region: 'us-east-2',
  aws_cognito_identity_pool_id:
    getAPIName() === 'Prod'
      ? 'us-east-2:dddd5e96-b246-4174-9d6c-82c1080acb15'
      : 'us-east-2:abb60f79-fb42-4851-a1ef-fcee2b609d1a',
  aws_cognito_region: 'us-east-2',
  aws_user_pools_id:
    getAPIName() === 'Prod' ? 'us-east-2_kVraIhISf' : 'us-east-2_ILlPkv549',
  aws_user_pools_web_client_id:
    getAPIName() === 'Prod'
      ? '4m317e356p9mnffavmlvd20lil'
      : '3c5g61lsrt3jq88o35b37orcef',
  oauth: {},
  aws_cloud_logic_custom: [
    {
      name: 'Local',
      endpoint: 'http://localhost:3001',
      region: 'us-east-2',
      custom_header: async () => {
        let session = await Auth.currentSession();
        let access_token = session.getAccessToken();
        let jwt = access_token.getJwtToken();

        return {
          access_token: jwt,
        };
      },
    },
    {
      name: 'Dev',
      endpoint: 'https://api.dev.aws.dfwtollaid.com',
      region: 'us-east-2',
      custom_header: async () => {
        let session = await Auth.currentSession();
        let access_token = session.getAccessToken();
        let jwt = access_token.getJwtToken();

        return {
          access_token: jwt,
        };
      },
    },
    {
      name: 'Prod',
      endpoint: 'https://api.aws.dfwtollaid.com',
      region: 'us-east-2',
      custom_header: async () => {
        let session = await Auth.currentSession();
        let access_token = session.getAccessToken();
        let jwt = access_token.getJwtToken();

        return {
          access_token: jwt,
        };
      },
    },
  ],
};

export default awsmobile;
