import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { getAPIName } from '../../config';
import {
  Grid,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  Divider,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  TextField,
  Card,
  CardContent,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles, fade } from '@material-ui/core/styles';
import moment from 'moment';
import DealershipSelect from '../dealership-select.component';
import ReactTable, { Column, SortingRule } from 'react-table';
import { Link } from '@reach/router';
import Dealership from '../../_models/Dealership';

// I am not proud of this long file...

interface ISAStatistics {
  totalSAContracts: number;
  totalFound: number;
  totalNotFound: number;
  tokenPercentage: number;
}

interface ISAStatisticsTitles {
  totalSAContracts: string;
  totalFound: string;
  totalNotFound: string;
  tokenPercentage: string;
}

const sAStatisticTitles: ISAStatisticsTitles = {
  totalSAContracts: 'Total Contracts',
  totalFound: 'Tokens Found',
  totalNotFound: 'Tokens Not Found',
  tokenPercentage: 'TAuth Compliance',
};

interface IStatistics {
  tollsPerContracts: number;
  chargedCount: number;
  declinedCount: number;
  totalContracts: number;
  totalInvoices: number;
  InvoiceWithoutAuthTokenCount: number;
  authTokenPercentage: number;
  pastAuthTokenPercentage: number;
  AuthCompliancetrend: number;
}

interface IStatisticsTitles {
  tollsPerContracts: string;
  chargedCount: string;
  declinedCount: string;
  totalContracts: string;
  totalInvoices: string;
  InvoiceWithoutAuthTokenCount: string;
  authTokenPercentage: string;
  pastAuthTokenPercentage: string;
  AuthCompliancetrend: string;
}

const statisticTitles: IStatisticsTitles = {
  tollsPerContracts: 'Average Tolls ($) / contract',
  chargedCount: 'Total Charged Amount',
  declinedCount: 'Total Declined Amount',
  totalContracts: 'Total Contracts',
  totalInvoices: 'Total Invoices',
  InvoiceWithoutAuthTokenCount: 'Invoices without AUTH token',
  authTokenPercentage: 'Auth Compliance',
  pastAuthTokenPercentage: 'Previous Auth Compliance',
  AuthCompliancetrend: 'Auth Compliance trend',
};

const styles = () => ({
  headerPadding: {
    paddingBottom: '2rem',
  },
  grow: {
    flexGrow: 1,
  },
});

const useStyles = makeStyles((theme) => ({
  headerPadding: {
    paddingBottom: '2rem',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    display: 'flex',
  },
  searchIcon: {
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: 200,
  },
  input: {
    color: 'white',
  },
  card: {
    // marginRight: theme.spacing(1),
    // marginLeft: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },
  subMenu: {
    backgroundColor: '#484a54',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
  select: {
    color: 'white',
  },
}));
function compareDealerships(a: Dealership, b: Dealership) {
  if (a.name > b.name)
    return 1;
  if (b.name > a.name)
    return -1;
  return 0;
}

function Dashboard(props: any) {
  const classes = useStyles();
  const [searchType, setSearchType] = useState<string>('');
  const [dealershipId, setDealershipId] = useState<number | undefined>(0);
  const [dealershipGroupId, setDealershipGroupId] = useState<
    number | undefined
  >(0);
  const [dashboard, setDashboard] = useState<IStatistics>({
    tollsPerContracts: 0,
    chargedCount: 0,
    declinedCount: 0,
    totalContracts: 0,
    totalInvoices: 0,
    InvoiceWithoutAuthTokenCount: 0,
    authTokenPercentage: 0,
    pastAuthTokenPercentage: 0,
    AuthCompliancetrend : 0,
  });

  const [saDetails, setSADetails] = useState<any>({
    totalSAContracts: 0,
    totalFound: 0,
    totalNotFound: 0,
    tokenPercentage: 0,
  });
  const [saRecords, setSARecords] = useState<any>({
    dealerships: [],
    saDetails: [],
  });
  const [saOption, setSAOption] = useState<any>('');
  const [dealershipOptions, setDealershipOptions] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<any>(
    moment().startOf('month').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState<any>(moment().format('YYYY-MM-DD'));

  const getDealerships = () => {
    let apiName = getAPIName();
    let path = '/dealerships';
    let myInit = {};
    API.get(apiName, path, myInit)
      .then((response) => {
        const results = response.body;
        setDealershipOptions(results.sort(compareDealerships));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDealershipGroups = () => {
    let apiName = getAPIName();
    let path = '/dealershipgroups';
    let myInit = {};
    API.get(apiName, path, myInit)
      .then((response) => {
        const results = response.body;
        setDealershipOptions(results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSearchTypeChange = (e: any) => {
    let type = e.target.value;
    setSearchType(type);
    if (type === 'dealership') {
      getDealerships();
    } else if (type === 'dealershipGroup') {
      getDealershipGroups();
    }
  };

  const fetchSADetails = async () => {
    let queries: Array<{ name: string; value: string }> = [];
    queries.push({
      name: 'startDt',
      value: `${startDate}`,
    });
    queries.push({
      name: 'endDt',
      value: `${endDate}`,
    });
    const paramStrings: string[] = queries.map(
      (q) => `${encodeURIComponent(q.name)}=${encodeURIComponent(q.value)}`
    );

    const path: string = `/dashboard/sa/${dealershipId}/${saOption}?${paramStrings.join(
      '&'
    )}`;
    try {
      const response = await API.get(getAPIName(), path, {});
      setSADetails({ ...response.body });
    } catch (err) {
      console.log('there was an error');
      console.log(err);
    }
  };

  const fetchSAForDealershipGroup = async () => {
    let queries: Array<{ name: string; value: string }> = [];
    queries.push({
      name: 'startDt',
      value: `${startDate}`,
    });
    queries.push({
      name: 'endDt',
      value: `${endDate}`,
    });
    const paramStrings: string[] = queries.map(
      (q) => `${encodeURIComponent(q.name)}=${encodeURIComponent(q.value)}`
    );

    const path: string = `/dashboard/sa/group/${dealershipGroupId}?${paramStrings.join(
      '&'
    )}`;
    try {
      const response = await API.get(getAPIName(), path, {});
      setSARecords(response.body);
    } catch (err) {
      console.log('there was an error');
      console.log(err);
    }
  };

  const fetchSAForDealership = async () => {
    let queries: Array<{ name: string; value: string }> = [];
    queries.push({
      name: 'startDt',
      value: `${startDate}`,
    });
    queries.push({
      name: 'endDt',
      value: `${endDate}`,
    });
    const paramStrings: string[] = queries.map(
      (q) => `${encodeURIComponent(q.name)}=${encodeURIComponent(q.value)}`
    );

    const path: string = `/dashboard/sa/dealership/${dealershipId}?${paramStrings.join(
      '&'
    )}`;
    try {
      const response = await API.get(getAPIName(), path, {});
      setSARecords(response.body);
    } catch (err) {
      console.log('there was an error');
      console.log(err);
    }
  };

  const fetchDashboardStatistics = async () => {
    let queries: Array<{ name: string; value: string }> = [];
    queries.push({
      name: 'startDt',
      value: `${startDate}`,
    });
    queries.push({
      name: 'endDt',
      value: `${endDate}`,
    });
    if (searchType === 'dealership') {
      if (dealershipId) {
        queries.push({
          name: `dealershipid`,
          value: `${dealershipId}`,
        });
      }
    }
    if (searchType === 'dealershipGroup') {
      if (dealershipGroupId) {
        queries.push({
          name: `dealershipgroupid`,
          value: `${dealershipGroupId}`,
        });
      }
    }
    const paramStrings: string[] = queries.map(
      (q) => `${encodeURIComponent(q.name)}=${encodeURIComponent(q.value)}`
    );
    const path: string = `/dashboard?${paramStrings.join('&')}`;
    try {
      const response = await API.get(getAPIName(), path, {});
      setDashboard({ ...dashboard, ...response.body });
    } catch (err) {
      console.log('there was an error');
      console.log(err);
    }
  };

  const handleStartDateChange = (e: any) => {
    setStartDate(e.target.value.toString());
  };

  const handleEndDateChange = (e: any) => {
    setEndDate(e.target.value.toString());
  };

  const handleDealershipOptionsDropdown = (e: any) => {
    if (searchType === 'dealership') {
      setDealershipId(e.target.value.toString());
    }
    if (searchType === 'dealershipGroup')
      setDealershipGroupId(e.target.value.toString());
  };

  const handleSAOptionsDropdown = (e: any) => {
    setSAOption(e.target.value.toString());
  };

  useEffect(() => {
    fetchDashboardStatistics();
  }, [dealershipId, dealershipGroupId, startDate, endDate]);

  useEffect(() => {
    fetchSAForDealership();
  }, [dealershipId, startDate, endDate]);

  useEffect(() => {
    fetchSAForDealershipGroup();
  }, [dealershipGroupId, startDate, endDate]);

  // useEffect(() => {
  //   fetchSADetails();
  // }, [saOption]);

  const formatMoney = (statistic: string, value: number) => {
    switch (statistic) {
      case 'totalSAContracts':
      case 'totalFound':
      case 'totalNotFound':
      case 'totalContracts':
      case 'totalInvoices':
        return new Intl.NumberFormat('en-US').format(value);
      case 'tollsPerContracts':
      case 'chargedCount':
      case 'declinedCount':
      case 'InvoiceWithoutAuthTokenCount':
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(value);
      case 'authTokenPercentage':
      case 'pastAuthTokenPercentage':
      case 'tokenPercentage':
        return new Intl.NumberFormat('en-US', {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(value);
      case 'AuthCompliancetrend':
        if (value > 0) { return '⬈ '}
        else if (value < 0) { return '⬊ '}
        else { return '- '}
    }
  };

  const renderDealershipOptions = (dealershipOptions: any) => {
    let options = dealershipOptions.map((dealership: any) => {
      return (
        <MenuItem key={dealership.id} value={dealership.id}>
          {dealership.name}
        </MenuItem>
      );
    });
    options.unshift(
      <MenuItem key={'0'} value={0}>
        Select Dealership
      </MenuItem>
    );
    return options;
  };

  const renderSAOptions = (saOptions: any) => {
    let options = saOptions.map((sa: any) => {
      return (
        <MenuItem key={sa.service_advisor_id} value={sa.service_advisor_id}>
          {sa.service_advisor_id}
        </MenuItem>
      );
    });
    options.unshift(
      <MenuItem key={'0'} value={''}>
        Select Service Advisor
      </MenuItem>
    );
    return options;
  };

  const renderDealershipGroupOptions = (dealershipOptions: any) => {
    let options = dealershipOptions.map((dealership: any) => {
      return (
        <MenuItem key={dealership.id} value={dealership.id}>
          {dealership.name}
        </MenuItem>
      );
    });
    options.unshift(
      <MenuItem key={'0'} value={0}>
        Select Dealership Group
      </MenuItem>
    );
    return options;
  };

  const SAColumns: Column[] = [
    {
      Header: 'SA Name',
      accessor: 'saName',
    },
    {
      Header: 'Auth Completion %',
      accessor: (record: any) => {
        return new Intl.NumberFormat('en-US', {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(record.tokenPercentage);
      },
      id: 'tokenPercentage',
      sortable: true,
    },
    {
      Header: 'Total Contracts',
      accessor: 'totalContracts',
    },
    {
      Header: 'Total Found',
      accessor: 'totalFound',
    },
    {
      Header: 'Total Not Found',
      accessor: 'totalNotFound',
    },
  ];

  const columns: Column[] = [
    {
      Header: 'Dealership Name',
      accessor: 'dealershipName',
    },
    {
      Header: 'Auth Completion %',
      accessor: (record: any) => {
        return new Intl.NumberFormat('en-US', {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(record.tokenPercentage);
      },
      id: 'tokenPercentage',
    },
    {
      Header: 'Total Contracts',
      accessor: 'totalContracts',
    },
    {
      Header: 'Total Found',
      accessor: 'totalFound',
      Cell: (row) => (<Link to={`contracts`}
        state={{
          dealershipId: row.original.dealershipId,
          authTokenStatusSelection: { name: "Found", value: "Found" },
          startDate: moment(startDate, moment.defaultFormat).toDate()
        }}>{row.value}</Link>)
    },
    {
      Header: 'Total Not Found',
      accessor: 'totalNotFound',
      Cell: (row) => (<Link to={`contracts`}
        state={{
          dealershipId: row.original.dealershipId,
          authTokenStatusSelection: { name: "Found", value: "Not Found" },
          startDate: moment(startDate, moment.defaultFormat).toDate()
        }}>{row.value}</Link>)
    },
  ];

  const renderSATable = () => {
    const { saDetails } = saRecords;
    return (
      <Grid container>
        <Grid xs={12}>
          <ReactTable
            className="-striped -highlight"
            columns={SAColumns}
            data={saDetails}
            defaultSorted={[
              {
                id: 'saName',
                desc: true,
              },
            ]}
            defaultPageSize={5}
          // manual
          />
        </Grid>
      </Grid>
    );
  };

  const renderDealershipTable = () => {
    const { dealerships } = saRecords;
    return (
      <Grid container>
        <Grid xs={12}>
          <ReactTable
            className="-striped -highlight"
            columns={columns}
            data={dealerships}
            defaultSorted={[
              {
                id: 'dealershipName',
                desc: true,
              },
            ]}
            defaultPageSize={5}
          // manual
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <div className="Dashboard">
      <div className={classes.grow}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.subMenu}
        >
          <Grid xs={12} md={3}>
            <div className={classes.search}>
              <Select
                displayEmpty
                value={searchType}
                onChange={handleSearchTypeChange}
                autoWidth={true}
                inputProps={{
                  classes: {
                    root: classes.select,
                  },
                }}
              >
                <MenuItem key="0" value={''}>
                  Select Search Type
                </MenuItem>
                <MenuItem key={'1'} value={'dealership'}>
                  Dealership
                </MenuItem>
                <MenuItem key={'2'} value={'dealershipGroup'}>
                  Dealership Group
                </MenuItem>
              </Select>
            </div>
          </Grid>

          {searchType === 'dealership' && (
            <Grid xs={12} md={3}>
              <div className={classes.search}>
                <Select
                  value={dealershipId}
                  onChange={handleDealershipOptionsDropdown}
                  autoWidth={true}
                  displayEmpty
                  inputProps={{
                    classes: {
                      root: classes.select,
                    },
                  }}
                >
                  {dealershipOptions &&
                    renderDealershipOptions(dealershipOptions)}
                </Select>
              </div>
            </Grid>
          )}
          {searchType === 'dealershipGroup' && (
            <Grid xs={12} md={3}>
              <div className={classes.search}>
                <Select
                  value={dealershipGroupId}
                  onChange={handleDealershipOptionsDropdown}
                  autoWidth={true}
                  displayEmpty
                  inputProps={{
                    classes: {
                      root: classes.select,
                    },
                  }}
                >
                  {dealershipOptions &&
                    renderDealershipGroupOptions(dealershipOptions)}
                </Select>
              </div>
            </Grid>
          )}

          <div className={classes.grow} />
          <Grid xs={12} md={3}>
            <TextField
              id="startDate"
              label="Start Date"
              type="date"
              onChange={handleStartDateChange}
              value={startDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
                className: classes.input,
              }}
              InputProps={{
                className: classes.input,
              }}
            />
          </Grid>
          <Grid xs={12} md={3}>
            <TextField
              id="endDate"
              label="End Date"
              type="date"
              onChange={handleEndDateChange}
              value={endDate}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
                className: classes.input,
              }}
              InputProps={{
                className: classes.input,
              }}
            />
          </Grid>
        </Grid>
        {/* </AppBar> */}
        <br />
        <br />
        <Divider />
        <br />

        <Grid container spacing={2}>
          {Object.keys(dashboard).map((statistic: string) => {
            return (
              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <Typography
                      // className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      {statisticTitles[statistic as keyof IStatisticsTitles]}
                    </Typography>
                    <Typography variant="h5" component="h2">
                      {formatMoney(
                        statistic,
                        dashboard[statistic as keyof IStatistics]
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>

        <br />
        <br />
        <Divider />
        <br />

        {!!saRecords.dealerships.length && (
          <>
            {renderDealershipTable()}
            <br />
            <br />
            <Divider />
          </>
        )}

        {!!saRecords.saDetails.length && renderSATable()}

        {!!saRecords.length && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant={'h6'} align={'left'}>
                  Service Advisor Information
                </Typography>
              </Grid>
              {/* <div className={classes.search}> */}
              <Select
                value={saOption}
                onChange={handleSAOptionsDropdown}
                autoWidth={true}
                displayEmpty
              // inputProps={{
              //   classes: {
              //     root: classes.select,
              //   },
              // }}
              >
                {saRecords && renderSAOptions(saRecords)}
              </Select>
              {/* </div> */}
            </Grid>
            <br />
            <br />
            {saDetails && (
              <Grid container spacing={2}>
                {Object.keys(saDetails).map((statistic: string) => {
                  return (
                    <Grid item xs={12} md={3}>
                      <Card>
                        <CardContent>
                          <Typography
                            // className={classes.title}
                            color="textSecondary"
                            gutterBottom
                          >
                            {
                              sAStatisticTitles[
                              statistic as keyof ISAStatisticsTitles
                              ]
                            }
                          </Typography>
                          <Typography variant="h5" component="h2">
                            {formatMoney(
                              statistic,
                              saDetails[statistic as keyof ISAStatistics]
                            )}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
