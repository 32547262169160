import React, { useState, useEffect } from 'react';
import { TextField, Switch, Grid, Button, TextFieldProps, SwitchProps } from '@material-ui/core';
import { API } from 'aws-amplify';
import { getAPIName } from '../../config';
import { DataGrid, GridColDef, GridSelectionModel, GridRowId, GridCellParams } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import handleApiErrors from '../../common/handleApiErrors';
import { ChangeEvent } from 'react';
import useCustomSnack from '../helpers/handleSnackbars'
import Joi from 'joi';
import { Select, MenuItem } from '@material-ui/core';

interface DealershipControl {
    id: number;
    dealershipid: number;
    send_financial_statements: boolean;
    is_stripe_auth_active: boolean;
    is_stripe_hold_active: boolean;
    hold_amount: string;
    is_tollaid_tollaccount?: boolean;
    is_tollaid_merchant?: boolean;
    penalty_paymentlink_amount?: number;
    penalty_paymentlinks_offset_start_days?: number;
    penalty_paymentlinks_offset_end_days?: number;
    [key: string]: string | number | boolean | null | undefined;
}
interface Dealership {
    id: number;
    name: string;
    interim_inovoice_days?: number | null;
    notify_contract_initiated?: boolean;
    invoice_offset_days?: number | null;
    invoice_offset_days_limit?: number;
    active_invoice?:boolean;
    active_payments?:boolean;
    tollFeeType?:string;
    tollFee?:number;
    tollFeeMax?:number;
    tollLineItem?:string;
    is_active?: boolean;
    subscription_mode?: string;
    dealershipcontrols: DealershipControl;
    lmsversion: string;
    dealershipgroupid: number;
    groupName: string;
}
interface DealershipGroup {
    dealerships: Dealership[];
    id: number;
    name: string;
}
interface FlagSetting {
    dealershipids: GridRowId[];
    dealership_flags: { [key: string]: string | number | boolean | null | 'RESET' }[];
    dealership_controls_flags: { [key: string]: string | number | boolean | null | 'RESET' }[];
}
const dealershipSchema = Joi.object({
    dealershipids: Joi.array().items(Joi.number().integer()).min(1).required(),
    dealership_flags: Joi.array().items(Joi.object({
        notify_contract_initiated: Joi.boolean().optional(),
        is_active: Joi.boolean().optional(),
        invoice_offset_days: Joi.number().integer().optional().allow(null),
        interim_inovoice_days: Joi.number().integer().optional().allow(null),
        active_invoice: Joi.boolean().optional(),
        active_payments: Joi.boolean().optional(),
        tollFeeType: Joi.string().allow(null).optional(),
        tollFee: Joi.number().allow(null).optional(),
        tollFeeMax: Joi.number().allow(null).optional(),
        tollLineItem: Joi.string().allow(null).optional()
    })).optional(),
    dealership_controls_flags: Joi.array().items(Joi.object({
        send_financial_statements: Joi.boolean().optional(),
        is_tollaid_tollaccount: Joi.boolean().optional(),
        is_tollaid_merchant: Joi.boolean().optional(),
    })).optional(),
});

const arraySchema = Joi.array().items(dealershipSchema).min(1).required();

// Custom styled Switch component
const GreenSwitch = withStyles({
    switchBase: {
        '&$checked': {
            color: '#4caf50',
        },
        '&$checked + $track': {
            backgroundColor: '#4caf50',
        },
    },
    checked: {},
    track: {},
})(Switch);

// Custom styled TextField component
const SmallTextField = withStyles({
    root: {
        '& .MuiInputBase-input': {
            fontSize: '0.75rem',
            padding: '0.5rem',
            marginTop: '20px'
        },
    },
})(TextField);

const useStyles = makeStyles({
    root: {
        '& .MuiDataGrid-cell': { fontSize: '0.75rem' },
        '& .MuiDataGrid-row': { minHeight: 30 }, 
        '& .MuiDataGrid-columnHeader': { 
            fontSize: '0.75rem',
            whiteSpace: 'normal', 
            wordBreak: 'break-word', 
            lineHeight: '1.2', 
        }
    },
    changedCell: {
        backgroundColor: 'rgb(196 203 57 / 24%);',
    },
    transparentForm: {
        opacity: 0.5
    },
});

interface GeneralizedTextFieldProps extends Omit<TextFieldProps, 'onChange'> {
    rowId: GridRowId;
    field: string;
    value: string | number | null;
    is_dealership_flag: boolean;
    handleInputChange: (id: GridRowId, fieldName: string, value: null | string | number | boolean, is_dealership_flag: boolean) => void;
    is_integer?: boolean;
    is_decimal?: boolean; 
    size?: 'small' | 'medium';
    style?: React.CSSProperties;
}

  const GeneralizedTextField: React.FC<GeneralizedTextFieldProps> = ({
    rowId,
    field,
    value,
    is_dealership_flag,
    handleInputChange,
    is_integer = false, 
    is_decimal = false, 
    size = 'small',
    style = { height: "70%" },
  }) => {
    return (
      <SmallTextField
        size={size}
        style={style}
        value={value ?? ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            let inputValue = e.target.value;
            let parsedValue: string | number | null = inputValue;
            if (is_decimal) {
                // Handle Decimal Inputs
                const decimalRegex = /^\d+(\.\d{0,2})?$/;
                if (inputValue == '') {
                  parsedValue = null;  // Allow empty input 
                } else if (decimalRegex.test(inputValue)) {
                  parsedValue = inputValue;  // Parse valid decimal
                } else {
                  parsedValue = value;  // Keep the previous valid value if input is invalid
                }
            } else if (is_integer) {
                // Handle integer inputs
                inputValue = inputValue.replace(/[^0-9]/g, ''); 
                parsedValue = inputValue === '' ? null : parseInt(inputValue);
            } else {
                // Default to string inputs
                parsedValue = inputValue.trim() === '' ? null : inputValue;
            }
            handleInputChange(rowId as number, field, parsedValue, is_dealership_flag);
        }}
      />
    );
  };

  interface GeneralizedSwitchProps extends Omit<SwitchProps, 'onChange'> {
    rowId: GridRowId;
    field: string;
    checked: boolean;
    handleInputChange: (id: GridRowId, fieldName: string, value: null | string | number | boolean, is_dealership_flag: boolean) => void;
    size?: 'small' | 'medium';
    is_dealership_flag: boolean;
  }

  const GeneralizedSwitch: React.FC<GeneralizedSwitchProps> = ({
    rowId,
    field,
    checked,
    handleInputChange,
    size = 'small',
    is_dealership_flag
  }) => {
    return (
      <GreenSwitch
        size={size}
        checked={checked}
        onChange={(e) => handleInputChange(rowId as number, field, e.target.checked, is_dealership_flag)}
      />
    );
  };
  interface GeneralizedSelectProps {
    rowId: GridRowId;
    field: string;
    value: string | number | null;
    handleInputChange: (id: GridRowId, fieldName: string, value: null | string | number, is_dealership_flag: boolean) => void;
    options: (string | number)[];
    is_dealership_flag: boolean;
    fullWidth?: boolean;
    style?: React.CSSProperties;
  }
  
  const GeneralizedSelect: React.FC<GeneralizedSelectProps> = ({
    rowId,
    field,
    value,
    handleInputChange,
    options,
    is_dealership_flag,
    fullWidth = true,
    style = { height: '70%', fontSize: '0.75rem' }
  }) => {
    return (
      <Select
        value={value || ''}
        onChange={(event) => handleInputChange(
          rowId as number,
          field,
          (event.target as HTMLInputElement).value == '' ? null : (event.target as HTMLInputElement).value,
          is_dealership_flag
        )}
        fullWidth={fullWidth}
        style={style}
      >
        <MenuItem value={''} style={{ fontSize: '0.75rem' }}>
          None
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option} value={option} style={{ fontSize: '0.75rem' }}>
            {option}
          </MenuItem>
        ))}
      </Select>
    );
  };

const DealershipControls: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const handleCustomSnack = useCustomSnack();
    const [dealershipGroups, setDealershipGroups] = useState<DealershipGroup[]>([]);
    const [rows, setRows] = useState<Dealership[]>([]);
    const [filteredRows, setFilteredRows] = useState<Dealership[]>([]);
    const [filteredGroups, setFilteredGroups] = useState<DealershipGroup[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [lms, setLms] = useState<string[]>([])
    const [selectedLms, setSelectedLms] = useState<string[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<DealershipGroup[]>([]);
    const [hasChanges, setHasChanges] = useState(false);
    const classes = useStyles();
    const [field_updates, setFieldUpdates] = useState<FlagSetting[]>([]);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [changedCells, setChangedCells] = useState<Set<string>>(new Set());
    const tollFeeTypeOptions = ['Daily', 'Flat Rate', 'Percent', 'Transactional'];
    const handleInputChange = (id: GridRowId, fieldName: string, value: null | string | number | boolean, is_dealership_flag: boolean) => {
        setFieldUpdates(prevSettings => {
            const newSettings = [...prevSettings];
            const isSelected = selectionModel.includes(id);
            let targetSetting = newSettings.find(setting =>
                isSelected ? setting.dealershipids.some(did => selectionModel.includes(did)) : setting.dealershipids.includes(id)
            );
            if (!targetSetting) {
                targetSetting = {
                    dealershipids: isSelected ? [...selectionModel] : [id],
                    dealership_flags: [],
                    dealership_controls_flags: [],
                };
                newSettings.push(targetSetting);
            }
            const originalRow = rows.find(row => row.id === id);
            if (!originalRow) return newSettings;
            let defaultValue;
            if (is_dealership_flag) {
                defaultValue = originalRow[fieldName as keyof Dealership];
            } else {
                defaultValue = originalRow.dealershipcontrols[fieldName as keyof DealershipControl];
            }
            const targetArray = is_dealership_flag ? targetSetting.dealership_flags : targetSetting.dealership_controls_flags;
            const existingFlagIndex = targetArray.findIndex(flag => Object.keys(flag)[0] === fieldName);
            if (value == defaultValue) {
                if (existingFlagIndex !== -1) {
                    targetArray[existingFlagIndex] = { [fieldName]: 'RESET' };
                } else {
                    targetArray.push({ [fieldName]: 'RESET' });
                }
            } else {
                if (existingFlagIndex !== -1) {
                    targetArray[existingFlagIndex] = { [fieldName]: value };
                } else {
                    targetArray.push({ [fieldName]: value });
                }
            }
            setHasChanges(newSettings.some(setting =>
                setting.dealership_flags.some(flag => Object.values(flag)[0] !== 'RESET') ||
                setting.dealership_controls_flags.some(flag => Object.values(flag)[0] !== 'RESET')
            ));
            return newSettings;
        });
        if (selectionModel.includes(id)) {
            setChangedCells(prev => {
                const newSet = new Set(prev);
                selectionModel.forEach(selectedId => newSet.add(`${selectedId}-${fieldName}`));
                return newSet;
            });
        } else {
            setChangedCells(prev => new Set(prev).add(`${id}-${fieldName}`));
        }
    };
    const handleSave = async () => {
        try {
            const cleanedFlagSettings = field_updates.map(setting => ({
                ...setting,
                dealership_flags: setting.dealership_flags.filter(flag => Object.values(flag)[0] !== 'RESET'),
                dealership_controls_flags: setting.dealership_controls_flags.filter(flag => Object.values(flag)[0] !== 'RESET')
            })).filter(setting => setting.dealership_flags.length > 0 || setting.dealership_controls_flags.length > 0);
            if (cleanedFlagSettings.length < 1) {
                handleCustomSnack('you have to make some changes before saving',  'warning');
                return;
            }
            const { error } = arraySchema.validate(cleanedFlagSettings, { abortEarly: false });
            if (error) {
                error.details.forEach((detail) => {
                    handleCustomSnack(detail.message, 'error' );
                });
                return;
            }
            setLoading(true);
            const response = await API.put(getAPIName(), '/api/v1/dealershipcontrols/', {
                body: cleanedFlagSettings,
            });
            if (response[0]?.status) {
                setLoading(false);
                setHasChanges(false)
                setSelectedGroups([])
                setSelectedLms([])
                await fetchDealershipGroups();
                handleCustomSnack('Flag Settings Updated Successfully', 'success');
                setFieldUpdates([])
                setSelectionModel([])
                setChangedCells(new Set());
            } else {
                setLoading(false);
                handleCustomSnack('Error Updating Flag Settings', 'warning');
            }
        } catch (err) {
            setLoading(false);
            handleApiErrors(err, enqueueSnackbar);
        }
    };

    const handleSelectionChange = (newSelectionModel: GridSelectionModel) => {
        const deselectedIds = selectionModel.filter(id => !newSelectionModel.includes(id));
        const newlySelectedIds = newSelectionModel.filter(id => !selectionModel.includes(id));
        const findChangedSetting = (ids: number[] | GridSelectionModel) => field_updates.find(setting =>
            setting.dealershipids.some(did => ids.includes(did as number)) &&
            (setting.dealership_flags.length > 0 || setting.dealership_controls_flags.length > 0)
        );
        const handleSelectionUpdate = (message: string, updatedModel: GridSelectionModel) => {
            handleCustomSnack(message, 'warning');
            setSelectionModel(updatedModel);
        };
        const allFlagsReset = (settings: FlagSetting) =>
            settings.dealership_flags.every(flag => Object.values(flag).every(value => value === 'RESET')) &&
            settings.dealership_controls_flags.every(flag => Object.values(flag).every(value => value === 'RESET'));
        const changedSetting = findChangedSetting(selectionModel);
        if (!changedSetting) {
            // Avoid Row Selection if user has already changed flags 
            const alreadyChangedSettings = findChangedSetting(newlySelectedIds);
            if (alreadyChangedSettings && !allFlagsReset(alreadyChangedSettings)) {
                handleSelectionUpdate(
                    'You cannot select row if you have already made changes to that row',
                    selectionModel.filter(id => !newlySelectedIds.includes(id))
                );
                return;
            }
        } else if (deselectedIds.length > 0) {
            // Avoid Deselection of row if user has made changes to selected dealers
            if (!allFlagsReset(changedSetting)) {
                handleSelectionUpdate('You cannot deselect rows after making changes to selected rows',
                    Array.from(new Set([...selectionModel, ...newlySelectedIds]))
                );
                return;
            }
        } else if (newlySelectedIds.length > 0) {
            // Avoid New Row Selection if user has made changes to selected dealers
            if (!allFlagsReset(changedSetting)) {
                handleSelectionUpdate('You cannot select rows after making changes to selected rows',
                    selectionModel.filter(id => !newlySelectedIds.includes(id))
                );
                return;
            }
        }
        // Allow deselection if user has not made changes to selected rows.
        if (deselectedIds.length > 0 && newlySelectedIds.length === 0) {
            setSelectionModel(newSelectionModel);
            setFieldUpdates(prevSettings =>
                prevSettings
                    .map(setting => {
                        const updatedDealershipIds = setting.dealershipids.filter(id => !deselectedIds.includes(id));
                        return updatedDealershipIds.length > 0 ? { ...setting, dealershipids: updatedDealershipIds } : null;
                    })
                    .filter(Boolean) as FlagSetting[]
            );
            return;
        }
        // Update FlagSettings Array
        setSelectionModel(newSelectionModel);
        setFieldUpdates(prevSettings => {
            const existingSetting = prevSettings.find(setting =>
                setting.dealershipids.some(did => newSelectionModel.includes(did))
            );
            if (existingSetting) {
                existingSetting.dealershipids = Array.from(new Set([...existingSetting.dealershipids, ...newSelectionModel]));
            } else {
                prevSettings.push({
                    dealershipids: [...newSelectionModel],
                    dealership_flags: [],
                    dealership_controls_flags: []
                });
            }
            return [...prevSettings];
        });
    };

    const columns: GridColDef[] = [
        {
            field: 'name', headerName: 'Name', width: 300, filterable: false,
            headerAlign: 'left',
            align: 'left',
        },
        {
            field: 'groupName',
            headerName: 'Group',
            width: 170,
            headerAlign: 'left',
            align: 'left',
            sortable: false,
        },
        {
            field: 'dealershipcontrols.send_financial_statements',
            headerName: 'Statements',
            description: 'Send Financial Statements',
            filterable: false,
            width: 95,
            headerAlign: 'left',
            sortable: false,
            align: 'left',
            renderCell: (params: GridCellParams) => (
                <div className={changedCells.has(`${params.id}-send_financial_statements`) ? classes.changedCell : ''} style={{ width: "100%" }}>
                    <GeneralizedSwitch
                        rowId={params.id}
                        field="send_financial_statements"
                        checked={params?.row?.dealershipcontrols?.send_financial_statements ?? false}
                        handleInputChange={handleInputChange}
                        is_dealership_flag={false}
                    />
                </div>
            )
        },
        {
            field: 'dealershipcontrols.is_tollaid_tollaccount',
            headerName: 'TA Toll',
            description: 'Is Tollaid Toll-Account',
            renderHeader: () => (
                <span style={{textAlign:'left'}}>
                  TA -<br />Toll
                </span>
            ),
            filterable: false,
            width: 60,
            headerAlign: 'left',
            align: 'left',
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <div className={changedCells.has(`${params.id}-is_tollaid_tollaccount`) ? classes.changedCell : ''} style={{ width: "100%" }}>
                    <GeneralizedSwitch
                        rowId={params.id}
                        field="is_tollaid_tollaccount"
                        checked={params?.row?.dealershipcontrols?.is_tollaid_tollaccount ?? false}
                        handleInputChange={handleInputChange}
                        is_dealership_flag={false}
                    />
                </div>
            )
        },
        {
            field: 'dealershipcontrols.is_tollaid_merchant',
            headerName: 'TA Bank',
            description: 'Is Tollaid Merchant',
            renderHeader: () => (
                <span style={{textAlign:'left'}}>
                  TA -<br />Bank
                </span>
            ),
            filterable: false,
            headerAlign: 'left',
            align: 'left',
            width: 60,
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <div className={changedCells.has(`${params.id}-is_tollaid_merchant`) ? classes.changedCell : ''} style={{ width: "100%" }}>
                    <GeneralizedSwitch
                        rowId={params.id}
                        field="is_tollaid_merchant"
                        checked={params?.row?.dealershipcontrols?.is_tollaid_merchant ?? false}
                        handleInputChange={handleInputChange}
                        is_dealership_flag={false}
                    />
                </div>
            )
        },
        {
            field: 'interim_inovoice_days',
            headerName: 'Interim Invoice Days',
            description: 'Interim Invoice Days',
            width: 100,
            renderHeader: () => (
                <span style={{textAlign:'left'}}>
                  Interim -<br />Invoice days
                </span>
            ),
            headerAlign: 'left',
            align: 'left',
            sortable: false,
            filterable: false,
            renderCell: (params: GridCellParams) => (
                <div className={changedCells.has(`${params.id}-interim_inovoice_days`) ? classes.changedCell : ''} style={{ width: "100%", height: "100%" }}>
                    <GeneralizedTextField 
                        rowId={params.id}
                        size='small'
                        style={{height:'70%'}}
                        field="interim_inovoice_days"
                        value={params?.row?.interim_inovoice_days}
                        is_dealership_flag={true}
                        is_integer={true}
                        handleInputChange={handleInputChange}
                    />
                </div>
            )
        },
        {
            field: 'notify_contract_initiated',
            headerName: 'Notify Customer',
            description: 'Notify Customer',
            renderHeader: () => (
                <span style={{textAlign:'left'}}>
                  Notify -<br />Customer
                </span>
            ),
            width: 80,
            headerAlign: 'left',
            align: 'left',
            sortable: false,
            filterable: false,
            renderCell: (params: GridCellParams) => (
                <div className={changedCells.has(`${params.id}-notify_contract_initiated`) ? classes.changedCell : ''} style={{ width: "100%" }}>
                    <GeneralizedSwitch
                        rowId={params.id}
                        field="notify_contract_initiated"
                        checked={params?.row?.notify_contract_initiated ?? false}
                        handleInputChange={handleInputChange}
                        is_dealership_flag={true}
                    />
                </div>
            )
        },
        {
            field: 'invoice_offset_days',
            headerName: 'Invoice Days',
            description: 'Invoice Days',
            renderHeader: () => (
                <span style={{textAlign:'left'}}>
                  Invoice -<br />Days
                </span>
            ),
            width: 70,
            headerAlign: 'left',
            align: 'left',
            filterable: false,
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <div className={changedCells.has(`${params.id}-invoice_offset_days`) ? classes.changedCell : ''} style={{ width: "100%" }}>
                    <GeneralizedTextField 
                        rowId={params.id}
                        size='small'
                        style={{height:'70%'}}
                        field="invoice_offset_days"
                        value={params?.row?.invoice_offset_days}
                        is_dealership_flag={true}
                        is_integer={true}
                        handleInputChange={handleInputChange}
                    />
                </div>
            )
        },
        {
            field: 'tollFee',
            headerName: 'Fees',
            description: 'Toll Fees',
            width: 60,
            headerAlign: 'left',
            align: 'left',
            filterable: false,
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <div className={changedCells.has(`${params.id}-tollFee`) ? classes.changedCell : ''} style={{ width: "100%" }}>
                    <GeneralizedTextField 
                        rowId={params.id}
                        size='small'
                        style={{height:'70%'}}
                        field="tollFee"
                        value={params?.row?.tollFee}
                        is_dealership_flag={true}
                        is_decimal={true}
                        handleInputChange={handleInputChange}
                    />
                </div>
            )
        },
        {
            field: 'tollFeeMax',
            headerName: 'Max Fees',
            description: 'Toll Fees Maximum',
            renderHeader: () => (
                <span style={{textAlign:'left'}}>
                  Max -<br />Fees
                </span>
            ),
            width: 80,
            headerAlign: 'left',
            align: 'left',
            filterable: false,
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <div className={changedCells.has(`${params.id}-tollFeeMax`) ? classes.changedCell : ''} style={{ width: "100%" }}>
                    <GeneralizedTextField 
                        rowId={params.id}
                        size='small'
                        style={{height:'70%'}}
                        field="tollFeeMax"
                        value={params?.row?.tollFeeMax}
                        is_dealership_flag={true}
                        is_decimal={true}
                        handleInputChange={handleInputChange}
                    />
                </div>
            )
        },
        {
            field: 'tollLineItem',
            headerName: 'Fee Description',
            description: 'Fee Description',
            renderHeader: () => (
                <span style={{textAlign:'left'}}>
                  Fee -<br />Description
                </span>
            ),
            width: 110,
            headerAlign: 'left',
            align: 'left',
            filterable: false,
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <div className={changedCells.has(`${params.id}-tollLineItem`) ? classes.changedCell : ''} style={{ width: "100%" }}>
                    <GeneralizedTextField 
                        rowId={params.id}
                        size='small'
                        style={{height:'70%'}}
                        field="tollLineItem"
                        value={params?.row?.tollLineItem}
                        is_dealership_flag={true}
                        handleInputChange={handleInputChange}
                    />
                </div>
            )
        },
        {
            field: 'tollFeeType',
            headerName: 'Fees Type',
            description: 'Fees Type',
            renderHeader: () => (
                <span style={{textAlign:'left'}}>
                Fees -<br />Type
                </span>
            ),
            width: 90,
            headerAlign: 'left',
            align: 'left',
            filterable: false,
            sortable: false,
            renderCell: (params: GridCellParams) => (
              <div className={changedCells.has(`${params.id}-tollFeeType`) ? classes.changedCell : ''} style={{ width: "100%" }}>
                <GeneralizedSelect
                    rowId={params.id}
                    field="tollFeeType"
                    value={params.row.tollFeeType}
                    handleInputChange={handleInputChange}
                    options={tollFeeTypeOptions}
                    is_dealership_flag={true}
                />
              </div>
            )
          },
        {
            field: 'active_invoice',
            headerName: 'Active Invoice',
            description: 'Active Invoice',
            renderHeader: () => (
                <span style={{textAlign:'left'}}>
                  Active -<br />Invoice
                </span>
            ),
            width: 80,
            headerAlign: 'left',
            align: 'left',
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <div className={changedCells.has(`${params.id}-active_invoice`) ? classes.changedCell : ''} style={{ width: "100%" }}>
                    <GeneralizedSwitch
                        rowId={params.id}
                        field="active_invoice"
                        checked={params?.row?.active_invoice ?? false}
                        handleInputChange={handleInputChange}
                        is_dealership_flag={true}
                    />
                </div>
            )
        },
        {
            field: 'active_payments',
            headerName: 'Active Payments',
            description: 'Active Payments',
            renderHeader: () => (
                <span style={{textAlign:'left'}}>
                  Active -<br />Payments
                </span>
            ),
            width: 80,
            headerAlign: 'left',
            align: 'left',
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <div className={changedCells.has(`${params.id}-active_payments`) ? classes.changedCell : ''} style={{ width: "100%" }}>
                    <GeneralizedSwitch
                        rowId={params.id}
                        field="active_payments"
                        checked={params?.row?.active_payments ?? false}
                        handleInputChange={handleInputChange}
                        is_dealership_flag={true}
                    />
                </div>
            )
        },
        {
            field: 'is_active',
            headerName: 'Active',
            description: 'Active',
            width: 80,
            headerAlign: 'left',
            align: 'left',
            sortable: false,
            renderCell: (params: GridCellParams) => (
                <div className={changedCells.has(`${params.id}-is_active`) ? classes.changedCell : ''} style={{ width: "100%" }}>
                    <GeneralizedSwitch
                        rowId={params.id}
                        field="is_active"
                        checked={params?.row?.is_active ?? false}
                        handleInputChange={handleInputChange}
                        is_dealership_flag={true}
                    />
                </div>
            )
        },
    ];

    const fetchDealershipGroups = async () => {
        try {
            setLoading(true);
            const apiName = getAPIName();
            const path = `/dealershipgroups?orderBy-0=name&orderBy-1=dealership.name`;
            const response = await API.get(apiName, path, {});
            setDealershipGroups(response.body);
        } catch (error) {
            console.error('Error fetching dealership groups:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchLms = async () =>{
        try {
            setLoading(true);
            const apiName = getAPIName();
            const path = `/api/v1/lms?orderBy-=lmsname`;
            const response = await API.get(apiName, path, {});
            const lmsNames = response?.body?.map((item:{id : number; lmsname : string}) => item.lmsname);
            setLms(lmsNames);
        } catch (error) {
            console.error('Error fetching Lms', error);
        }
    }
    useEffect(() => {
        fetchDealershipGroups();
        fetchLms()
    }, []);

    useEffect(() => {
        //Update the rows according to field_updates
        const updatedRows = filteredRows.map(row => {
            const setting = field_updates.find(setting => setting.dealershipids.includes(row.id));
            if (setting) {
                // get default values
                const originalRow = rows.find(original => original.id === row.id);
                if (!originalRow) return row;
                const flags = setting.dealership_flags.reduce((acc, flag) => {
                    const [key, value] = Object.entries(flag)[0];
                    if (value === 'RESET') {
                        setChangedCells(prev => {
                            const newSet = new Set(prev);
                            newSet.delete(`${row.id}-${key}`);
                            return newSet;
                        });
                        return { ...acc, [key]: originalRow[key as keyof Dealership] };
                    } else {
                        return { ...acc, [key]: value };
                    }
                }, {} as Partial<Dealership>);

                const controlFlags = setting.dealership_controls_flags.reduce((acc, flag) => {
                    const [key, value] = Object.entries(flag)[0];
                    if (value === 'RESET') {
                        setChangedCells(prev => {
                            const newSet = new Set(prev);
                            newSet.delete(`${row.id}-${key}`);
                            return newSet;
                        });
                        return { ...acc, [key]: originalRow.dealershipcontrols[key as keyof DealershipControl] };
                    } else {
                        return { ...acc, [key]: value };
                    }
                }, {} as Partial<DealershipControl>);

                return {
                    ...row,
                    ...flags,
                    dealershipcontrols: { ...row.dealershipcontrols, ...controlFlags }
                };
            }
            return row;
        });
        setFilteredRows(updatedRows);
    }, [field_updates]);

    useEffect(() => {
        const flattenedRows = dealershipGroups.flatMap(group =>
            group.dealerships.map(dealership => ({
                ...dealership,
                groupName: group.name
            }))
        );
        setRows(flattenedRows);
        setFilteredRows(flattenedRows);
        setFilteredGroups(dealershipGroups);
    }, [dealershipGroups]);

    useEffect(() => {
        filterRows();
    }, [selectedLms, selectedGroups, rows]);

    useEffect(() => {
        const validSelectedGroups = selectedGroups.filter(group =>
            filteredGroups.some(option => option.id === group.id)
        );
        if (validSelectedGroups.length !== selectedGroups.length) {
            setSelectedGroups(validSelectedGroups);
        }
    }, [filteredGroups, selectedGroups]);

    const filterRows = () => {
        let filtered = rows;
        if (selectedLms.length > 0) {
            filtered = filtered.filter((row) => selectedLms.includes(row.lmsversion));
        }
        const filteredGroupIds = new Set(filtered.map(row => row.dealershipgroupid));
        const newFilteredGroups = dealershipGroups.filter(group =>
            group.dealerships.some(dealership => filteredGroupIds.has(dealership.dealershipgroupid))
        );
        setFilteredGroups(newFilteredGroups);
        if (selectedGroups.length > 0) {
            filtered = filtered.filter(row => selectedGroups.some(group => group.name === row.groupName));
        }
        setFilteredRows(filtered);
    };

    const handleLmsChange = (event: ChangeEvent<{}>, newValue: string[]) => {
        setSelectionModel([]);
        setFieldUpdates([]);
        setSelectedLms(newValue);
    };

    const handleGroupChange = (event: ChangeEvent<{}>, newValue: DealershipGroup[]) => {
        setSelectionModel([]);
        setFieldUpdates([]);
        setSelectedGroups(newValue);
    };
    return (
        <>
            <Grid container spacing={2} style={{ marginBottom: '20px' }}>
                <Grid item xs={12} sm={6} md={6}>
                    <Autocomplete
                        multiple
                        options={lms}
                        value={selectedLms}
                        renderInput={(params) => <TextField {...params} label="Filter by LMS" variant="outlined" />}
                        onChange={handleLmsChange}
                        loading={loading}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Autocomplete
                        multiple
                        options={filteredGroups}
                        value={selectedGroups}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Filter by Group" variant="outlined" />}
                        onChange={handleGroupChange}
                        loading={loading}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems='flex-end' justifyContent='flex-end' style={{ marginBottom: '20px' }}>
                <Grid item xs={12}>
                    <Button onClick={handleSave} disabled={(loading || !hasChanges)} variant="contained" color="primary">
                        Save
                    </Button>
                </Grid>
            </Grid>
            <div className={`${loading ? classes.transparentForm : ''}`}>
                <DataGrid
                    rows={filteredRows}
                    columns={columns}
                    checkboxSelection
                    disableSelectionOnClick
                    selectionModel={selectionModel}
                    onSelectionModelChange={handleSelectionChange}
                    loading={loading}
                    headerHeight={40}
                    rowHeight={40}
                    autoHeight={true}
                    disableColumnMenu
                    density='comfortable'
                    className={classes.root}
                />
            </div>
            <Grid container spacing={2} alignItems='flex-end' justifyContent='flex-end' style={{ marginTop: '20px' }}>
                <Grid item xs={12}>
                    <Button onClick={handleSave} disabled={(loading || !hasChanges)} variant="contained" color="primary">
                        Save
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default DealershipControls;
