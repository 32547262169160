import React, { useState, useRef, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { API } from 'aws-amplify';
import { getAPIName } from '../config';
import handleApiErrors from '../common/handleApiErrors';
import { useSnackbar } from 'notistack';
import {
  CircularProgress,
  Button,
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import useCustomSnak from './helpers/handleSnackbars';
export interface HoldFormProperties {
  readonly dealershipId?: number;
  readonly contractNumber?: string;
  readonly emailaddress?: string;
  readonly resetform: () => void;
  readonly defaultHold?: string;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    base: {
      justifyContent: "center",
    },
    card_element: {
      width: '80%',
      background: "honeydew",
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      padding: '10px',
      font: 'large'
    },
    button: {
      marginTop: '20px'
    },
    transparentForm: {
      opacity: 0.5
    }
  })
);

const HoldForm = (props: HoldFormProperties) => {
  const [amount, setAmount] = useState(props.defaultHold);
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const classes = useStyles();
  const [isCardElementFilled, setIsCardElementFilled] = useState(false);
  const handleCustomSnak = useCustomSnak();
  const handleCardChange = (event: any) => {
    setIsCardElementFilled(event.complete);
  };
  const insertAuthToken = async (authTokenObject: any) => {
    let path = '/authtokens';
    try {
      const response: any = await API.post(getAPIName(), path,
        {
          body: authTokenObject
        });
      handleCustomSnak(`Hold with amount $ ${amount} has been Created for rental agreement number : ${authTokenObject.referencenumber}`, 'success')
      setIsLoading(false)
      props.resetform()
    }
    catch (e) {
      handleApiErrors(e, enqueueSnackbar);
      setIsLoading(false)
      props.resetform()
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (!amount || parseFloat(amount) == 0) {
      handleCustomSnak(`Enter Valid Amount`, 'error')
      return
    }
    setIsLoading(true)
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }
    const { token, error } = await stripe.createToken(cardElement);
    if (error) {
      console.error('Error creating token:', error.message);
      setIsLoading(false)
      props.resetform()
      return;
    }
    try {
      const response: any = await API.post(getAPIName(), '/api/v1/payment/setup_payments',
        {
          body: {
            dealership_id: props.dealershipId,
            referencenumber: props.contractNumber,
            emailaddress: props.emailaddress,
            amount: parseFloat(amount) * 100,
            token: token?.id
          }
        });
      if (response.success) {
        if (response.client_secret) {
          var confirmation = await stripe.confirmCardPayment(response.client_secret);
          if (confirmation.paymentIntent?.status == "requires_capture") {
            insertAuthToken({
              token: confirmation.paymentIntent.id,
              ccsystemcode: null,
              paymentgateway: "Stripe",
              referencenumber: props.contractNumber,
              collecteddate: null,
              tokenproviderid: null,
              dealershipid: props.dealershipId,
              is_authorised: false,
              pgw_version: 'V4'
            })
          }
          else {
            handleCustomSnak('Failed to Create Hold', 'error')
            props.resetform()
          }
        }
        else {
          insertAuthToken({
            token: response.payment_intent_id,
            ccsystemcode: null,
            paymentgateway: "Stripe",
            referencenumber: props.contractNumber,
            collecteddate: null,
            tokenproviderid: null,
            dealershipid: props.dealershipId,
            is_authorised: false,
            pgw_version: 'V4'
          })
        }
      }
      else
        handleApiErrors("Failed to Create Hold", enqueueSnackbar, 'Failed to Create Hold');
    }
    catch (e) {
      setIsLoading(false)
      handleApiErrors(e, enqueueSnackbar, 'Failed to Create Hold');
      props.resetform()
    }
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    // Validate the input to allow only float numbers
    if (/^\d*\.?\d{0,2}$/.test(newValue)) {
      setAmount(newValue);
    }
  };

  useEffect(() => {
    setAmount(props.defaultHold)
  }, [props.defaultHold])

  return (
    <>
      <form style={{ paddingTop: "12px" }} onSubmit={handleSubmit} className={isLoading ? classes.transparentForm : ''} >
        <Grid container className={classes.base}>
          <Grid item xs={6} >
            <Typography variant="h3">
              <TextField
                placeholder="Hold Amount"
                label="Hold Amount"
                value={amount}
                onChange={handleAmountChange}
                type="text"
                inputProps={{ inputMode: 'numeric' }}
                disabled={isLoading ? true : false}
              />
            </Typography>
          </Grid>
          <Grid xs={8} className={classes.card_element}>
            <label>
              <CardElement
                onChange={handleCardChange}
              />
            </label>
          </Grid>
          <Grid xs={8} className={classes.button}>
            {
              !isLoading ?
                <Button type="submit" disabled={!stripe || !isCardElementFilled} variant="contained" color="primary" aria-label="CREATE HOLD">
                  CREATE HOLD
                </Button>
                :
                <Button type="submit" disabled={true} variant="outlined" color="primary" >
                  <CircularProgress size={24} style={{ color: "darkblue" }} />
                </Button>
            }
          </Grid>
        </Grid>
      </form>

    </>
  );
};

export default HoldForm;
