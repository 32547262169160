import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import validateEmail from '../common/helper';
import { statusOptions } from '../components/Pages/Invoices'
import {
    Button,
    Grid,
    TextField
} from '@material-ui/core';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { generateHandleChange } from '../common/state-setter-generator'
import { API } from 'aws-amplify';
import { getAPIName } from '../config';
import { useSnackbar } from 'notistack';
import handleApiErrors from '../common/handleApiErrors';
import CircularProgress from '@material-ui/core/CircularProgress';
import useCustomSnak from './helpers/handleSnackbars';
const CHAR_LIMIT = 120;
interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}
interface Customer {
    firstname: string,
    lastname: string,
    emailaddress: string,
    contractid: number,
}

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        }
    },
    formBox: {
        padding: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flexStart",
        flexDirection: "column",
        gap: "20px",
        marginTop: "10px"
    },
    form: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flexStart",
        gap: "4px",
        flexDirection: "column"
    },
    popupBtnGroup: {
        display: "flex",
        flexDirection: "row",
        gap: "5px",
        justifyContent: "flex-end",
        width: "100%"
    },
    transparentForm: {
        opacity: 0.5
    },
    loaderContainer:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 2,
    },
    counterdiv :{
        color : "grey"
    }
}));

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}

        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function tabPanelProps(index: any) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
export default function EditInvoicePopup(props: any) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const handleCustomSnak = useCustomSnak();
    const [state, setState] = React.useState<any>(props.invoice);
    const [note, setnote] = React.useState<any>('');
    const validOptionsDict: any = {
        "Charged": ['customer_dispute_lost', 'customer_dispute_won', 'customer_dispute_initiated', 'Charged', 'manually_refunded'],
        "Created": ['Created'],
        "Declined": ['manually_charged', 'Declined'],
        "missing_cc_token": ['manually_charged', 'missing_cc_token'],
        "manually_charged": ['Declined', 'missing_cc_token', 'manually_charged'],
        "customer_dispute_lost": ['Charged', 'customer_dispute_won', 'customer_dispute_initiated', 'customer_dispute_lost'],
        "customer_dispute_won": ['customer_dispute_lost', 'Charged', 'customer_dispute_initiated', 'customer_dispute_won'],
        "customer_dispute_initiated": ['customer_dispute_lost', 'customer_dispute_won', 'Charged', 'customer_dispute_initiated'],
        "manually_refunded": ['Charged', 'manually_refunded']
    }
    const invoiceStatusMenuItems = statusOptions.filter((e: any) => validOptionsDict[state.status]?.includes(e.value)).map((d: any) =>
        <MenuItem key={d.name} value={d.value}>
            {d.name || '—'}
        </MenuItem>
    );
    const handleClose = () => {
        props.toggleOpen();
    };
    const handleSubmit = () => {
        props.handleSave(state, props.updatedInvoiceStatus);
    };
    const handleInvoiceStatusSelect = (e: React.ChangeEvent<{ name?: string; value: unknown; }>): void => {
        props.setUpdatedInvoiceStatus(e.target.value);
    };
    const [defaultCustomerState, setDefaultCustomerState] = React.useState<Customer>({
        firstname: state?.contract?.customer?.firstname,
        lastname: state?.contract?.customer?.lastname,
        emailaddress: state?.contract?.customer?.emailaddress,
        contractid: state?.contract?.id
    });
    React.useEffect(() => {
        setDefaultCustomerState({
            firstname: props.invoice?.contract?.customer?.firstname,
            lastname: props.invoice?.contract?.customer?.lastname,
            emailaddress: props.invoice?.contract?.customer?.emailaddress,
            contractid: props.invoice?.contract?.id
        });
        setState(props.invoice);
        setnote(props.invoice?.invoiceinformation?.comment)
    }, [props.invoice]);
    React.useEffect(() => {
        setCustomer(defaultCustomerState);
    }, [defaultCustomerState]);
    // TABS
    const [customer, setCustomer] = useState<Customer>(defaultCustomerState);
    const handleCustomerChange = generateHandleChange<Customer>(customer, setCustomer);
    const [tabsValue, setTabsValue] = React.useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabsValue(newValue);
    };

    const handleCustomerSubmit = async () => {
        try {
            let response;
            
            if (!customer.firstname) {
                return enqueueSnackbar('Enter firstname', { variant: 'warning' })
            }
            if (!customer.emailaddress) {
                return enqueueSnackbar('Enter emailaddress', { variant: 'warning' })
            }
            if (!validateEmail(customer.emailaddress)) {
                return enqueueSnackbar('Enter Valid Email Address', { variant: 'warning' });
            }
            let addCustomer = {
                "firstname": customer.firstname,
                "lastname": customer.lastname,
                "emailaddress": customer.emailaddress,
                "contractid": customer.contractid
            }
            setIsLoading(true)
            response = await API.post(getAPIName(), '/customers', {
                body: addCustomer,
            });
            if (response.body.id) {
                enqueueSnackbar('Customer Inserted', { variant: 'success' });
                handleClose();
                setIsLoading(false)
                props.setUpdateExecuted(!props.updateExecuted)
            }
            else {
                enqueueSnackbar(response.message, { variant: 'warning' });
                setIsLoading(false)
            }
        }
        catch (err) {
            setIsLoading(false)
            handleApiErrors(err, enqueueSnackbar);
        }
    }

    const handleCommentSubmit = async () => {
        try {
            let response;
            if (note.length > CHAR_LIMIT - 20)
                return enqueueSnackbar('text should be less than or equal to 100 characters', { variant: 'warning' })
            if (note == props.invoice?.invoiceinformation?.comment)
                return enqueueSnackbar('note to be updated is same as original', { variant: 'warning' })
            setIsLoading(true)
            if (props.invoice.invoiceinformation) {
                response = await API.patch(getAPIName(), '/api/v1/invoiceinformation/' + props.invoice?.invoiceinformation?.id, {
                    body: {
                        "comment": note
                    }
                });
            }
            else {
                response = await API.post(getAPIName(), '/api/v1/invoiceinformation', {
                    body: {
                        "invoiceid": props.invoice.id,
                        "comment": note
                    }
                });
            }
            handleCustomSnak('Note Updated', 'success');
            handleClose();
            setIsLoading(false)
            props.setUpdateExecuted(!props.updateExecuted)
        }
        catch (err) {
            setIsLoading(false)
            handleApiErrors(err, enqueueSnackbar);
        }
    }
    return (
        <div>
            <Dialog
                open={props.isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <AppBar position="static" color="default">
                    <Tabs
                        value={tabsValue}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="full width tabs example"
                    >
                        <Tab label="Invoice" {...tabPanelProps(0)} />
                        <Tab label="Customer" {...tabPanelProps(1)} />
                        <Tab label="Notes" {...tabPanelProps(2)} />
                    </Tabs>
                </AppBar>
                {/* Invoice Status Edit Tab Panel */}
                <TabPanel value={tabsValue} index={0}>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Invoice with id {state?.id}
                        </DialogContentText>

                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={4}>
                                Location
                            </Grid>
                            <Grid item xs={8}>
                                {state?.contract?.dealership?.name}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={4}>
                                Contract
                            </Grid>
                            <Grid item xs={8}>
                                {state?.contract?.contractnumber}
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={4}>
                                Customer
                            </Grid>
                            <Grid item xs={8}>
                                {state?.contract?.customer?.firstname + " " + state?.contract?.customer?.lastname}
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <FormControl style={{ width: '100%' }}>
                                    <InputLabel shrink={false}>
                                        Status
                                    </InputLabel>
                                    {/* We were using "zero width space" AKA \\u200B as a hidden break here. */}
                                    {/* Adding explicit breaks until we fix with CSS */}
                                    <br />
                                    <br />
                                    <br />
                                    <Select
                                        onChange={handleInvoiceStatusSelect}
                                        value={props.updatedInvoiceStatus}
                                    >
                                        {invoiceStatusMenuItems}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        {
                            state.status != props.updatedInvoiceStatus ?
                                <Button onClick={handleSubmit} color="primary" autoFocus>
                                    Save
                                </Button>
                                :
                                <Button onClick={handleSubmit} color="primary" disabled>
                                    Save
                                </Button>
                        }
                    </DialogActions>
                </TabPanel>
                {/* Customer Edit Tab Panel*/}
                <TabPanel value={tabsValue} index={1}>
                    <div className={`${classes.formBox} ${isLoading ? classes.transparentForm : ''}`}>
                        <TextField
                            fullWidth
                            id="plate"
                            name='plate'
                            value={customer?.firstname}
                            onChange={(event) => {
                                handleCustomerChange('firstname')(event.target.value)
                            }}
                            margin="dense"
                            placeholder="First Name"
                        />
                        <TextField
                            fullWidth
                            id="plate"
                            name='plate'
                            value={customer?.lastname}
                            onChange={(event) => {
                                handleCustomerChange('lastname')(event.target.value)
                            }}
                            margin="dense"
                            placeholder="Last Name"
                        />
                        <TextField
                            fullWidth
                            id="plate"
                            name='plate'
                            value={customer?.emailaddress}
                            onChange={(event) => {
                                handleCustomerChange('emailaddress')(event.target.value)
                            }}
                            margin="dense"
                            placeholder="Email Address"
                        />
                        <div className={classes.popupBtnGroup}>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleCustomerSubmit} color="primary" disabled={isLoading}>
                                Submit
                            </Button>
                        </div>
                        {isLoading && <div className={classes.loaderContainer}><CircularProgress /></div>}
                    </div>
                </TabPanel>
                {/* notes edit tab */}
                <TabPanel value={tabsValue} index={2}>
                    <div className={`${classes.formBox} ${isLoading ? classes.transparentForm : ''}`}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="note"
                                    label="Notes"
                                    type="text"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={note}
                                    onChange={(e) => setnote(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.counterdiv}>{ note ? note.length : 0}/{CHAR_LIMIT-20}</Grid>
                            <div className={classes.popupBtnGroup}>
                                <Button onClick={handleClose} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={handleCommentSubmit} color="primary" disabled={isLoading}>
                                    Submit
                                </Button>
                            </div>
                            {isLoading && <div className={classes.loaderContainer}><CircularProgress /></div>}
                        </Grid>
                    </div>
                </TabPanel>
            </Dialog>
        </div>
    );
}