import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import useCustomSnak from '.././helpers/handleSnackbars';
import moment from 'moment';
import { convertLocalToUTC, convertUTCToLocal } from '../../common/helper';
import {
  Button,
  Grid,
  TextField
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { generateHandleChange } from '../../common/state-setter-generator'
import { API } from 'aws-amplify';
import { getAPIName } from '../../config';
import { useSnackbar } from 'notistack';
import handleApiErrors from '../../common/handleApiErrors';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyles } from '../EditInvoicePopup';
import validateEmail from '../../common/helper';

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}

    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function tabPanelProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

export default function EditContractPopup(props: any) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [contractState, setContractState] = React.useState<any>(props.contract);
  const [customerState, setcustomerState] = React.useState<any>(props.contract.customer);
  const handleCustomerChange = generateHandleChange(customerState, setcustomerState);
  const handleContractChange = generateHandleChange(contractState, setContractState);
  const handleCustomSnak = useCustomSnak();
  const handleClose = () => {
    props.toggleOpen();
  };
  React.useEffect(() => {
    setContractState(props.contract)
    setcustomerState(props.contract.customer)
  }, [props.contract]);

  const [tabsValue, setTabsValue] = React.useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabsValue(newValue);
  };

  const handleContractSubmit = async () => {
    try {
      let response;
      setIsLoading(true)

      if (!moment(contractState.dateout).isValid()) {
        setIsLoading(false)
        return handleCustomSnak('Date out cant be empty', 'warning')
      }
      if (contractState.dateout && contractState.dateout && (moment(contractState.dateout) > moment(contractState.datein))) {
        setIsLoading(false)
        return handleCustomSnak('Date out cant be greater than Date in', 'warning')
      }
      let contractbody = {
        "customerid": contractState.customerid,
        "vehicleid": contractState.vehicleid,
        "dealershipid": contractState.dealershipid,
        "contractnumber": contractState.contractnumber,
        "datein": moment(contractState.datein).isValid() ? contractState.datein : null,
        "dateout": contractState.dateout,
        "lms": contractState.lms,
        "status": moment(contractState.datein).isValid() ? 'Closed' : 'Open',
        "ro_number": contractState.ro_number,
        "service_advisor_id": contractState.service_advisor_id,
        "id": contractState.id
      }

      response = await API.put(getAPIName(), '/contracts', {
        body: contractbody,
      });
      if (response.body["numberOfRecordsUpdated"]) {
        handleCustomSnak('Contract Updated', 'success')
        handleClose();
        setIsLoading(false)
        props.setUpdateExecuted(!props.updateExecuted)
      }
      else {
        handleCustomSnak('response.message', 'warning')
        setIsLoading(false)
      }
      setIsLoading(false)
    }
    catch (err) {
      setIsLoading(false)
      handleApiErrors(err, enqueueSnackbar);
    }
  }
  const handleCustomerSubmit = async () => {
    try {
      let response;
      let customerbody = {
        "firstname": customerState?.firstname,
        "lastname": customerState?.lastname,
        "emailaddress": customerState?.emailaddress,
      }
      if (!validateEmail(customerState?.emailaddress)) {
        return handleCustomSnak('Enter valid email address', 'warning')
      }
      setIsLoading(true)
      response = await API.put(getAPIName(), '/customers' + "/" + customerState.id, {
        body: customerbody,
      });
      if (response.body && response.body[0][0]["id"]) {
        handleCustomSnak("Customer Updated", "success")
        handleClose();
        setIsLoading(false)
        props.setUpdateExecuted(!props.updateExecuted)
      }
      else {
        handleCustomSnak("response.message", "warning")
        setIsLoading(false)
      }
      setIsLoading(false)
    }
    catch (err) {
      setIsLoading(false)
      handleApiErrors(err, enqueueSnackbar);
    }
  }

  return (
    <div >
      <Dialog
        open={props.isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <AppBar position="static" color="default">
          <Tabs
            value={tabsValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="full width tabs example"
          >
            <Tab label="Contract" {...tabPanelProps(0)} />
            {
              customerState &&
              <Tab label="Customer" {...tabPanelProps(1)} />
            }
          </Tabs>
        </AppBar>
        {/* Contract date in , date out Edit Tab Panel */}
        <TabPanel value={tabsValue} index={0}>
          <div className={`${classes.formBox} ${isLoading ? classes.transparentForm : ''}`}>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
              <b>Rental Agreement </b>
              </Grid>
              <Grid item xs={8}>
                {contractState?.contractnumber ? contractState?.contractnumber : "N/A"}
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <b>Ro Number</b>
              </Grid>
              <Grid item xs={8}>
              {contractState?.ro_number ? contractState?.ro_number : "N/A"}
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
              <b> Licenseplate</b> 
              </Grid>
              <Grid item xs={8}>
              {(contractState?.vehicle?.licenseplate ? contractState?.vehicle?.licenseplate : "N/A") + " - " + (contractState?.vehicle?.state ? contractState?.vehicle?.state : "N/A")}
              </Grid>
            </Grid>


            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
              <b> Date Out</b> 
              </Grid>
              <Grid item xs={8}>
                <input
                  type="datetime-local"
                  id="dateout"
                  // value={contractState.dateout ? moment(contractState.dateout).format('YYYY-MM-DDTHH:mm') : ''}
                  value={contractState.dateout ? convertUTCToLocal(contractState.dateout) : ''}

                  onChange={(event: any) =>
                    handleContractChange('dateout')(convertLocalToUTC(new Date(event.target.value)))
                  }
                >
                </input>
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
              <b> Date In</b> 
              </Grid>
              <Grid item xs={8}>
                <input
                  type="datetime-local"
                  id="datein"
                  value={contractState.datein ? convertUTCToLocal(contractState.datein) : ''}
                  // value={contractState.datein ? moment(contractState.datein).format('YYYY-MM-DDTHH:mm') : ''}
                  onChange={(event) => {
                    handleContractChange('datein')(convertLocalToUTC(new Date(event.target.value)))
                  }}
                >
                </input>
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
              <b> Customer</b> 
              </Grid>
              <Grid item xs={8}>
                {(contractState?.customer?.firstname ? contractState?.customer?.firstname : "N/A") + " " + (contractState?.customer?.lastname ? contractState?.customer?.lastname : "N/A")}
              </Grid>
            </Grid>

            <DialogActions>
              <Button onClick={handleClose} color="primary" disabled={isLoading}>
                Cancel
              </Button>
              <Button onClick={handleContractSubmit} color="primary" disabled={isLoading}>
                Save
              </Button>

            </DialogActions>
          </div>
        </TabPanel>
        {/* Customer Edit Tab Panel*/}
        <TabPanel value={tabsValue} index={1}>
          <div className={`${classes.formBox} ${isLoading ? classes.transparentForm : ''}`}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
              <b> First Name</b> 
              </Grid>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  id="firstname"
                  name='First Name'
                  value={customerState?.firstname}
                  onChange={(event) => {
                    handleCustomerChange('firstname')(event.target.value)
                  }}
                  margin="dense"
                  placeholder="First Name"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">

              <Grid item xs={4}>
              <b> Last Name</b> 
              </Grid>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  id="lastname"
                  name='Last Name'
                  value={customerState?.lastname}
                  onChange={(event) => {
                    handleCustomerChange('lastname')(event.target.value)
                  }}
                  margin="dense"
                  placeholder="Last Name"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
              <b> Email Address</b> 
              </Grid>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  id="emailaddress"
                  name='Email Address'
                  value={customerState?.emailaddress}
                  onChange={(event) => {
                    handleCustomerChange('emailaddress')(event.target.value)
                  }}
                  margin="dense"
                  placeholder="Email Address"
                />
              </Grid>
            </Grid>
            <div className={classes.popupBtnGroup}>
              <Button onClick={handleClose} color="primary" disabled={isLoading}>
                Cancel
              </Button>
              <Button onClick={handleCustomerSubmit} color="primary" disabled={isLoading}>
                Save
              </Button>
            </div>
          </div>
        </TabPanel>

        {isLoading && <div className={classes.loaderContainer}><CircularProgress /></div>}

      </Dialog>
    </div>
  );
}