import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    Button,
    Grid,
    TextField
} from '@material-ui/core';
import moment from 'moment';
import { generateHandleChange } from '../common/state-setter-generator';

export default function EditTollRatePopup(props: any) {
    const [state, setState] = React.useState<any>(props.tollRate);
    const handleClose = () => {
        props.toggleOpen();
    };
    const handleSubmit = () => {
        props.handleSave(props.updatedTollRate);
    };
    React.useEffect(() => {
        props.setUpdatedTollRate(props.tollRate);
        setState(props.tollRate)
    }, [props.tollRate]);
    const handleChange = generateHandleChange(
        props.updatedTollRate,
        props.setUpdatedTollRate
    );

    return (
        <div>
            <Dialog
                open={props.isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Edit Toll Rate Details"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Toll Rate with id {state?.id}
                    </DialogContentText>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                            Description Entry
                        </Grid>
                        <Grid item xs={8}>
                            {state.description_entry}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                            Description Exit
                        </Grid>
                        <Grid item xs={8}>
                            {state.description_exit}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                            Effective Start
                        </Grid>
                        <Grid item xs={8}>
                            <input
                                type="date"
                                id="effective_start_date"
                                value={props.updatedTollRate.effective_start_date ? moment(props.updatedTollRate.effective_start_date).format('YYYY-MM-DD') : ''}
                                onChange={(event: any) =>
                                    handleChange('effective_start_date')(event.target.value)
                                }
                            >
                            </input>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                            Effective End
                        </Grid>
                        <Grid item xs={8}>
                            <input
                                type="date"
                                id="effective_end_date"
                                value={props.updatedTollRate.effective_end_date ? moment(props.updatedTollRate.effective_end_date).format('YYYY-MM-DD') : ''}
                                onChange={(event: any) =>
                                    handleChange('effective_end_date')(event.target.value)
                                }
                            >
                            </input>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                            Transponder Rate
                        </Grid>
                        <Grid item xs={8}>
                            {state.transponder_rate_axle_2}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} spacing={2} container alignItems="center">
                        <Grid item xs={4} style={{ textAlign: 'left' }}>
                            Cash Rate
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                id="cash_rate_axle_2"
                                placeholder="Amount"
                                margin="dense"
                                value={props.updatedTollRate.cash_rate_axle_2}
                                onChange={(event: any) =>
                                    handleChange('cash_rate_axle_2')(parseFloat(event.target.value))
                                }
                                type="number"
                                InputProps={{
                                    inputProps: { min: 0 }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} spacing={2} container alignItems="center">
                        <Grid item xs={4} style={{ textAlign: 'left' }}>
                            Mid Rate
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                id="mid_rate_axle_2"
                                placeholder="Amount"
                                margin="dense"
                                value={props.updatedTollRate.mid_rate_axle_2 }
                                onChange={(event: any) =>
                                    handleChange('mid_rate_axle_2')(parseFloat(event.target.value))
                                }
                                type="number"
                                InputProps={{
                                    inputProps: { min: 0 }
                                }}
                            />
                        </Grid>
                    </Grid>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}