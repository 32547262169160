import React, { useEffect } from 'react';
import {
  AppBar,
  Button,
  Card,
  Divider,
  Grid,
  Tab,
  Typography,
} from '@material-ui/core';
import { getAPIName } from '../../config';
import { DealershipGroup } from '../../_models/DealershipGroups';
import { API } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import DealershipGroupForm from '../dealership-group.form';
import handleApiErrors from '../../common/handleApiErrors';

function compareDealershipGroups(a: DealershipGroup, b: DealershipGroup) {
  if (a.name > b.name)
    return 1;
  if (b.name > a.name)
    return -1;
  return 0;
}

export default function DealershipGroups() {
  const { enqueueSnackbar } = useSnackbar();

  const [ selectedDealershipGroup, setSelectedDealershipGroup ] = React.useState<DealershipGroup>();

  // fetch stuff //////////////////////////////////////////////////////////////
  // dealership groups
  const [ dealershipGroups, setDealershipGroups ] = React.useState<DealershipGroup[]>([]);
  const getDealershipGroups = async () => {
    try {
      const response: any = await API.get(getAPIName(), '/dealershipGroups', {});
      setDealershipGroups(response.body.sort(compareDealershipGroups));
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => { getDealershipGroups(); }, []);

  // handlers /////////////////////////////////////////////////////////////////
  const handleDealershipGroupButton = (dealershipGroup?: DealershipGroup): void => {
    setSelectedDealershipGroup(dealershipGroup);
  }
  const handleSubmitDealershipGroupForm = async (dealershipGroup: DealershipGroup): Promise<void> => {
    try {
      if (selectedDealershipGroup == null) {
        const response = await API.post(getAPIName(), '/dealershipgroups', { body: dealershipGroup });
        setDealershipGroups([...dealershipGroups, response].sort(compareDealershipGroups));
        setSelectedDealershipGroup(response);
      } else {
        const response = await API.put(getAPIName(), `/dealershipgroups/${dealershipGroup.id}`, { body: dealershipGroup });
        const newDealershipGroups: DealershipGroup[] = [...dealershipGroups].filter(d => d.id != dealershipGroup.id);
        newDealershipGroups.push(response);
        newDealershipGroups.sort(compareDealershipGroups);
        setDealershipGroups(newDealershipGroups);
      }
      enqueueSnackbar('Dealership group saved!', { variant: 'success' });
    } catch (e) {
      handleApiErrors(e, enqueueSnackbar, 'dealership group');
    }
  };

  // JSX.Element preprocessing /////////////////////////////////////////////////
  const dealershipGroupButtons: JSX.Element[] = dealershipGroups.map(d =>
    <div key={d.id}>
      <Button
        className="button-label"
        color={selectedDealershipGroup != null && d.id === selectedDealershipGroup.id ? "primary" : undefined}
        //disableFocusRipple={true}
        onClick={() => handleDealershipGroupButton(d)}
        type="button"
      >
        {d.name || '—'}
      </Button>
      <Divider />
    </div>
  );

  return (
    <div className="DealershipGroups">
      <Grid container spacing={2}>
        <Grid item md={6}>
          <div>
            <Grid container>
              <Grid item xs={9}>
                <Typography variant={'h4'} align={'left'}>
                  Dealership Groups
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Button
                  className="add-button"
                  disableFocusRipple={true}
                  onClick={() => setSelectedDealershipGroup(undefined)}
                  size={'small'}
                >
                  + Add
                </Button>
              </Grid>
            </Grid>
            <br />
            <Divider />
            {dealershipGroupButtons}
          </div>
        </Grid>

        <Grid item md={6}>
          <Card>
            <AppBar position="static">
              <Tab className="tab-label" label="Group Info"></Tab>
            </AppBar>

            <DealershipGroupForm
              dealershipGroupToEdit={selectedDealershipGroup}
              handleSubmit={handleSubmitDealershipGroupForm}
            />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
