import React, { useContext, useEffect, useState } from 'react';
import { AmplifyAuthContext, AmplifyAuthSignoutContext } from '../App';
import clsx from 'clsx';
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { Auth } from 'aws-amplify';
import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApplicationRouter } from './routing.component';
import { NavMenuItem } from './NavItem';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCar,
  faChartLine,
  faCog,
  faFile,
  faFolder,
  faHome,
  faLayerGroup,
  faListUl,
  faQuestion,
  faRoad,
  faUserTie,
  faUsers,
  faWarehouse,
  faCreditCard,
  faMagnifyingGlassDollar,
  faFileInvoiceDollar,
  faParking,
  faGear
} from '@fortawesome/free-solid-svg-icons';
library.add(
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCar,
  faChartLine,
  faCog,
  faFile,
  faFolder,
  faHome,
  faLayerGroup,
  faListUl,
  faQuestion,
  faRoad,
  faUserTie,
  faUsers,
  faWarehouse,
  faFileInvoiceDollar,
  faParking,
  faGear
);

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      backgroundColor: '#111218',
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);

export default function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const currentAuthenticatedUser: any = useContext(AmplifyAuthContext);
  const amplifyAuthSignout: any = useContext(AmplifyAuthSignoutContext);
  
  const [open, setOpen] = React.useState(false);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  const signOut = async (): Promise<void> => {
    await Auth.signOut();
    amplifyAuthSignout();
  };

  const userRole: string =
    currentAuthenticatedUser == null ||
      currentAuthenticatedUser.attributes == null ||
      currentAuthenticatedUser.attributes.given_name == null
      ? 'Individual'
      : currentAuthenticatedUser.attributes.given_name;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            TollAid
          </Typography>
          <Button color="inherit" onClick={signOut}>
            Sign Out
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            ) : (
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <NavMenuItem label="Dashboard" icon={faHome} route="" />
          <NavMenuItem label="Contracts" icon={faListUl} route="contracts" />
          <NavMenuItem label="Invoices" icon={faFile} route="invoices" />
          <NavMenuItem label="Tolls" icon={faRoad} route="tolls" />
          <NavMenuItem label='ParkingTickets' icon={faParking} route="parkingtickets"/>
          {userRole === 'Admin' && (
            <NavMenuItem label="Toll Reports (Beta)" icon={faChartLine} route="tollreports" />
          )}
          <NavMenuItem label="Financial Reports (Beta)" icon={faFileInvoiceDollar}  route="financialreport" />
          <NavMenuItem label="Vehicles" icon={faCar} route="vehicles" />
          <NavMenuItem label="Authorize Payment" icon={faCreditCard} route="authorise" />
          {userRole === 'Admin' && (<NavMenuItem label="Dealership Controls" icon={faGear} route="dealershipcontrols"/> )}
          {/*<NavMenuItem label="Employees" icon={faUserTie} route="employees" />*/}
          {userRole === 'Admin' && (
            <NavMenuItem
              label="DealershipsGroups"
              icon={faLayerGroup}
              route="dealership-groups"
            />
          )}
          {(userRole === 'Admin' || userRole === 'Group') && (
            <React.Fragment>
              <NavMenuItem
                label="Dealerships"
                icon={faWarehouse}
                route="dealerships"
              />

              <NavMenuItem label="Users" icon={faUsers} route="users" />
            </React.Fragment>
          )}
          {userRole === 'Admin' && (
            <NavMenuItem
              label="TollRates"
              icon={faMagnifyingGlassDollar}
              route="tollrates"
            />
          )}
          {/*
          <NavMenuItem label="Paper Violations" icon={faFolder} route="paper-violations" />
          <NavMenuItem label="Fleet" icon={faCar} route="fleet" />
          <NavMenuItem label="reports" icon={faChartLine} route="reports" />
          */}
        </List>
        {/*
        <Divider />
        <NavMenuItem label="Help & Support" icon={faQuestion} route="help-support" />
        */}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <ApplicationRouter userRole={userRole}/>
      </main>
    </div>
  );
}
