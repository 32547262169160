import React, { useEffect } from 'react';
import { API } from 'aws-amplify';
import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import Dealership from '../_models/Dealership';
import { getAPIName } from '../config';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import {
  faEdit,
  faCalendarAlt,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCheck,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
library.add(
  faEdit as IconDefinition ,
  faCalendarAlt as IconDefinition,
  faAngleLeft as IconDefinition,
  faAngleRight as IconDefinition,
  faAngleDoubleLeft as IconDefinition,
  faAngleDoubleRight as IconDefinition,
  faCheck as IconDefinition,
  faTimes as IconDefinition
);

function compareDealerships(a: Dealership, b: Dealership) {
  if (a.name > b.name) return 1;
  if (b.name > a.name) return -1;
  return 0;
}

export interface DealershipSelectProperties {
  readonly dealershipId?: number;
  readonly onChangeDealership: (id: number) => void;
};

export default function DealershipSelect(props: DealershipSelectProperties) {

  const [dealerships, setDealerships] = React.useState<Dealership[]>([]);

  useEffect(() => { getDealerships(); }, []);

  async function getDealerships() {
    let path = '/dealerships';
    try {
      const response: any = await API.get(getAPIName(), path, {});
      setDealerships(response.body.sort(compareDealerships));
    } catch (e) {
      console.log(e);
    }
  }

  const handleDealershipSelect = (e: React.ChangeEvent<{ name?: string; value: unknown; }>): void => {
    const dealershipId: number = parseInt(e.target.value as string, 10);
    props.onChangeDealership(dealershipId);
  };

  const dealershipId: number = props.dealershipId == null
    ? 0
    : props.dealershipId;

  const dealershipMenuItems = dealerships.map(d =>
    <MenuItem key={d.id} value={d.id}>
      {d.name || '—'}
    </MenuItem>
  );
  dealershipMenuItems.unshift(
    <MenuItem key={'0'} value={'0'}>
      Select Dealership
    </MenuItem>
  );
  
  return (
    <FormControl style={{ width: '100%' }}>
      {/* <InputLabel shrink={false}>
        Dealership
      </InputLabel> */}
      {/* We were using "zero width space" AKA \\u200B as a hidden break here. */}
      {/* Adding explicit breaks until we fix with CSS */}
      <br />
      <br />
      <br />
      <Select
        onChange={handleDealershipSelect}
        value={dealershipId}
      >
        {dealershipMenuItems}
      </Select>
    </FormControl>
  );
}
