import React, { useEffect } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { getAPIName } from '../../config'
import { API } from 'aws-amplify';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        main_box: {
            background: 'azure',
            padding: '100px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto'
        },
        heading: {
            color: 'darkgrey'
        },
        stat: {
            color: 'green'
        },
        buttondiv: {
            color: 'white',
            padding: '6px',
            background: 'darkgrey',
            '&:hover': {
                background: "black",
            }
        }
    })
);

const AuthorisePaymentSuccess = () => {
    const classes = useStyles();
    const handleredirect = () => {
        window.location.replace(window.location.protocol + "//" + window.location.hostname + "/authorise")
    }
    var dealershipid: any = ''
    var contractnumber: any = ''
    const getvariables = () => {
        const query = new URLSearchParams(window.location.search);
        dealershipid = query.get("dealer");
        contractnumber = query.get("contract");
    }
    useEffect(() => {
        getvariables()
        if (dealershipid && contractnumber) {
            updateAuthToekn(dealershipid, contractnumber)
        }
    }, [])

    const updateAuthToekn = async (dealershipid: any, contractnumber: any) => {
        let path = '/api/v1/payment/setup_customer';
        try {
            if (dealershipid && contractnumber) {
                const response: any = await API.patch(getAPIName(), path,
                {
                    body: {
                        status: true,
                        dealership_id: dealershipid,
                        referencenumber: contractnumber
                    }
                });
            }
        } catch (e) {
            console.log(e);
            alert(e)
        }

    }


    return (
        <>
            <div className={classes.main_box}>
                <h2 className={classes.heading}>payment method has been saved </h2>
                <h1 className={classes.stat}> SUCCESSFULLY </h1>
                <div className={classes.buttondiv} onClick={() => handleredirect()}>TAKE ANOTHER CC AUTH</div>
            </div>

        </>
    );
};

export default AuthorisePaymentSuccess;