import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listAuthTokens } from '../../graphql/queries';

const listTodos = `query listTodos {
    listTodos{
      items{
        id
        name
        description
      }
    }
  }`;

const addTodo = `mutation createTodo($name:String! $description: String!) {
    createTodo(input:{
      name:$name
      description:$description
    }){
      id
      name
      description
    }
  }`;

/*const addAuth = `mutation createAuth($name:String! $description: String!) {
  createTodo(input:{
    name:$name
    description:$description
  }){
    id
    name
    description
  }
}`; */

class HelpSupport extends React.Component {
  todoMutation = async () => {
    const todoDetails = {
      name: 'Party tonight!',
      description: 'Amplify CLI rocks!'
    };

    const newTodo = await API.graphql(graphqlOperation(addTodo, todoDetails));
    alert(JSON.stringify(newTodo));
  };

  listQuery = async () => {
    console.log('listing todos');
    const allTodos = await API.graphql(graphqlOperation(listTodos));
    alert(JSON.stringify(allTodos));
  };

  listAuth = async () => {
    console.log('listing auth');
    const allTodos = await API.graphql(graphqlOperation(listAuthTokens));
    alert(JSON.stringify(allTodos));
  };

  render() {
    return (
      <div className="App">
        <button onClick={this.listQuery}>Todo Query</button>
        <button onClick={this.todoMutation}>Todo Mutation</button>
        <br />

        <button onClick={this.listAuth}>Auth Query</button>
        <button onClick={this.todoMutation}>Auth Mutation</button>
      </div>
    );
  }
}

export default HelpSupport;
