import React, { useEffect, useRef, useState } from 'react';
import { API } from 'aws-amplify';
import { makeStyles, fade } from '@material-ui/core/styles';
import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import Dealership from '../../_models/Dealership';
import moment, { tz } from "moment-timezone";
import ReactTable, { Column} from 'react-table';
import { getAPIName } from '../../config';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import handleApiErrors from '../../common/handleApiErrors';
import { useSnackbar } from 'notistack';
import { HorizontalBar } from 'react-chartjs-2';
import "chartjs-plugin-datalabels";


import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Divider,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import {
    faEdit,
    faCalendarAlt,
    faAngleLeft,
    faAngleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faCheck,
    faTimes,
    faSortNumericDown
} from '@fortawesome/free-solid-svg-icons';
library.add(
    faEdit as IconDefinition,
    faCalendarAlt as IconDefinition,
    faAngleLeft as IconDefinition,
    faAngleRight as IconDefinition,
    faAngleDoubleLeft as IconDefinition,
    faAngleDoubleRight as IconDefinition,
    faCheck as IconDefinition,
    faTimes as IconDefinition
);
const useStyles = makeStyles((theme) => ({
    headerPadding: {
        paddingBottom: '2rem',
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(3),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
        display: 'flex',
    },
    searchIcon: {
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        // width: 200,
    },
    input: {
        color: 'white',
    },
    card: {
        // marginRight: theme.spacing(1),
        // marginLeft: theme.spacing(1),
        // marginBottom: theme.spacing(1),
    },
    subMenu: {
        backgroundColor: '#484a54',
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
    },
    select: {
        color: 'white',
    },
    excelDownload: {
        marginLeft: '1000px',
    }
}));

interface IStatistics {
    tollCreditSum: number;
    contractProcessedCount: number;
    tollTransactionsProcessedCount: number;
    invoicesChargedCount: number;
    avgTollContractCount: number;
    vehicleWithTollsCount: number;
    // avgTollVehicleCount: number;
}
interface IStatisticsTitles {
    tollCreditSum: string;
    contractProcessedCount: string;
    tollTransactionsProcessedCount: string;
    invoicesChargedCount: string;
    avgTollContractCount: string;
    vehicleWithTollsCount: string;
    // avgTollVehicleCount: string;
}
const statisticTitles: IStatisticsTitles = {
    tollCreditSum: 'Toll Credits',
    contractProcessedCount: 'Total Contracts Processed',
    tollTransactionsProcessedCount: 'Total Toll Transaction Processed',
    invoicesChargedCount: 'Total Invoice Charged',
    avgTollContractCount: 'Avg Tolls Per Contract',
    vehicleWithTollsCount: 'Number of vehicles with tolls',
    // avgTollVehicleCount: 'Avg Tolls Per Day Per Vehicle',
};

function compareDealerships(a: Dealership, b: Dealership) {
    if (a.name > b.name)
        return 1;
    if (b.name > a.name)
        return -1;
    return 0;
}

export default function TollsReport() {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [searchType, setSearchType] = useState<string>('');
    const [dealershipId, setDealershipId] = useState<number | undefined>(0);
    const [dealershipGroupId, setDealershipGroupId] = useState<number | undefined>(0);
    const [dealershipOptions, setDealershipOptions] = useState<any[]>([]);
    const [csvIsLoading, setCsvIsLoading] = useState<boolean>(false);
    const currentDate = new Date();
    const formatDate = (date: Date): string => {
        return `${date.getFullYear()}-${new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date)}-${new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)}`;
    };

    const [startDate, setStartDate] = useState<string>(
        formatDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1))
    );
    const [endDate, setEndDate] = useState<string>(
        formatDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1))
    );
    const convertDateToUTC = (date: string) => {
        if (date) {
            let dateFull = new Date(date);
            dateFull.setHours(0, 0, 0, 0);
            const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
            return new Date(dateFull.toLocaleString('en-US', { timeZone, hour12: true })).toISOString();
        }
        return null;
    };

    var defaultValue = true;
    var searchTypes = 'None';
    const [tiles, setTiles] = useState<IStatistics>({
        tollCreditSum: 0,
        contractProcessedCount: 0,
        tollTransactionsProcessedCount: 0,
        invoicesChargedCount: 0,
        avgTollContractCount: 0,
        vehicleWithTollsCount: 0
        // avgTollVehicleCount: 0,
    });

    const handleDealershipOptionsDropdown = (e: any) => {
        if (searchType === 'dealership') {
            setDealershipId(e.target.value.toString());
        }
        if (searchType === 'dealershipGroup')
            setDealershipGroupId(e.target.value.toString());
    };
    const renderDealershipOptions = (dealershipOptions: any) => {
        let options = dealershipOptions.map((dealership: any) => {
            return (
                <MenuItem key={dealership.id} value={dealership.id}>
                    {dealership.name}
                </MenuItem>
            );
        });
        options.unshift(
            <MenuItem key={'0'} value={0}>
                Select Dealership
            </MenuItem>
        );
        return options;
    };
    const renderDealershipGroupOptions = (dealershipOptions: any) => {
        let options = dealershipOptions.map((dealership: any) => {
            return (
                <MenuItem key={dealership.id} value={dealership.id}>
                    {dealership.name}
                </MenuItem>
            );
        });
        options.unshift(
            <MenuItem key={'0'} value={0}>
                Select Dealership Group
            </MenuItem>
        );
        return options;
    };
    const getDealerships = () => {
        let apiName = getAPIName();
        let path = '/dealerships';
        let myInit = {};
        API.get(apiName, path, myInit)
            .then((response) => {
                const results = response.body;
                setDealershipOptions(results.sort(compareDealerships));
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const getDealershipGroups = () => {
        let apiName = getAPIName();
        let path = '/dealershipgroups';
        let myInit = {};
        API.get(apiName, path, myInit)
            .then((response) => {
                const results = response.body;
                setDealershipOptions(results);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleSearchTypeChange = (e: any) => {
        let type = e.target.value;
        setSearchType(type);
        if (type === 'dealership') {
            getDealerships();
        } else if (type === 'dealershipGroup') {
            getDealershipGroups();
        }
    };

    const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(e.target.value);
    };
    const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(e.target.value);
    };

    const handleSearch = async (): Promise<void> => {
        defaultValue = false;
        if (startDate && new Date(endDate) < new Date(startDate)) {
            enqueueSnackbar("End date cannot be less than the start date",{ variant: 'error' });
            setEndDate('')
            return;
        }
        const response = await getTolls({});
        if (response.body) {
            setTolls(response.body);
            setGraphData(response.body);
            setTiles({ ...tiles, ...response.body['static'][0] });
        }
    };
    const [tolls, setTolls] = useState<any>({
        tollsDetails: [],
    });
    const [graphData, setGraphData] = useState<any>({});
    const handleCsvButton = async (): Promise<void> => {
        setCsvIsLoading(true);
        const init = {
            headers: {
                accept: 'text/csv',
                timezone: moment.tz.guess(),
            },
            responseType: 'text',
        };
        defaultValue = false;
        const response = await getTolls(init);
        const currentDate = moment().format('YYYYMMDD-hhmmss')
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(response);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "Toll_Report_Summary" + currentDate + fileExtension);
        setCsvIsLoading(false);
    };
    const getTolls = async (apiInit: any = {}) => {
        let queries: Array<{ name: string; value: string }> = [];
        if (defaultValue === true) {
            queries.push({ name: 'type', value: `${searchTypes}` });
            if (convertDateToUTC(startDate) != '' || convertDateToUTC(startDate) != null)
                queries.push({ name: 'startDate', value: `${convertDateToUTC(startDate)}` });
            if (convertDateToUTC(endDate) != '' || convertDateToUTC(endDate) != null)
                queries.push({ name: 'endDate', value: `${convertDateToUTC(endDate)}` });
        }
        if (defaultValue === false) {
            queries.push({ name: 'type', value: `${searchType}` });
            if (dealershipId != 0 && searchType === 'dealership')
                queries.push({ name: 'dealershipid', value: `${dealershipId}` });
            if (dealershipGroupId != 0 && searchType === 'dealershipGroup')
                queries.push({ name: 'dealershipgroupid', value: `${dealershipGroupId}`});
            if (convertDateToUTC(startDate) != '' || convertDateToUTC(startDate) != null)
                queries.push({ name: 'startDate', value: `${convertDateToUTC(startDate)}` });
            if (convertDateToUTC(endDate) != '' || convertDateToUTC(endDate) != null)
                queries.push({ name: 'endDate', value: `${convertDateToUTC(endDate)}` });
        }
        let path: string = '/tollsreport';
        if (queries.length > 0) {
            const paramStrings: string[] = queries.map(q => `${encodeURIComponent(q.name)}=${encodeURIComponent(q.value)}`);
            path = `${path}?${paramStrings.join("&")}`;
        }
        try {
            return await API.get(getAPIName(), path, apiInit);
        } catch (e) {
            handleApiErrors(e, enqueueSnackbar);
            return [[], 0];
        }
    };

    const fetchDefaultData = async (): Promise<void> => {
        const response = await getTolls({});
        if (response.body) {
            setTiles({ ...tiles, ...response.body['static'][0] });
        }
    };

    useEffect(() => {
        if (defaultValue === true) {
            fetchDefaultData();
        }
    }, []);

    const columns: Column[] = [
        {
            Header: 'Group',
            accessor: 'dealershipgroup'
        },
        {
            Header: 'Store Name',
            accessor: 'dealershipname'
        },
        {
            Header: 'Tolls Pending',
            accessor: (row) => `$${row.totalPending}`,
            id: 'tolls-pending-column'
        },
        {
            Header: 'Tolls Charged',
            accessor: (row) => `$${row.totalCharged}`,
            id: 'tolls-charged-column'
        },
        {
            Header: 'Tolls Declined',
            accessor: (row) => `$${row.totalDeclined}`,
            id: 'tolls-declined-column'
        },
        {
            Header: 'Tolls w/o CC',
            accessor: (row) => `$${row.totalWithoutCC}`,
            id: 'tolls-without-cc-column'
        },
        {
            Header: 'Tolls w/o Contracts',
            accessor: (row) => `$${row.totalWithoutContract}`,
            id: 'tolls-without-contracts-column'
        },
        {
            Header: 'Total Tolls',
            accessor: (row) => `$${row.totalTolls}`,
            id: 'total-tolls-column'
        },
        {
            Header: 'Payment Gateway Fee',
            accessor: (row) => `$${row.totalPgwFee}`,
            id: 'payment-gateway-column'
        },
        {
            Header: 'Number of Vehicle',
            accessor: (row) => `${row.totalActiveVehicle}`,
            id: 'active-vehicle-column'
        },
    ];
    const renderDealershipTable = () => {
        const { tollsDetails } = tolls;
        return (
            <Grid container>
                <Grid xs={12}>
                    <ReactTable
                        className="-striped -highlight"
                        columns={columns}
                        data={tollsDetails}
                        defaultPageSize={10}
                    />
                </Grid>
            </Grid>
        );
    };

    function renderBarGraph(): [string[], string[], string[], string[], string[], string[], string[]] {
        var dealerName: any = [];
        var pendingCount: any = [];
        var chargedCount: any = [];
        var declinedCount: any = [];
        var missingCount: any = [];
        var withoutContractCount: any = [];
        var pgwCount: any = [];
        if (graphData['tollsDetails'] != undefined && graphData['tollsDetails'].length > 0) {
            var newextractedData = graphData['tollsDetails'];
            var newList = [];
            for (let j = 0; j < graphData['tollsDetails'].length; j++) {
                var dictVal = Object.keys(newextractedData[j]).map(key => newextractedData[j][key]);
                newList.push(dictVal);
            }

            for (let j = 0; j < newList.length; j++) {
                dealerName.push(newList[j][0])
                pendingCount.push(newList[j][2])
                chargedCount.push(newList[j][3])
                declinedCount.push(newList[j][4])
                missingCount.push(newList[j][5])
                withoutContractCount.push(newList[j][6])
                pgwCount.push(newList[j][7])
            }
        }
        return [dealerName, pendingCount, chargedCount, declinedCount, missingCount, withoutContractCount, pgwCount];
    };

    const [dealerName, pendingCount, chargedCount, declinedCount, missingCount, withoutContractCount, pgwCount] = renderBarGraph();
    const dataHorBar = {
        labels: dealerName,
        datasets: [
            {
                label: 'Tolls Pending',
                backgroundColor: '#63bff0',
                borderWidth: 1,
                data: pendingCount
            },
            {
                label: 'Tolls Charged',
                backgroundColor: '#1984c5',
                borderWidth: 1,
                data: chargedCount
            },
            {
                label: 'Tolls Declined',
                backgroundColor: '#c23728',
                borderWidth: 1,
                data: declinedCount
            },
            {
                label: 'Tolls w/o CC',
                backgroundColor: '#e14b31',
                borderWidth: 1,
                data: missingCount
            },
            {
                label: 'Tolls w/o Contracts',
                backgroundColor: '#e1a692',
                borderWidth: 1,
                data: withoutContractCount
            },
            {
                label: 'Payment Gateway',
                backgroundColor: '#e2e2e2',
                borderWidth: 1,
                data: pgwCount
            }
        ]
    };
    const options = {
        responsive: true,
        scales: {
            yAxes: [
                {
                    barPercentage: 0.7, // Make bars wider
                    categoryPercentage: 0.9, // Reduce the space between bars
                }
            ]
        },
        plugins: {
            datalabels: {
                display: true,
                color: "black",
                align: "end",
                anchor: "end",
                formatter: function (value: any, context: any) {
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                font: { size: "11" }
            }
        },
        legend: {
            display: true
        }
    };

    const formatMoney = (statistic: string, value: number) => {
        switch (statistic) {
            case 'contractProcessedCount':
            case 'invoicesChargedCount':
            case 'vehicleWithTollsCount':
                return new Intl.NumberFormat('en-US').format(value);
            case 'avgTollContractCount':
            case 'tollCreditSum':
            case 'tollTransactionsProcessedCount':
                return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(value);
        }
    };


    return (
        <div className="TollReport">
            <Grid item xs={9}>
                <Typography variant="h4" align="left">
                    Toll Reports <sup style={{ color: '#3f51b5' ,fontSize:'15px'}}>(Beta)</sup>
                </Typography>
            </Grid>
            <Divider />
            <div className={classes.grow}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={classes.subMenu}
                >
                    <Grid xs={12} md={3}>
                        <div className={classes.search}>
                            <Select
                                displayEmpty
                                value={searchType}
                                onChange={handleSearchTypeChange}
                                autoWidth={true}
                                inputProps={{
                                    classes: {
                                        root: classes.select,
                                    },
                                }}
                            >
                                <MenuItem key="0" value={''}>
                                    Select Search Type
                                </MenuItem>
                                <MenuItem key={'1'} value={'dealership'}>
                                    Dealership
                                </MenuItem>
                                <MenuItem key={'2'} value={'dealershipGroup'}>
                                    Dealership Group
                                </MenuItem>
                            </Select>
                        </div>
                    </Grid>

                    {searchType === 'dealership' && (
                        <Grid xs={12} md={3}>
                            <div className={classes.search}>
                                <Select
                                    value={dealershipId}
                                    onChange={handleDealershipOptionsDropdown}
                                    autoWidth={true}
                                    displayEmpty
                                    inputProps={{
                                        classes: {
                                            root: classes.select,
                                        },
                                    }}
                                >
                                    {dealershipOptions &&
                                        renderDealershipOptions(dealershipOptions)}
                                </Select>
                            </div>
                        </Grid>
                    )}
                    {searchType === 'dealershipGroup' && (
                        <Grid xs={12} md={3}>
                            <div className={classes.search}>
                                <Select
                                    value={dealershipGroupId}
                                    onChange={handleDealershipOptionsDropdown}
                                    autoWidth={true}
                                    displayEmpty
                                    inputProps={{
                                        classes: {
                                            root: classes.select,
                                        },
                                    }}
                                >
                                    {dealershipOptions &&
                                        renderDealershipGroupOptions(dealershipOptions)}
                                </Select>
                            </div>
                        </Grid>
                    )}

                    <div className={classes.grow} />
                    <Grid xs={12} md={3}>
                        <TextField
                            id="startDate"
                            label="Start Date"
                            type="date"
                            onChange={handleStartDateChange}
                            value={startDate}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                                className: classes.input,
                            }}
                            InputProps={{
                                className: classes.input,
                            }}
                        />
                    </Grid>
                    <Grid xs={12} md={3}>
                        <TextField
                            id="endDate"
                            label="End Date"
                            type="date"
                            onChange={handleEndDateChange}
                            value={endDate}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                                className: classes.input,
                            }}
                            InputProps={{
                                className: classes.input,
                            }}
                        />
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSearch}
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
                <br />
                <Divider />
                <br />
                <Grid container spacing={2}>
                    {Object.keys(tiles).map((statistic: string) => {
                        return (
                            <Grid item xs={12} md={4}>
                                <Card>
                                    <CardContent>
                                        <Typography
                                            color="textSecondary"
                                            gutterBottom
                                        >
                                            {statisticTitles[statistic as keyof IStatisticsTitles]}
                                        </Typography>
                                        <Typography variant="h5" component="h2">
                                            {formatMoney(
                                                statistic,
                                                tiles[statistic as keyof IStatistics]
                                            )}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
                <br />
                <Divider />
                <br />
                {!!tolls.tollsDetails.length && (
                    <>
                        <Grid container spacing={2}>
                            <HorizontalBar data={dataHorBar} options={options} width={500} height={300} />
                        </Grid>
                        <br />
                        <br />
                        <Divider />
                        <br />
                        <>
                            <Button size="small" variant="outlined" className={classes.excelDownload} onClick={handleCsvButton}>
                                {!csvIsLoading ? 'Download Excel' :
                                    <CircularProgress size={24} />}
                            </Button>
                            {renderDealershipTable()}
                            <br />
                            <br />
                            <Divider />
                        </>
                    </>
                )}
            </div>
        </div>
    );
}