// tslint:disable
// this is an auto generated file. This will be overwritten

export const listDealershipGroups = `query ListDealershipGroups(
  $filter: ModelDealershipGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listDealershipGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      Dealerships {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getDealershipGroup = `query GetDealershipGroup($id: ID!) {
  getDealershipGroup(id: $id) {
    id
    Dealerships {
      items {
        id
        Name
        Email
        Phone
        Line1
        Line2
        City
        ZipCode
        Timezone
      }
      nextToken
    }
  }
}
`;
export const listDealerships = `query ListDealerships(
  $filter: ModelDealershipFilterInput
  $limit: Int
  $nextToken: String
) {
  listDealerships(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      Name
      Email
      Phone
      Line1
      Line2
      City
      ZipCode
      Timezone
      DealershipGroup {
        id
      }
      State {
        id
        Name
      }
      Vehicles {
        nextToken
      }
      Contracts {
        nextToken
      }
      TollAccount {
        id
        CipherBlock
      }
    }
    nextToken
  }
}
`;
export const getDealership = `query GetDealership($id: ID!) {
  getDealership(id: $id) {
    id
    Name
    Email
    Phone
    Line1
    Line2
    City
    ZipCode
    Timezone
    DealershipGroup {
      id
      Dealerships {
        nextToken
      }
    }
    State {
      id
      Name
    }
    Vehicles {
      items {
        id
        LicensePlate
      }
      nextToken
    }
    Contracts {
      items {
        id
        ContractNumber
        FirstName
        LastName
        Address
        City
        State
        Zip
        DateIn
        DateOut
        LMS
      }
      nextToken
    }
    TollAccount {
      id
      CipherBlock
      Dealership {
        id
        Name
        Email
        Phone
        Line1
        Line2
        City
        ZipCode
        Timezone
      }
      TollAgency {
        id
        Name
      }
    }
  }
}
`;
export const listUsStates = `query ListUsStates(
  $filter: ModelUSStateFilterInput
  $limit: Int
  $nextToken: String
) {
  listUSStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      Name
    }
    nextToken
  }
}
`;
export const getUsState = `query GetUsState($id: ID!) {
  getUSState(id: $id) {
    id
    Name
  }
}
`;
export const listVehicles = `query ListVehicles(
  $filter: ModelVehicleFilterInput
  $limit: Int
  $nextToken: String
) {
  listVehicles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      LicensePlate
      Dealership {
        id
        Name
        Email
        Phone
        Line1
        Line2
        City
        ZipCode
        Timezone
      }
      Tolls {
        nextToken
      }
      Contracts {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getVehicle = `query GetVehicle($id: ID!) {
  getVehicle(id: $id) {
    id
    LicensePlate
    Dealership {
      id
      Name
      Email
      Phone
      Line1
      Line2
      City
      ZipCode
      Timezone
      DealershipGroup {
        id
      }
      State {
        id
        Name
      }
      Vehicles {
        nextToken
      }
      Contracts {
        nextToken
      }
      TollAccount {
        id
        CipherBlock
      }
    }
    Tolls {
      items {
        id
      }
      nextToken
    }
    Contracts {
      items {
        id
        ContractNumber
        FirstName
        LastName
        Address
        City
        State
        Zip
        DateIn
        DateOut
        LMS
      }
      nextToken
    }
  }
}
`;
export const getToll = `query GetToll($id: ID!) {
  getToll(id: $id) {
    id
    Vehicle {
      id
      LicensePlate
      Dealership {
        id
        Name
        Email
        Phone
        Line1
        Line2
        City
        ZipCode
        Timezone
      }
      Tolls {
        nextToken
      }
      Contracts {
        nextToken
      }
    }
  }
}
`;
export const listTolls = `query ListTolls(
  $filter: ModelTollFilterInput
  $limit: Int
  $nextToken: String
) {
  listTolls(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      Vehicle {
        id
        LicensePlate
      }
    }
    nextToken
  }
}
`;
export const getContract = `query GetContract($id: ID!) {
  getContract(id: $id) {
    id
    ContractNumber
    FirstName
    LastName
    Address
    City
    State
    Zip
    DateIn
    DateOut
    LMS
    Dealership {
      id
      Name
      Email
      Phone
      Line1
      Line2
      City
      ZipCode
      Timezone
      DealershipGroup {
        id
      }
      State {
        id
        Name
      }
      Vehicles {
        nextToken
      }
      Contracts {
        nextToken
      }
      TollAccount {
        id
        CipherBlock
      }
    }
    Vehicle {
      id
      LicensePlate
      Dealership {
        id
        Name
        Email
        Phone
        Line1
        Line2
        City
        ZipCode
        Timezone
      }
      Tolls {
        nextToken
      }
      Contracts {
        nextToken
      }
    }
    AuthToken {
      id
      Contract {
        id
        ContractNumber
        FirstName
        LastName
        Address
        City
        State
        Zip
        DateIn
        DateOut
        LMS
      }
    }
    MerchantLogin {
      id
      PaymentGateway
      MerchantId
      MerchantName
      CipherBlock
      PrimaryUrl
      Contracts {
        nextToken
      }
    }
  }
}
`;
export const listContracts = `query ListContracts(
  $filter: ModelContractFilterInput
  $limit: Int
  $nextToken: String
) {
  listContracts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      ContractNumber
      FirstName
      LastName
      Address
      City
      State
      Zip
      DateIn
      DateOut
      LMS
      Dealership {
        id
        Name
        Email
        Phone
        Line1
        Line2
        City
        ZipCode
        Timezone
      }
      Vehicle {
        id
        LicensePlate
      }
      AuthToken {
        id
      }
      MerchantLogin {
        id
        PaymentGateway
        MerchantId
        MerchantName
        CipherBlock
        PrimaryUrl
      }
    }
    nextToken
  }
}
`;
export const listAuthTokens = `query ListAuthTokens(
  $filter: ModelAuthTokenFilterInput
  $limit: Int
  $nextToken: String
) {
  listAuthTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      Contract {
        id
        ContractNumber
        FirstName
        LastName
        Address
        City
        State
        Zip
        DateIn
        DateOut
        LMS
      }
    }
    nextToken
  }
}
`;
export const getAuthToken = `query GetAuthToken($id: ID!) {
  getAuthToken(id: $id) {
    id
    Contract {
      id
      ContractNumber
      FirstName
      LastName
      Address
      City
      State
      Zip
      DateIn
      DateOut
      LMS
      Dealership {
        id
        Name
        Email
        Phone
        Line1
        Line2
        City
        ZipCode
        Timezone
      }
      Vehicle {
        id
        LicensePlate
      }
      AuthToken {
        id
      }
      MerchantLogin {
        id
        PaymentGateway
        MerchantId
        MerchantName
        CipherBlock
        PrimaryUrl
      }
    }
  }
}
`;
export const getInvoice = `query GetInvoice($id: ID!) {
  getInvoice(id: $id) {
    id
    Contract {
      id
      ContractNumber
      FirstName
      LastName
      Address
      City
      State
      Zip
      DateIn
      DateOut
      LMS
      Dealership {
        id
        Name
        Email
        Phone
        Line1
        Line2
        City
        ZipCode
        Timezone
      }
      Vehicle {
        id
        LicensePlate
      }
      AuthToken {
        id
      }
      MerchantLogin {
        id
        PaymentGateway
        MerchantId
        MerchantName
        CipherBlock
        PrimaryUrl
      }
    }
  }
}
`;
export const listInvoices = `query ListInvoices(
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      Contract {
        id
        ContractNumber
        FirstName
        LastName
        Address
        City
        State
        Zip
        DateIn
        DateOut
        LMS
      }
    }
    nextToken
  }
}
`;
export const listTollAgencys = `query ListTollAgencys(
  $filter: ModelTollAgencyFilterInput
  $limit: Int
  $nextToken: String
) {
  listTollAgencys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      Name
      TollAccounts {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getTollAgency = `query GetTollAgency($id: ID!) {
  getTollAgency(id: $id) {
    id
    Name
    TollAccounts {
      items {
        id
        CipherBlock
      }
      nextToken
    }
  }
}
`;
export const getTollAccount = `query GetTollAccount($id: ID!) {
  getTollAccount(id: $id) {
    id
    CipherBlock
    Dealership {
      id
      Name
      Email
      Phone
      Line1
      Line2
      City
      ZipCode
      Timezone
      DealershipGroup {
        id
      }
      State {
        id
        Name
      }
      Vehicles {
        nextToken
      }
      Contracts {
        nextToken
      }
      TollAccount {
        id
        CipherBlock
      }
    }
    TollAgency {
      id
      Name
      TollAccounts {
        nextToken
      }
    }
  }
}
`;
export const listTollAccounts = `query ListTollAccounts(
  $filter: ModelTollAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  listTollAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      CipherBlock
      Dealership {
        id
        Name
        Email
        Phone
        Line1
        Line2
        City
        ZipCode
        Timezone
      }
      TollAgency {
        id
        Name
      }
    }
    nextToken
  }
}
`;
export const getPaymentGateway = `query GetPaymentGateway($id: ID!) {
  getPaymentGateway(id: $id) {
    id
  }
}
`;
export const listPaymentGateways = `query ListPaymentGateways(
  $filter: ModelPaymentGatewayFilterInput
  $limit: Int
  $nextToken: String
) {
  listPaymentGateways(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
    }
    nextToken
  }
}
`;
export const listMerchantLogins = `query ListMerchantLogins(
  $filter: ModelMerchantLoginFilterInput
  $limit: Int
  $nextToken: String
) {
  listMerchantLogins(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      PaymentGateway
      MerchantId
      MerchantName
      CipherBlock
      PrimaryUrl
      Contracts {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getMerchantLogin = `query GetMerchantLogin($id: ID!) {
  getMerchantLogin(id: $id) {
    id
    PaymentGateway
    MerchantId
    MerchantName
    CipherBlock
    PrimaryUrl
    Contracts {
      items {
        id
        ContractNumber
        FirstName
        LastName
        Address
        City
        State
        Zip
        DateIn
        DateOut
        LMS
      }
      nextToken
    }
  }
}
`;
export const getJobCenpos = `query GetJobCenpos($id: ID!) {
  getJobCenpos(id: $id) {
    id
    token
    referenceNumber
    merchantId
    invoiceId
    contractNumber
    paymentGateway
    address1
    address2
    city
    state
    zip
    insertedDate
  }
}
`;
export const listJobCenposs = `query ListJobCenposs(
  $filter: ModeljobCenposFilterInput
  $limit: Int
  $nextToken: String
) {
  listJobCenposs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      token
      referenceNumber
      merchantId
      invoiceId
      contractNumber
      paymentGateway
      address1
      address2
      city
      state
      zip
      insertedDate
    }
    nextToken
  }
}
`;
export const getJobTempus = `query GetJobTempus($id: ID!) {
  getJobTempus(id: $id) {
    id
    token
    contractNumber
    tokenProviderId
    ccSystemCode
    insertedDate
  }
}
`;
export const listJobTempuss = `query ListJobTempuss(
  $filter: ModeljobTempusFilterInput
  $limit: Int
  $nextToken: String
) {
  listJobTempuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      token
      contractNumber
      tokenProviderId
      ccSystemCode
      insertedDate
    }
    nextToken
  }
}
`;
export const getJobNtta = `query GetJobNtta($id: ID!) {
  getJobNTTA(id: $id) {
    id
    DealershipName
    InsertedTimestamp
    BatchId
    entryDate
    entryLane
    entryLocation
    exitDate
    exitLane
    exitLocation
    postedDate
    tagId
    licensePlate
    transactionType
    discount
    amount
  }
}
`;
export const listJobNttAs = `query ListJobNttAs(
  $filter: ModeljobNTTAFilterInput
  $limit: Int
  $nextToken: String
) {
  listJobNTTAs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      DealershipName
      InsertedTimestamp
      BatchId
      entryDate
      entryLane
      entryLocation
      exitDate
      exitLane
      exitLocation
      postedDate
      tagId
      licensePlate
      transactionType
      discount
      amount
    }
    nextToken
  }
}
`;
export const getJobTxTag = `query GetJobTxTag($id: ID!) {
  getJobTxTag(id: $id) {
    id
    postingDate
    transactionDate
    time
    transactionNumber
    plate
    roadway
    description
    amount
    transactionEpochDateTime
  }
}
`;
export const listJobTxTags = `query ListJobTxTags(
  $filter: ModeljobTxTagFilterInput
  $limit: Int
  $nextToken: String
) {
  listJobTxTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      postingDate
      transactionDate
      time
      transactionNumber
      plate
      roadway
      description
      amount
      transactionEpochDateTime
    }
    nextToken
  }
}
`;
export const getJobArs = `query GetJobArs($id: ID!) {
  getJobARS(id: $id) {
    id
    dealershipName
    rentalNumber
    RONumber
    firstName
    lastName
    emailAddress
    Address
    City
    State
    Zip
    Country
    licensePlate
    dateOut
    dateIn
    Processed
  }
}
`;
export const listJobArSs = `query ListJobArSs(
  $filter: ModeljobARSFilterInput
  $limit: Int
  $nextToken: String
) {
  listJobARSs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      dealershipName
      rentalNumber
      RONumber
      firstName
      lastName
      emailAddress
      Address
      City
      State
      Zip
      Country
      licensePlate
      dateOut
      dateIn
      Processed
    }
    nextToken
  }
}
`;
export const getJobSunpassActivity = `query GetJobSunpassActivity($id: ID!) {
  getJobSunpassActivity(id: $id) {
    transactionNumber
    postedDate
    transactionDate
    transactionTime
    transponder
    agencyName
    lane
    axle
    description
    debit
    credit
    balance
    transactionEpochDateTime
  }
}
`;
export const listJobSunpassActivitys = `query ListJobSunpassActivitys(
  $filter: ModeljobSunpassActivityFilterInput
  $limit: Int
  $nextToken: String
) {
  listJobSunpassActivitys(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      transactionNumber
      postedDate
      transactionDate
      transactionTime
      transponder
      agencyName
      lane
      axle
      description
      debit
      credit
      balance
      transactionEpochDateTime
    }
    nextToken
  }
}
`;
export const getJobSunpassTransponder = `query GetJobSunpassTransponder($id: ID!) {
  getJobSunpassTransponder(id: $id) {
    transponder
    transponderType
    transponderStatus
    associatedPlate
    friendlyName
  }
}
`;
export const listJobSunpassTransponders = `query ListJobSunpassTransponders(
  $filter: ModeljobSunpassTransponderFilterInput
  $limit: Int
  $nextToken: String
) {
  listJobSunpassTransponders(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      transponder
      transponderType
      transponderStatus
      associatedPlate
      friendlyName
    }
    nextToken
  }
}
`;
export const getJobSunpassVehicle = `query GetJobSunpassVehicle($id: ID!) {
  getJobSunpassVehicle(id: $id) {
    id
    licensePlate
    state
    plateType
    make
    model
    year
    color
    startDateTime
    endDate
    isRentalVehicle
    isTrailer
  }
}
`;
export const listJobSunpassVehicles = `query ListJobSunpassVehicles(
  $filter: ModeljobSunpassVehicleFilterInput
  $limit: Int
  $nextToken: String
) {
  listJobSunpassVehicles(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      licensePlate
      state
      plateType
      make
      model
      year
      color
      startDateTime
      endDate
      isRentalVehicle
      isTrailer
    }
    nextToken
  }
}
`;
export const getJobTsdFleet = `query GetJobTsdFleet($id: ID!) {
  getJobTsdFleet(id: $id) {
    id
    accountNumber
    licensePlateNumber
    licensePlateState
    vin
    locationCode
    inServiceDate
    Processed
  }
}
`;
export const listJobTsdFleets = `query ListJobTsdFleets(
  $filter: ModeljobTsdFleetFilterInput
  $limit: Int
  $nextToken: String
) {
  listJobTsdFleets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountNumber
      licensePlateNumber
      licensePlateState
      vin
      locationCode
      inServiceDate
      Processed
    }
    nextToken
  }
}
`;
export const getJobTsdContract = `query GetJobTsdContract($id: ID!) {
  getJobTsdContract(id: $id) {
    id
    accountNumber
    locationOut
    contractNumber
    unitNumber
    vin
    checkOutDateTime
    checkInDateTime
    expectedCheckInDateTime
    licensePlateNumber
    licensePlateState
    roNumber
    serviceAdvisor
    Processed
  }
}
`;
export const listJobTsdContracts = `query ListJobTsdContracts(
  $filter: ModeljobTsdContractFilterInput
  $limit: Int
  $nextToken: String
) {
  listJobTsdContracts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountNumber
      locationOut
      contractNumber
      unitNumber
      vin
      checkOutDateTime
      checkInDateTime
      expectedCheckInDateTime
      licensePlateNumber
      licensePlateState
      roNumber
      serviceAdvisor
      Processed
    }
    nextToken
  }
}
`;
