import React, { useEffect, useState } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main_box:{
        background:'azure',
        padding: '100px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        margin:'auto'
    },
    heading:{
        color:'darkgrey'
    },
    stat:{
        color:'red'
    },
    buttondiv:{
        color:'white',
        padding: '6px',
        background:'darkgrey',
        '&:hover': {
            background: "black",
          }
    }
  })
);

const AuthorisePaymentFail = () => {
    const classes = useStyles();
    const handleredirect = () =>{
        window.location.replace(window.location.protocol + "//" + window.location.hostname + "/authorise")
    }


    const [error_message , set_error_message] = useState('')
    const geterrormessage = () =>{
        const query = new URLSearchParams(window.location.search);
        const temp:any  = query.get("message")
        set_error_message(temp ? temp : '')
    }
    useEffect(()=>{
        geterrormessage()
    },[])
    return (
        <>
        <div className={classes.main_box}>
            <h2 className={classes.heading}>payment method not saved successfully</h2> 
            {error_message && <h1 className={classes.stat}> {error_message}  </h1>}  
            <div className={classes.buttondiv} onClick={()=>handleredirect()}>TAKE ANOTHER CC AUTH</div>
        </div>
        
        </>
    );
};

export default AuthorisePaymentFail;