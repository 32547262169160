import React, { ChangeEvent, useEffect } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200
        },
        dense: {
            marginTop: theme.spacing(2)
        },
        menu: {
            width: 200
        }
    })
);

export interface DropDownState {
    readonly statusSelection?: DropDownOption;
}

export interface DropDownOption {
    readonly name: string;
    readonly value: string;
}

export interface DropDownProperties {
    readonly onChange: (state: DropDownState) => void;
    readonly state: DropDownState;
    readonly dropdownOptions: DropDownOption[];
}

export default function DropDown(props: DropDownProperties) {
    const classes = useStyles();

    // handlers /////////////////////////////////////////////////////////////////
    const handleStatusDropdown = (event: any) => {
        props.onChange({
            ...props.state,
            statusSelection: event.target.value === ''
                ? undefined
                : props.dropdownOptions.filter(so => so.value === event.target.value)[0],
        });
    };

    // JSX.Element preprocessing /////////////////////////////////////////////////
    const statusOptionMenuItems = props.dropdownOptions.map(o =>
        <MenuItem key={o.value} value={o.value}>{o.name}</MenuItem>
    );
    statusOptionMenuItems.unshift(
        <MenuItem key="" value=""></MenuItem>
    );


    return (
        <FormControl style={{ width: '100%' }}>
            <InputLabel htmlFor="statusDropdown" shrink={false}>
                Charge Type
            </InputLabel>
            {/* We were using "zero width space" AKA \\u200B as a hidden break here. */}
            {/* Adding explicit breaks until we fix with CSS */}
            <br />
            <br />
            <br />
            <Select
                inputProps={{
                    name: 'status',
                    id: 'statusDropdown'
                }}
                onChange={handleStatusDropdown}
                value={props.state.statusSelection == null ? '' : props.state.statusSelection.value}
            >
                {statusOptionMenuItems}
            </Select>
        </FormControl>
    );
}
