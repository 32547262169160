import React, { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { getAPIName } from '../../config';
import TopForm, { TopFormState, StatusOption } from '../TopForm';
import handleApiErrors from '../../common/handleApiErrors';
import ReactTable, { Column, SortingRule } from 'react-table';
import moment from 'moment';
import {
    CircularProgress,
    Button,
    Grid,
    Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPencilAlt
} from '@fortawesome/free-solid-svg-icons';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { TollRates } from '../../_models/TollRate';
import TopFormSearch from '../TopFormSearch';
import { TopFormSearchOption } from '../TopFormSearch';
import TollAgencySelect from '../TollAgency-select.component';
import EditTollRatePopup from '../EditTollRatePopUp';

const searchOptions: TopFormSearchOption[] = [
    { label: 'Description Entry', value: 'description_entry' },
    // { label: 'Description Exit', value: 'description_exit' },
];
const DEFAULT_PAGE_SIZE: number = 10;
interface PageState {
    readonly page: number;
    readonly pageSize: number;
    readonly sortingRules: SortingRule[];
}
const defaultPageState: PageState = {
    page: 0,
    pageSize: 10,
    sortingRules: [{
        id: 'id',
        desc: false,
    }],
};
interface TollRateState {
    readonly toll_agency_id: number;
    readonly searchInput: string;
    readonly searchType: TopFormSearchOption;
}
const defaultState: TollRateState = {
    toll_agency_id: 0,
    searchInput: '',
    searchType: searchOptions[0],
};

export default function TollsRates() {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pageState, setPageState] = useState<PageState>(defaultPageState);
    const [searchValue, setSearchValue] = useState<string>('');
    const [state, setState] = React.useState<TollRateState>(defaultState);
    const [editTollRateFlag, setEditTollRateFlag] = useState(false)
    const [tollRateToEdit, setTollRateToEdit] = useState({})
    const [updatedTollRate, setUpdatedTollRate] = useState({})
    const [updateExecuted, setUpdateExecuted] = useState(false)

    // fetch stuff //////////////////////////////////////////////////////////////
    // invoices
    const [[tollRates, totalTollratesCount], setTollRates] = React.useState<[TollRates[], number]>([[], 0]);

    const handleTableFetchData = (state: {
        page: number;
        pageSize: number;
        sorted: SortingRule[];
    }): void => {
        setPageState({
            ...pageState,
            page: state.page,
            pageSize: state.pageSize,
            sortingRules: state.sorted,
        });
    };

    const getTollRates = async (paginate: boolean = true, apiInit: any = {}): Promise<any> => {
        const queries: Array<{ name: string; value: string }> = [];
        if (paginate) {
            queries.push({ name: 'limit', value: `${pageState.pageSize}` });
            queries.push({ name: 'offset', value: `${pageState.pageSize * pageState.page}` });
        }

        if (state.toll_agency_id !== 0)
            queries.push({ name: 'toll_agency_id', value: `${state.toll_agency_id}` });
        if (state.searchType.value !== '' && state.searchInput !== '')
            queries.push({
                name: 'search-term',
                value: state.searchInput,
            });

        // sortS
        let i: number = 0;
        for (let sortingRule of pageState.sortingRules) {
            if (sortingRule.id == 'rate_status' || sortingRule.id == 'edit') {
                queries.push({
                    name: `orderBy-0`,
                    value: "id",
                });
            }
            else {
                queries.push({
                    name: `orderBy-${i++}${sortingRule.desc === true ? '-desc' : ''}`,
                    value: `${sortingRule.id}`,
                });
            }
        }
        let path: string = '/api/v1/tollrates';
        if (queries.length >= 1) {
            const paramStrings: string[] = queries.map(q => `${encodeURIComponent(q.name)}=${encodeURIComponent(q.value)}`);
            path = `${path}?${paramStrings.join("&")}`;
        }

        try {
            return await API.get(getAPIName(), path, apiInit);
        } catch (e) {
            handleApiErrors(e, enqueueSnackbar);
        }
    };

    // JSX.Element preprocessing /////////////////////////////////////////////////
    let pages: number =
        totalTollratesCount === 0 || pageState.pageSize === 0
            ? 0
            : Math.trunc(totalTollratesCount / pageState.pageSize);
    if (
        pages > 0 &&
        totalTollratesCount > pageState.pageSize &&
        totalTollratesCount % pageState.pageSize > 0
    )
        ++pages;
    const toggleTollRateEditPopup = () => {
        setEditTollRateFlag(!editTollRateFlag);
    }
    const columns: Column[] = [
        {
            accessor: 'id',
            Header: 'Id',
        },
        {
            accessor: (tollrate: TollRates) => tollrate.description_entry == ''
                ? '—'
                : tollrate.description_entry,
            Header: 'Description Entry',
            id: 'description_entry',
        },
        {
            accessor: (tollrate: TollRates) => tollrate.description_exit == ''
                ? '—'
                : tollrate.description_exit,
            Header: 'Description Exit',
            id: 'stadescription_exite',
        },
        {
            accessor: (tollrate: TollRates) => tollrate.effective_start_date == '' || tollrate.effective_start_date == null
                ? '—'
                : moment(tollrate.effective_start_date).local().format('MM/DD/YYYY'),
            Header: 'Effective Start',
            id: 'effective_start_date',
        },
        {
            accessor: (tollrate: TollRates) => tollrate.effective_end_date == '' || tollrate.effective_end_date == null
                ? '—'
                : moment(tollrate.effective_end_date).local().format('MM/DD/YYYY'),
            Header: 'Effective End',
            id: 'effective_end_date',
        },
        {
            accessor: (tollrate: TollRates) => tollrate.transponder_rate_axle_2 && tollrate.transponder_rate_axle_2 > 0 ? `$${(+tollrate.transponder_rate_axle_2).toFixed(2)}` : 0.0,
            Header: 'Transponder Rate',
            id: 'transponder_rate_axle_2',
        },
        {
            accessor: (tollrate: TollRates) => tollrate.cash_rate_axle_2 && tollrate.cash_rate_axle_2 > 0 ? `$${(+tollrate.cash_rate_axle_2).toFixed(2)}` : 0.0,
            Header: 'Cash Rate',
            id: 'cash_rate_axle_2',
        },
        {
            accessor: (tollrate: TollRates) => tollrate.mid_rate_axle_2 && tollrate.mid_rate_axle_2 > 0 ? `$${(+tollrate.mid_rate_axle_2).toFixed(2)}` : 0.0,
            Header: 'Mid Rate',
            id: 'mid_rate_axle_2',
        },
        {
            accessor: (tollrate: TollRates) => tollrate.toll_agency?.name,
            Header: 'Toll Agency',
            id: 'toll_agency_id',
        },
        {
            accessor: (tollrate: TollRates) => tollrate.effective_end_date ? (moment(tollrate.effective_end_date) > moment() ? 'Active' : "Expired") : "-",
            Header: 'Status',
            id: 'rate_status',
        },
        {
            accessor: (tollrate: TollRates) => !tollrate
                ? '—'
                : <Button
                    variant="outlined"
                    className="blue-button margin-right"
                    onClick={() => editTollRate(tollrate)}
                >
                    <FontAwesomeIcon icon={faPencilAlt} />
                </Button>,
            Header: "Edit",
            id: 'edit',
        },
    ];

    const editTollRate = (tollrate: TollRates) => {
        setTollRateToEdit(tollrate);
        setEditTollRateFlag(true);
    }
    const resetPaginationState = (): void => {
        setPageState({
            ...pageState,
            page: 0,
        });
    }
    const handleSearchInputChange = (searchInput: string): void => {
        resetPaginationState();
        setState({
            ...state,
            searchInput: searchInput,
        });
    };
    const handleSearchTypeChange = (searchType: TopFormSearchOption): void => {
        resetPaginationState();
        setState({
            ...state,
            searchType: searchType,
        });
    };
    const handleTollAgencySelect = (id: number): void => {
        resetPaginationState();
        setState({
            ...state,
            toll_agency_id: id,
        });
    };

    const searchTollRates = async (): Promise<void> => {
        setIsLoading(true);
        const response = await getTollRates(true, {});
        setTollRates([response.body, response.totalRecordCount]);
        setIsLoading(false);
    }
    const handleSearch = (): void => {
        searchTollRates();
    };

    const updateTollRate = async (updatedTollRate: any) => {
        let apiName = getAPIName();
        let path = `/api/v1/tollrates/${updatedTollRate.id}`;
        let unwanted_keys = ["id","inserted_timestamp","toll_agency"]
        let postBody = Object.fromEntries(Object.entries(updatedTollRate).filter(([key]) => !unwanted_keys.includes(key)));
        try {
            if (isNaN(updatedTollRate.cash_rate_axle_2) || isNaN(updatedTollRate.mid_rate_axle_2))
                enqueueSnackbar('enter valid amount', { variant: 'error' });
            else {
                const response = await API.put(apiName, path, { body: postBody });
                if (response.body['numberOfRecordsUpdated'] > 0) {
                    enqueueSnackbar('toll rate updates saved!', { variant: 'success' });
                }
                else {
                    handleApiErrors("cannot update the toll rate", enqueueSnackbar, 'toll rate')
                }
                setUpdateExecuted(!updateExecuted)
                toggleTollRateEditPopup();
                setTollRateToEdit({})
            }
        } catch (e) {
            handleApiErrors(e, enqueueSnackbar, 'toll rate');
        }
    };
    useEffect(() => { searchTollRates(); }, [pageState.page, pageState.pageSize, pageState.sortingRules, updateExecuted]);
    // render ///////////////////////////////////////////////////////////////////
    return (
        <div>
            <Grid container>
                <Grid item xs={9}>
                    <Typography variant="h4" align="left">
                        Toll Rates
                    </Typography>
                </Grid>

            </Grid>
            <div className="TopForm">
                <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                        <TollAgencySelect
                            tollAgencyId={state.toll_agency_id}
                            onChangeTollAgency={handleTollAgencySelect}
                        />
                    </Grid>


                    <Grid item sm={6} xs={12}>
                        <TopFormSearch
                            onSearchInputChange={handleSearchInputChange}
                            onSearchTypeChange={handleSearchTypeChange}
                            searchInput={state.searchInput}
                            searchOptions={searchOptions}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSearch}
                        >
                            Search
                        </Button>
                    </Grid>

                </Grid>

            </div>
            <Grid container>
                <Grid item xs={12}>
                    <ReactTable
                        className="-striped -highlight"
                        columns={columns}
                        data={tollRates}
                        defaultPageSize={DEFAULT_PAGE_SIZE}
                        defaultSorted={defaultPageState.sortingRules}
                        loading={isLoading}
                        manual
                        onFetchData={handleTableFetchData}
                        page={pageState.page}
                        pages={pages}
                    />
                </Grid>
            </Grid>
            <EditTollRatePopup
                tollRate={tollRateToEdit}
                isOpen={editTollRateFlag}
                text="Edit Toll Rate"
                handleSave={updateTollRate}
                toggleOpen={toggleTollRateEditPopup}
                updatedTollRate={updatedTollRate}
                setUpdatedTollRate={setUpdatedTollRate}
            />
        </div>
    );
}