import moment from "moment";

interface AnyObject {
  [key: string]: any;
}

export function filterObjectKeys(obj: AnyObject, filterkeys: string[], remove: boolean = false): AnyObject {
  return Object.keys(obj)
    .filter(key =>  remove ? !filterkeys.includes(key) : filterkeys.includes(key))
    .reduce((newObj: AnyObject, key: string) => {
      newObj[key] = obj[key];
      return newObj;
    }, {});
}

//Trims whitespace from all string values in an object.
export function trimObjectValues(obj: AnyObject): AnyObject {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => 
      [key, typeof value === 'string' ? value.trim() : value]
    )
  );
}

const validateEmail = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const validateNumber = (number: string) => {
  return number.match(
    /^(?:[1-9]\d*|0)?(?:\.\d+)?$/
  );
};

export const convertUTCToLocal = (utcDateTime: any) => {
  return moment.utc(utcDateTime).local().format('YYYY-MM-DDTHH:mm');
};

export const convertLocalToUTC = (localDateTime:any) => {
  return moment(localDateTime).utc().format();
};

export default validateEmail
