import React, { ChangeEvent }  from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  TextField,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    },
    dense: {
      marginTop: theme.spacing(2),
    },
    menu: {
      width: 200,
    }
  }),
);

export interface TopFormSearchOption {
  readonly label: string;
  readonly value: string;
}

interface TopFormSearchProps {
  readonly onSearchInputChange: (searchInput: string) => void;
  readonly onSearchTypeChange: (searchType: TopFormSearchOption) => void;
  readonly searchInput: string;
  readonly searchOptions: TopFormSearchOption[];
  readonly marginTop? : number
};

export default function TopFormSearch(props: TopFormSearchProps) {
  const classes = useStyles();

  const [ searchType, setSearchType ] = React.useState<TopFormSearchOption>(props.searchOptions[0]);

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    props.onSearchInputChange(event.target.value);
  };
  const handleSearchTypeChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const searchType: TopFormSearchOption = props.searchOptions.filter(so => so.value === event.target.value)[0];
    setSearchType(searchType);
    props.onSearchTypeChange(searchType);
  };

  const searchOptions: JSX.Element[] = props.searchOptions.map(so =>
    <FormControlLabel
      control={<Radio color="primary" />}
      key={so.value}
      label={so.label}
      value={so.value}
    />
  );

  return (
    <div className="TopFormSearch">
      <Grid item xs={12} className="contracts-search">
        <Typography variant="h3" align={'left'}>
          <TextField
            className={classes.textField}
            onChange={handleSearchInputChange}
            placeholder="Search"
            style={{ marginTop: props.marginTop == null || props.marginTop == undefined ? 60 : props.marginTop, width: '100%' }}
            value={props.searchInput}
          />
        </Typography>
        <Grid xs={12} className="radios">
          <RadioGroup
            aria-label="top-form"
            flex-direction="row"
            name="top-form"
            onChange={handleSearchTypeChange}
            value={searchType.value}
          >
            {searchOptions}
          </RadioGroup>
        </Grid>
      </Grid>
    </div>
  );
}