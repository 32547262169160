import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,
  DatePickerProps,
  DateTimePicker,
  DateTimePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

interface DateRangePickerProps {
  readonly endDate: Date | null;
  readonly handleEndDateChange: (date: Date | null) => void;
  readonly handleStartDateChange: (date: Date | null) => void;
  readonly pickTime?: boolean;
  readonly startDate: Date | null;
}

export default function DateRangePicker(props: DateRangePickerProps) {
  const endDatePickerProps: any = {
    label: 'End',
    margin: 'normal',
    minDate: props.startDate == null ? undefined : props.startDate,
    onChange: props.handleEndDateChange,
    style: { width: '80%' },
    value: props.endDate,
    variant: 'inline',
  };
  const endDatePicker: JSX.Element = props.pickTime === true
    ? <DateTimePicker {...endDatePickerProps} />
    : <DatePicker {...endDatePickerProps} />;

  const startDatePickerProps: any = {
    label: 'Start',
    margin: 'normal',
    maxDate: props.endDate == null ? undefined : props.endDate,
    onChange: props.handleStartDateChange,
    style: { width: '80%' },
    value: props.startDate,
    variant: 'inline',
  };
  const startDatePicker: JSX.Element = props.pickTime === true
    ? <DateTimePicker clearable {...startDatePickerProps} />
    : <DatePicker clearable {...startDatePickerProps} />;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid item xs={12}>
        {startDatePicker}
      </Grid>
      <Grid item xs={12}>
        {endDatePicker}
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
