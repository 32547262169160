import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { API } from 'aws-amplify';
import { getAPIName } from '../config';
import { FormControl } from '@material-ui/core';
import { makeStyles, fade } from '@material-ui/core/styles';
import { renderDealershipOptions, renderDealershipGroupOptions } from './renderDealershipOptions';

interface DealershipSelectProps {
    dealershipId: number | undefined;
    setDealershipId: (id: number | undefined) => void;
    dealershipGroupId: number | undefined;
    setDealershipGroupId: (id: number | undefined) => void;
    searchType: string | unknown
    setSearchType: (id: string | unknown) => void;
    inputProps?: { color?: string, backgroundColor?: string };
}
interface DealershipOption {
    id: number;
    name: string;
}

const DealershipSelect: React.FC<DealershipSelectProps> = ({
    dealershipId,
    setDealershipId,
    dealershipGroupId,
    setDealershipGroupId,
    searchType,
    setSearchType,
    inputProps
}) => {
    const useStyles = makeStyles((theme) => ({
        formControl: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "10px",
            width: "95%"
        },
        select: {
            color: inputProps?.color == null || inputProps.color == undefined ? 'white' : inputProps.color,
            backgroundColor: inputProps?.backgroundColor == null || inputProps.backgroundColor == undefined ? '#6f7179' : inputProps.backgroundColor,
            '&:hover': {
                backgroundColor: inputProps?.backgroundColor == null || inputProps.backgroundColor == undefined ? '#6f7179' : inputProps.backgroundColor,
            },
            '&.Mui-focused': {
                backgroundColor: inputProps?.backgroundColor == null || inputProps.backgroundColor == undefined ? '#6f7179' : inputProps.backgroundColor,
            },
            '&.MuiSelect-root': {
                backgroundColor: inputProps?.backgroundColor == null || inputProps.backgroundColor == undefined ? '#6f7179' : inputProps.backgroundColor,
            },
            '&.MuiSelect-select:focus': {
                backgroundColor: inputProps?.backgroundColor == null || inputProps.backgroundColor == undefined ? '#6f7179' : inputProps.backgroundColor,
            },
        }
    }));
    const classes = useStyles();
    const [dealershipOptions, setDealershipOptions] = useState<DealershipOption[]>([]);
    const handleDealershipOptionsDropdown = (e: any) => {
        if (searchType === 'dealership') {
            setDealershipId(e.target.value.toString());
        }
        if (searchType === 'dealershipGroup')
            setDealershipGroupId(e.target.value.toString());
    };

    const getDealerships = async () => {
        try {
            let apiName = getAPIName();
            let path = '/dealerships?orderBy-=name';
            let myInit = {};
            const response = await API.get(apiName, path, myInit);
            const results = response.body;
            setDealershipOptions(results);
        } catch (error) {
            console.log(error);
        }
    };
    const getDealershipGroups = async () => {
        try {
            let apiName = getAPIName();
            let path = '/dealershipgroups?orderBy-=name';
            let myInit = {};
            const response = await API.get(apiName, path, myInit);
            const results = response.body;
            setDealershipOptions(results);
        } catch (error) {
            console.log(error);
        }
    };
    const handleSearchTypeChange = (e: React.ChangeEvent<{ value: string | unknown }>) => {
        let type = e.target.value;
        setSearchType(type);
        if (type === 'dealership') {
            getDealerships();
        } else if (type === 'dealershipGroup') {
            getDealershipGroups();
        }
    };
    return (
        <>
            <FormControl className={classes.formControl}>
                <Select
                    value={searchType}
                    onChange={handleSearchTypeChange}
                    autoWidth={true}
                    displayEmpty
                    style={{ width: '45%' }}
                    inputProps={{
                        classes: {
                            root: classes.select,
                        },
                    }}
                >
                    <MenuItem key="0" value={''}>
                        Select Search Type
                    </MenuItem>
                    <MenuItem key={'1'} value={'dealership'}>
                        Dealership
                    </MenuItem>
                    <MenuItem key={'2'} value={'dealershipGroup'}>
                        Dealership Group
                    </MenuItem>
                </Select>

                {searchType === 'dealership' && (
                    <Select
                        style={{ width: '45%' }}
                        value={dealershipId}
                        onChange={handleDealershipOptionsDropdown}
                        autoWidth={true}
                        displayEmpty
                        inputProps={{
                            classes: {
                                root: classes.select,
                            },
                        }}
                    >
                        {dealershipOptions && renderDealershipOptions(dealershipOptions)}
                    </Select>
                )}
                {searchType === 'dealershipGroup' && (
                    <Select
                        style={{ width: '45%' }}
                        value={dealershipGroupId}
                        onChange={handleDealershipOptionsDropdown}
                        autoWidth={true}
                        displayEmpty
                        inputProps={{
                            classes: {
                                root: classes.select,
                            },
                        }}
                    >
                        {dealershipOptions && renderDealershipGroupOptions(dealershipOptions)}
                    </Select>
                )}
            </FormControl>
        </>
    );
};

export default DealershipSelect;