import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from '@reach/router';
import ListItemText from '@material-ui/core/ListItemText';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
interface NavMenuItemProps {
  icon: IconProp;
  route: string;
  label: string;
}
export const NavMenuItem = (props: NavMenuItemProps) => (
  <ListItem
    button
    key={props.route}
    onClick={() => navigate(`/${props.route}`)}
  >
    <ListItemIcon>
      {<FontAwesomeIcon icon={props.icon} size="2x" />}
    </ListItemIcon>
    <ListItemText primary={props.label} />
  </ListItem>
);
