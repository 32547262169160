import React, { useEffect, useState, ChangeEvent } from 'react';
import { API } from 'aws-amplify';
import { getAPIName } from '../../config';
import { StatusOption } from '../TopForm';
import handleApiErrors from '../../common/handleApiErrors';
import ReactTable, { Column, SortingRule } from 'react-table';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import DealershipSelect from '../dealership-select.component';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import {
    Button,
    Divider,
    Grid,
    Typography,
    CircularProgress,
    TextField
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import useCustomSnak from '../helpers/handleSnackbars';
import DateRangePicker from '../date-range-picker.component';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200
        },
        dense: {
            marginTop: theme.spacing(2),
        },
        menu: {
            width: 200,
        },
        button: {
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(5),
            width: 100
        },
        paymentlink_reactivation :{
            background: "forestgreen",
            color: "white",
            '&:hover': { 
              backgroundColor: 'darkgreen',
            },
          },
    }),
);

const statusOptionsDict: any = {
    "Charged": "Charged",
    "Created": "Created",
    "Declined": "Declined",
    "missing_cc_token": "Missing Credit Card",
    "manually_charged": "Manually Charged",
    "customer_dispute_won": "Customer Dispute - Won",
    "customer_dispute_lost": "Customer Dispute - Lost",
    "customer_dispute_initiated": "Customer Dispute - Initiated",
    "manually_refunded": "Manually Refunded",
    "integrated_invoice_sent": "Created",
    "partially_charged":"Partially Charged"
}
const paymentlinkStatusOptions: any = {
    "Complete": "Charged",
    "Created": "Created",
    "Declined": "Declined",
    "Expired": "Expired"
}
interface PageState {
    readonly page: number;
    readonly pageSize: number;
    readonly sortingRules: SortingRule[];
}
interface TopFormState {
    readonly dealershipId: number;
    readonly statusSelection?: StatusOption;
}
interface SearchState {
    readonly firstName: string;
    readonly lastName: string;
    readonly startDate?: Date;
    readonly endDate?: Date;
}

const defaultSearchState: SearchState = {
    firstName: '',
    lastName: '',
    startDate: undefined,
    endDate: undefined
};
const defaultPageState: PageState = {
    page: 0,
    pageSize: 10,
    sortingRules: [{
        id: 'id',
        desc: false,
    }],
};

export default function SubscriptionInvoices() {
    const classes = useStyles();
    const handleCustomSnak = useCustomSnak();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pageState, setPageState] = useState<PageState>(defaultPageState);
    const [topFormState, setTopFormState] = useState<TopFormState>({
        dealershipId: 0,
        statusSelection: undefined,
    });
    const [reloadData, setreloadData] = useState(false)
    const [filterState, setFilterSetState] = React.useState<SearchState>(defaultSearchState);
    // fetch stuff //////////////////////////////////////////////////////////////
    // invoices
    const [[subscription_invoices, totalSubscriptionInvoicesCount], setSubscriptionInvoices] = React.useState<[[], number]>([[], 0]);
    const [tablepages,settablepages] = useState(0)

    const searchSubscriptionInvoices = async (): Promise<void> => {
        setIsLoading(true);
        const response = await getSubscriptionInvoices();
        setSubscriptionInvoices([response.body, response.totalRecordCount]);
        settablepages(response.totalRecordCount === 0 ? 1 : Math.trunc(response.totalRecordCount / pageState.pageSize) + 1)
        setIsLoading(false);
    }

    // handlers /////////////////////////////////////////////////////////////////
    const handleTableFetchData = (state: {
        page: number;
        pageSize: number;
        sorted: SortingRule[];
    }): void => {
        setPageState({
            ...pageState,
            page: state.page,
            pageSize: state.pageSize,
            sortingRules: state.sorted,
        });
    };

    const getSubscriptionInvoices = async (paginate: boolean = true, apiInit: any = {}): Promise<any> => {
        const queries: Array<{ name: string; value: string }> = [];
        if (paginate) {
            queries.push({ name: 'limit', value: `${pageState.pageSize}` });
            queries.push({ name: 'offset', value: `${pageState.pageSize * pageState.page}` });
        }
        if (topFormState.dealershipId !== 0)
            queries.push({ name: 'contract.dealershipid', value: `${topFormState.dealershipId}` });
        if (filterState.firstName)
            queries.push({ name: 'first-name', value: `${filterState.firstName}` });
        if (filterState.lastName) 
            queries.push({ name: 'last-name', value: `${filterState.lastName}` });

        if (filterState.startDate != null){
            queries.push({ name: "gte-insertedtimestamp", value: filterState.startDate.toISOString() });
        }
        if (filterState.endDate != null){
            queries.push({ name: "lt-insertedtimestamp", value: filterState.endDate.toISOString() });
        }
        // sort
        let i: number = 0;
        for (let sortingRule of pageState.sortingRules)
            queries.push({
                name: `orderBy-${i++}${sortingRule.desc === true ? '-desc' : ''}`,
                value: `${sortingRule.id}`,
            });

        let path: string = '/api/v1/subscription/invoices';
        if (queries.length >= 1) {
            const paramStrings: string[] = queries.map(q => `${encodeURIComponent(q.name)}=${encodeURIComponent(q.value)}`);
            path = `${path}?${paramStrings.join("&")}`;
        }

        try {
            return await API.get(getAPIName(), path, apiInit);
        } catch (e) {
            handleApiErrors(e, enqueueSnackbar);
        }
    };

    const resetPaginationState = (): void => {
        setPageState({
            ...pageState,
            page: 0,
        });
    }

    const handleDealershipSelect = (id: number): void => {
        resetPaginationState();
        setTopFormState({
            ...topFormState,
            dealershipId: id,
        });
        setFilterSetState(defaultSearchState)
    };

    const handlePaymentLinkReactivation = async (paymentLinkId : string) =>{
        try {
          setIsLoading(true)
          let path = '/api/v1/paymentlink/' + paymentLinkId;
          const response: any = await API.patch(getAPIName(), path,
            {
              body: {"active":"true"}
            });
          handleCustomSnak(`PaymentLink has been reactivated`, 'success')
          setIsLoading(false)
          setreloadData(!reloadData)
        }
        catch (e) {
          handleApiErrors(e, enqueueSnackbar);
          setIsLoading(false)
        }
      }
    const handleStartDateChange = (date?: Date | null): void => {
        setFilterSetState({
            ...filterState,
            startDate: date == null ? undefined : date,
        });
    };
    const handleEndDateChange = (date?: Date | null): void => {
        setFilterSetState({
            ...filterState,
            endDate: date == null ? undefined : date,
        });
    };

    const columns: Column[] = [
        {
            Header: 'Invoice Number',
            accessor: 'id',
            Cell: (props: any) => {
                return props.original.bucketpath && (
                    <a
                        target="_blank"
                        href={
                            'https://tollaid-emailtemplates.s3.us-east-2.amazonaws.com/prod/' +
                            props.original.bucketpath
                        }
                    >
                        {props.original.id}
                    </a>
                )
            }

        },
        {
            Header: 'Invoice Until',
            accessor: 'invoice_date_to',
            Cell: (props: any) => {
                return(
                props.original.invoice_date_to == null ?
                '-'
                : 
                moment(props.original.invoice_date_to).local().format('MM/DD/YYYY h:mm a')
                )
                }
            
        },
        {
            Header: 'First Name',
            accessor: 'subscription_contracts.contract.customer.firstname'
        },
        {
            Header: 'Last Name',
            accessor: 'subscription_contracts.contract.customer.lastname'
        },
        {
            Header: 'Email',
            accessor: 'subscription_contracts.contract.customer.emailaddress'
        },
        {
            accessor: (props: any) => props.amount != null ? `$${(+props.amount).toFixed(2)}` : '—',
            Header: 'Amount',
            id: 'amount',
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: (props: any) => {
                return props.original.status ? statusOptionsDict[props.original.status] : '-'
            }
        },
        {
            Header: 'Charge Attempt Date',
            accessor: 'attempted_charge_date',
            Cell: (props: any) => {
                return(
                props.original.attempted_charge_date == null ?
                '-'
                : 
                moment(props.original.attempted_charge_date).local().format('MM/DD/YYYY h:mm a')
                )
                }
            
        },
        {
            Header: 'Dealership',
            accessor: 'subscription_contracts.contract.dealership.name'
        },
        {
            Header: 'PaymentLink',
            accessor: 'subscription_paymentlink.payment_link_url',
            sortable: false,
            Cell: (props: any) => {
                return (
                    props.original.subscription_paymentlink && props.original.subscription_paymentlink[0] && props.original.subscription_paymentlink[0].status == 'Created' ?
                    (
                        <Button
                            onClick={() => window.open(props.original.subscription_paymentlink[0].payment_link_url)}
                            color="primary" size="small" variant="contained" > Pay  </Button>
                    )
                    :
                    (
                    props.original.subscription_paymentlink && props.original.subscription_paymentlink[0] && props.original.subscription_paymentlink[0].status == 'Expired' && !['partially_charged','Charged'].includes(props.original.status)? 
                        <Button
                            className={classes.paymentlink_reactivation}
                            onClick={() => handlePaymentLinkReactivation(props.original.subscription_paymentlink[0].payment_link_id)}
                            color="primary" size="small" variant="contained" > Reactivate  </Button>

                        :
                        '-'
                    )
                )
            }
        },
        {
            Header: 'Splits',
            accessor: 'subscription_paymentlink.payment_link_url',
            sortable: false,
            Cell: (props: any) => {
                return (
                    props.original.subscription_paymentlink && props.original.subscription_paymentlink[1] && props.original.subscription_paymentlink[1].status == 'Created' ?
                    (
                        <Button
                            onClick={() => window.open(props.original.subscription_paymentlink[1].payment_link_url)}
                            color="primary" size="small" variant="contained" > Installment 1  </Button>
                    )
                    :
                    (
                        props.original.subscription_paymentlink && props.original.subscription_paymentlink[2] && props.original.subscription_paymentlink[2].status == 'Created' && props.original.subscription_paymentlink[1].status == 'Complete' ?
                        (
                            <Button
                                onClick={() => window.open(props.original.subscription_paymentlink[2].payment_link_url)}
                                color="primary" size="small" variant="contained" > Installment 2  </Button>
                        )
                        :
                        '-'
                    )
                )
            }
        }
    ]

    const [csvIsLoading, setCsvIsLoading] = useState<boolean>(false);
    // handlers /////////////////////////////////////////////////////////////////
    const handleCsvButton = async (): Promise<void> => {
        setCsvIsLoading(true);
        const response = await getSubscriptionInvoices(false, { headers: { accept: 'text/csv' }, responseType: 'text' });
        // fileDownload(response, 'data.csv');
        const currentDate = moment().format('YYYYMMDD-hhmmss')
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(response);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "SubscriptionInvoiceSummary" + currentDate + fileExtension);
        setCsvIsLoading(false);
    };

    const handleFirstNameInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setFilterSetState({...filterState,firstName: event.target.value});
    };
    const handleLastNameInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setFilterSetState({...filterState,lastName: event.target.value});
    };


    useEffect(() => { searchSubscriptionInvoices(); }, [pageState, reloadData]);

    // render ///////////////////////////////////////////////////////////////////
    return (
        <div className="SubscriptionInvoices">
            <Grid container>
                <Grid item xs={9}>
                    <Typography variant="h4" align="left">
                        Subscription Invoices
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Button size="small" variant="contained" color="primary" onClick={handleCsvButton}>
                        {!csvIsLoading ? 'Download Excel' :
                            <CircularProgress size={24} />
                        }
                    </Button>
                </Grid>
            </Grid>
            <div className="TopForm">
                <Divider />
                <Grid container spacing={2}>
                    <Grid item sm={3} xs={12}>
                        <DealershipSelect
                            dealershipId={topFormState.dealershipId}
                            onChangeDealership={handleDealershipSelect}
                        />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <Typography variant="h3" align={'left'}>
                            <TextField
                                className={classes.textField}
                                onChange={handleFirstNameInputChange}
                                placeholder="First Name"
                                style={{ marginTop: 60, width: '100%' }}
                                value={filterState.firstName}
                            />
                        </Typography>
                    </Grid>
                    <Grid item sm={3} xs={6}>
                        <Typography variant="h3" align={'left'}>
                            <TextField
                                className={classes.textField}
                                onChange={handleLastNameInputChange}
                                placeholder="Last Name"
                                style={{ marginTop: 60, width: '100%' }}
                                value={filterState.lastName}
                            />
                        </Typography>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <DateRangePicker
                            endDate={filterState.endDate == null ? null : filterState.endDate}
                            handleEndDateChange={handleEndDateChange}
                            handleStartDateChange={handleStartDateChange}
                            pickTime={true}
                            startDate={filterState.startDate == null ? null : filterState.startDate}
                        />
                    </Grid>
                </Grid>
                
                <Grid item xs={12}>
                    <Button 
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={searchSubscriptionInvoices}
                    >
                        Search
                    </Button>
                </Grid>

            </div>
            <Grid container style={{marginTop:'20px'}}>
                <Grid xs={12}>
                    <ReactTable
                        className="-striped -highlight"
                        columns={columns}
                        data={subscription_invoices}
                        defaultPageSize={10}
                        defaultSorted={defaultPageState.sortingRules}
                        loading={isLoading}
                        manual
                        onFetchData={handleTableFetchData}
                        pages={tablepages}
                    />
                </Grid>
            </Grid>
        </div>
    );
}