import React, { useEffect, useRef, useState, useContext } from 'react';
import { AmplifyAuthContext } from '../../App';
import { API } from 'aws-amplify';
import { makeStyles, fade } from '@material-ui/core/styles';
import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import Dealership from '../../_models/Dealership';
import DealershipSelect from '../DealershipSelect';
import { getAPIName } from '../../config';
import handleApiErrors from '../../common/handleApiErrors';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCustomSnack from '../helpers/handleSnackbars'
import DatePicker, { DateObject } from "react-multi-date-picker"
import moment from 'moment';
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  faFileExcel,
  faFileInvoice
} from '@fortawesome/free-solid-svg-icons';
library.add(
  faFileExcel as IconDefinition,
  faFileInvoice as IconDefinition
);
const useStyles = makeStyles((theme) => ({
  today: {
    '& .rmdp-day.rmdp-today span': {
      backgroundColor: 'white',
      color: 'black',
      boxShadow: 'none'
    },
    '& .rmdp-day.rmdp-selected span:not(.highlight)': {
      backgroundColor: '#0074d9',
      boxShadow: '0 0 3px #8798ad',
      color: '#fff'
    }
  },
  iconSpacing: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(0.5)
  },
  table: {
    border: '1px solid #ccc',
    // boxShadow: '5px 10px 5px #b3b1b175;',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
    borderCollapse: 'collapse',
    fontSize: '1.2em',
    width: '44%',
  },
  cell: {
    border: '1px solid #ccc',
    padding: '12px',
    textAlign: 'left',
  },
  valueCell: {
    border: '1px solid #ccc',
    padding: '12px',
    textAlign: 'center',
  },
  totalRow: {
    border: '1px solid #ccc',
    padding: '12px',
    backgroundColor: '#333',
    color: 'white',
    textAlign: 'left',
  },
  totalValueCell: {
    border: '1px solid #ccc',
    padding: '12px',
    backgroundColor: '#333',
    color: 'white',
    textAlign: 'center',
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 5,
  },
  grow: {
    flexGrow: 1,
  },
  subMenu: {
    backgroundColor: '#484a54',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
}));

interface FinancialSummaryItem {
  id: number;
  tolls_sum: string;
  invoices_sum: string;
  invoices_sum_via_paymentlinks: string;
  pgw_fees: string;
  pgw_fees_from_paymentlinks: string;
  service_fees: string | null;
  statement_total: string;
  active_vehicles_count: number;
  lost_dispute_total: number;
  dispute_count:number;
  dispute_fee_total:number;
  tolls_amount_sum:number;
  month:number;
}

const FinancialReport = () => {
  const classes = useStyles();
  const handleCustomSnack = useCustomSnack();
  const [financialSummary, setFinancialSummary] = useState<FinancialSummaryItem[] | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [searchType, setSearchType] = useState<string | unknown>('');
  const [dealershipId, setDealershipId] = useState<number | undefined>(0);
  const [dealershipGroupId, setDealershipGroupId] = useState<number | undefined>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
  const [selectedYear, setSelectedYear] = useState<string | null>(null);
  const minDate = new Date(2000, 0, 1)
  const currentAuthenticatedUser: any = useContext(AmplifyAuthContext);
  const userRole: string =
  currentAuthenticatedUser == null ||
    currentAuthenticatedUser.attributes == null ||
    currentAuthenticatedUser.attributes.given_name == null
    ? 'Individual'
    : currentAuthenticatedUser.attributes.given_name;

  const handleMonthChange = (dateObject: DateObject) => {
    const monthIndex = new Date(Date.parse(`1 ${dateObject}`)).getMonth() + 1; // Parse month name and extract month index
    const selectedMonth = monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`; // Ensure two-digit format
    setSelectedMonth(selectedMonth);
  }
  const handleYearChange = (dateObject: DateObject) => {
    const dateObjectString = (dateObject.toString());
    setSelectedYear(dateObjectString)
  }
  const getMonthName = (month: number | null) => month ? moment(month, 'MM').format('MMMM').toUpperCase() : '';
  // Search Financial Summary
  const handleSearch = async (): Promise<void> => {
    if (searchType === '') {
      handleCustomSnack('select dealership or dealership-group', 'warning')
      return
    }
    if ((searchType === 'dealership' && dealershipId === 0) || (searchType === 'dealershipGroup' && dealershipGroupId === 0)) {
      const errorMessage = searchType === 'dealership' ? "Please select a dealership" : "Please select a dealership group";
      handleCustomSnack(errorMessage, 'warning')
      return;
    }
    if (selectedMonth === null) {
      handleCustomSnack("please select the month", 'warning')
      return
    }
    if (selectedYear === null) {
      handleCustomSnack("please select the year", 'warning')
      return
    }
    if (isNaN(parseInt(selectedMonth)) || parseInt(selectedMonth) < 1 || parseInt(selectedMonth) > 12 || selectedMonth === undefined) {
      handleCustomSnack("Invalid month selected", "warning");
      return
    } 
    if (isNaN(parseInt(selectedYear))  || parseInt(selectedYear) < 2000 || selectedYear === undefined) {
      handleCustomSnack("Invalid Year selected", "warning");
      return
    } 
  
    setIsLoading(true);
    let queries: Array<{ name: string; value: string }> = [];
    queries.push({ name: 'type', value: `${searchType}` })
    if (dealershipId != 0 && searchType === 'dealership')
      queries.push({ name: 'dealershipid', value: `${dealershipId}` });
    if (dealershipGroupId != 0 && searchType === 'dealershipGroup')
      queries.push({ name: 'dealershipgroupid', value: `${dealershipGroupId}` });
    queries.push({ name: 'month', value: `${selectedMonth}` })
    queries.push({ name: 'year', value: `${selectedYear}` })
    let path: string = '/api/v1/financialsummary';
    if (queries.length > 0) {
      const paramStrings: string[] = queries.map(q => `${encodeURIComponent(q.name)}=${encodeURIComponent(q.value)}`);
      path = `${path}?${paramStrings.join("&")}`;
    }
    try {
      const response = await API.get(getAPIName(), path, {});
      if (response && response.error) {
        handleCustomSnack(response?.error, 'warning')
        setFinancialSummary(null);
      } else {
        setFinancialSummary(response.body);
      }
    } catch (e) {
      setFinancialSummary(null)
      handleApiErrors(e, enqueueSnackbar);
    } finally {
      setIsLoading(false);
    }
  };
  // Fetch Presigned URL from API's
  const downloadFile = async (filetype: string, apiInit: any = {}) => {
    try {
      const id = financialSummary !== null ? financialSummary[0].id : null;
      const path = `/api/v1/financialsummary/download/${id}?filetype=${filetype}`;
      const response = await API.get(getAPIName(), path, apiInit);
      if (response && response.presignedUrl) {
        window.open(response.presignedUrl, '_blank');
        handleCustomSnack(`File Downloaded Successfully`, 'success')
      }
      else if (response && response.error) {
        handleCustomSnack(response?.error, 'warning')
      }
    } catch (error) {
      handleApiErrors(error, enqueueSnackbar);
    }
  }
  const handleStatementDownload = () => {
    downloadFile('statement', {})
  }
  const handleBillDownload = () => {
    downloadFile('bill', {})
  }

  return (
    <div className='tollreports'>
      <Grid item xs={9}>
        <Typography variant="h4" align="left">
          Financial Reports <sup style={{ color: '#3f51b5', fontSize: '15px' }}>(Beta)</sup>
        </Typography>
      </Grid>
      <Divider />
      <div className={classes.grow}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.subMenu}
        >
          <Grid item xs={12} md={6}>
          <DealershipSelect
            dealershipId={dealershipId}
            setDealershipId={setDealershipId}
            dealershipGroupId={dealershipGroupId}
            setDealershipGroupId={setDealershipGroupId}
            searchType={searchType}
            setSearchType={setSearchType}
          />
          </Grid>
          <div className={classes.grow} />
          <Grid xs={12} md={3} className={classes.today}>
            <label style={{ color: 'white' }}>Select Month: </label>
            <DatePicker
              onlyMonthPicker
              hideYear
              buttons={false}
              hideMonth
              format='MMMM'
              sort
              editable = {false}   
              onChange={handleMonthChange}
            />
          </Grid>
          <Grid xs={12} md={3} className={classes.today}>
            <label style={{ color: 'white' }}>Select Year: </label>
            <DatePicker
              onlyYearPicker
              hideMonth
              format='YYYY'
              sort
              editable={false}
              minDate={minDate}
              onChange={handleYearChange}           
            />
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearch}
            >
              Search
            </Button>
          </Grid>
        </Grid>
        <br />
      </div>
      {
        isLoading ? (
          <>
            <div className={classes.loaderContainer}><CircularProgress /></div>
          </>
        ) : (
          financialSummary === null ?
            (
              <>
              </>
            )
            :
            (
              <>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px", flexDirection: "column" }}>
                  {/* Financial Summary Table */}
                  <table className={classes.table} >
                    <thead>
                      <tr>
                        <th className={classes.cell}>ITEM</th>
                        <th className={classes.valueCell}>{`${financialSummary[0]?.month ? getMonthName(financialSummary[0].month).toUpperCase() + ' ' : ''}MONEY IN/OUT`}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                       userRole === 'Admin' && (
                        <tr className={classes.cell}>
                          <td className={classes.cell}>TOLLS PAID (internal)</td>
                          <td className={classes.valueCell}> ${" "}{financialSummary[0]?.tolls_amount_sum === null ? '0.0' : financialSummary[0]?.tolls_amount_sum}</td>
                        </tr>
                       )
                      }
                      <tr className={classes.cell}>
                        <td className={classes.cell}>TOLLS PAID</td>
                        <td className={classes.valueCell}> ${" "}{financialSummary[0]?.tolls_sum === null ? '' : financialSummary[0]?.tolls_sum}</td>
                      </tr>
                      <tr className={classes.cell}>
                        <td className={classes.cell}>INVOICES CHARGED VIA CC ON FILE</td>
                        <td className={classes.valueCell}> ${" "}{financialSummary[0]?.invoices_sum === null ? '' : financialSummary[0]?.invoices_sum}</td>
                      </tr>
                      <tr className={classes.cell}>
                        <td className={classes.cell}>INVOICES CHARGED VIA PAYMENT LINKS</td>
                        <td className={classes.valueCell}> ${" "}{financialSummary[0]?.invoices_sum_via_paymentlinks === null ? '' : financialSummary[0]?.invoices_sum_via_paymentlinks}</td>
                      </tr>
                      <tr className={classes.cell}>
                        <td className={classes.cell}>PAYMENT GATEWAY FEES FROM CC ON FILE</td>
                        <td className={classes.valueCell}> ${" "}{financialSummary[0]?.pgw_fees === null ? '' : financialSummary[0]?.pgw_fees}</td>
                      </tr>
                      <tr className={classes.cell}>
                        <td className={classes.cell}>PAYMENT GATEWAY FEES FROM PAYMENT LINKS</td>
                        <td className={classes.valueCell}> ${" "}{financialSummary[0]?.pgw_fees_from_paymentlinks === null ? '' : financialSummary[0]?.pgw_fees_from_paymentlinks}</td>
                      </tr>
                      <tr className={classes.cell}>
                        <td className={classes.cell}>LOST DISPUTES</td>
                        <td className={classes.valueCell}> ${" "}{financialSummary[0]?.lost_dispute_total === null ? '0.0' : financialSummary[0]?.lost_dispute_total}</td>
                      </tr>
                      <tr className={classes.cell}>
                        <td className={classes.cell}>DISPUTE FEES</td>
                        <td className={classes.valueCell}> ${" "}{financialSummary[0]?.dispute_fee_total === null ? '0.0' : financialSummary[0]?.dispute_fee_total}</td>
                      </tr>
                      <tr className={classes.totalRow}>
                        <td className={classes.totalRow}>TOTAL (CREDIT IF NEGATIVE) - <i>Excludes Monthly Service Fee</i></td>
                        <td className={classes.totalValueCell}> ${" "}{financialSummary[0]?.statement_total === null ? '' : financialSummary[0]?.statement_total}</td>
                      </tr>
                    </tbody>
                  </table>
                  {/* Download Buttons */}
                  <div style={{ marginTop: "60px", display: "flex", flexDirection: "row", gap: "60px" }}>
                    <Button variant="contained" color="primary" onClick={handleStatementDownload} endIcon={<FontAwesomeIcon className={classes.iconSpacing} icon={faFileExcel} />}>
                      Download XL
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleBillDownload} endIcon={<FontAwesomeIcon className={classes.iconSpacing} icon={faFileExcel} />}>
                      Download Bill
                    </Button>
                  </div>
                </div>
                <br/>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px", flexDirection: "column" }}>
                  {/* Couts Table */}
                  <table className={classes.table} >
                    <thead>
                      <tr>
                        <th className={classes.cell}>ITEM</th>
                        <th className={classes.valueCell}>COUNT</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className={classes.cell}>
                        <td className={classes.cell}>ACTIVE VEHICLES</td>
                        <td className={classes.valueCell}> {financialSummary[0]?.active_vehicles_count === null ? '0' : financialSummary[0]?.active_vehicles_count}</td>
                      </tr>
                      <tr className={classes.cell}>
                        <td className={classes.cell}>DISPUTES</td>
                        <td className={classes.valueCell}> {financialSummary[0]?.dispute_count === null ? '0' : financialSummary[0]?.dispute_count}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )
        )
      }
    </div>
  )
}

export default FinancialReport