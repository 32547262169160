import React, { useEffect, useRef, useState } from 'react';
import { API } from 'aws-amplify';
import { makeStyles, fade } from '@material-ui/core/styles';
import DealershipSelect from '../DealershipSelect';
import { getAPIName } from '../../config';
import handleApiErrors from '../../common/handleApiErrors';
import { useSnackbar } from 'notistack';
import useCustomSnack from '../helpers/handleSnackbars'
import DateRangePicker from '../date-range-picker.component';
import RadioFormSearch from '../RadioForm';
import { RadioSearchOptions } from '../RadioForm';
import ReactTable, { Column, SortingRule } from 'react-table';
import TopFormSearch from '../TopFormSearch';
import moment from "moment-timezone";
import { TopFormSearchOption } from '../TopFormSearch';
import { ParkingTicket } from '../../_models/ParkingTicket';
import {
    Button,
    Divider,
    Grid,
    Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
}));

const searchOptions: TopFormSearchOption[] = [
    { label: 'License Plate', value: 'license-plate' },
    { label: 'Citation Number', value: 'citation-number' },
    { label: 'VIN', value: 'vin' },
]

const timestampTypeSearchOption: RadioSearchOptions[] = [
    { label: 'Issue Time', value: 'issue_timestamp' },
    { label: 'Inserted Time', value: 'inserted_timestamp' },
]
interface ParkingTicketState {
    readonly dealershipId: number;
    readonly endDate?: Date;
    readonly searchInput: string;
    readonly searchType: TopFormSearchOption;
    readonly startDate?: Date;
    readonly timeSearchType?: RadioSearchOptions;
}
const defaultState: ParkingTicketState = {
    dealershipId: 0,
    searchInput: '',
    searchType: searchOptions[0],
    timeSearchType: timestampTypeSearchOption[0]
};
interface PaginationState {
    readonly page: number;
    readonly pageSize: number;
    readonly sortingRules: SortingRule[];
}
const DEFAULT_PAGE_SIZE: number = 10;
const defaultPaginationState: PaginationState = {
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    sortingRules: [{
        id: 'id',
        desc: false,
    }],
};

const ParkingTickets = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [searchType, setSearchType] = useState<string | unknown>('');
    const [dealershipId, setDealershipId] = useState<number | undefined>(0);
    const [dealershipGroupId, setDealershipGroupId] = useState<number | undefined>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [state, setState] = React.useState<ParkingTicketState>(defaultState);
    const [paginationState, setPaginationState] = React.useState<PaginationState>(defaultPaginationState);
    const [[parkingtickets, totalTicketCount], setParkingTicket] = React.useState<[ParkingTicket[], number]>([[], 0]);

    const handleSearchInputChange = (searchInput: string): void => {
        resetPaginationState();
        setState({
            ...state,
            searchInput: searchInput.toUpperCase(),
        });
    };
    const handleSearchTypeChange = (searchType: TopFormSearchOption): void => {
        resetPaginationState();
        setState({
            ...state,
            searchType: searchType,
        });
    };
    const handleTimeSearchInputChange = (searchInput: RadioSearchOptions): void => {
        resetPaginationState();
        setState({
            ...state,
            timeSearchType: searchInput,
        });
    };
    const handleStartDateChange = (date?: Date | null): void => {
        resetPaginationState();
        setState({
            ...state,
            startDate: date == null ? undefined : date,
        });
    };
    const handleEndDateChange = (date?: Date | null): void => {
        resetPaginationState();
        setState({
            ...state,
            endDate: date == null ? undefined : date,
        });
    };
    const handleTableFetchData = (state: { page: number; pageSize: number, sorted: SortingRule[] }): void => {
        setPaginationState({
            ...paginationState,
            page: state.page,
            pageSize: state.pageSize,
            sortingRules: state.sorted,
        });
    }
    const resetPaginationState = (): void => {
        setPaginationState({
            ...paginationState,
            page: 0,
        });
    }
    const getParkingTickets = async (paginate: boolean = true, apiInit: any = {}): Promise<any> => {
        const queries: Array<{ name: string; value: string }> = paginate == false
            ? []
            : [
                { name: 'limit', value: `${paginationState.pageSize}` },
                { name: 'offset', value: `${paginationState.pageSize * paginationState.page}` },
            ];
        if (dealershipId != 0 && searchType === 'dealership')
            queries.push({ name: 'dealershipid', value: `${dealershipId}` });
        if (dealershipGroupId != 0 && searchType === 'dealershipGroup')
            queries.push({ name: 'dealership.dealershipgroupid', value: `${dealershipGroupId}` });
        if (state.searchInput !== '') {
            switch (state.searchType.value.toLowerCase()) {
                case 'license-plate':
                    queries.push({ name: 'vehicle.licenseplate', value: state.searchInput });
                    break;
                case 'citation-number':
                    queries.push({ name: 'citation_number', value: state.searchInput });
                    break;
                case 'vin':
                    queries.push({ name: 'vehicle.vin', value: state.searchInput });
                    break;
            }
        }
        if (state.startDate != null) {
            const start_filter = state.timeSearchType?.value == 'inserted_timestamp' ? 'gte-insertedtimestamp' : 'gte-issuedate'
            queries.push({ name: start_filter, value: state.startDate.toISOString() });
        }
        if (state.endDate != null) {
            const end_filter = state.timeSearchType?.value == 'inserted_timestamp' ? 'lt-insertedtimestamp' : 'lt-issuedate'
            queries.push({ name: end_filter, value: state.endDate.toISOString() });
        }
        // sort
        let i: number = 0;
        for (let sortingRule of paginationState.sortingRules)
            queries.push({ name: `orderBy-${i++}${sortingRule.desc === true ? '-desc' : ''}`, value: `${sortingRule.id}` });

        let path: string = '/api/v1/parkingtickets';
        if (queries.length > 0) {
            const paramStrings: string[] = queries.map(q => `${encodeURIComponent(q.name)}=${encodeURIComponent(q.value)}`);
            path = `${path}?${paramStrings.join("&")}`;
        }
        try {
            return await API.get(getAPIName(), path, apiInit);
        } catch (e) {
            handleApiErrors(e, enqueueSnackbar);
            return [[], 0];
        }
    };
    const searchParkingTickets = async (): Promise<void> => {
        setIsLoading(true);
        const response = await getParkingTickets(true, {});
        setParkingTicket([response.body, response.totalRecordCount])
        setIsLoading(false);
    }
    useEffect(() => { searchParkingTickets(); }, [paginationState.page, paginationState.pageSize, paginationState.sortingRules]);
    // JSX.Element preprocessing /////////////////////////////////////////////////
    let pages: number = totalTicketCount === 0 || paginationState.pageSize === 0
        ? 1
        : Math.trunc(totalTicketCount / paginationState.pageSize)
    if (
        pages > 0
        && totalTicketCount > paginationState.pageSize
        && totalTicketCount % paginationState.pageSize > 0
    )
        ++pages;
    const columns: Column[] = [
        {
            accessor: (parkingticket: ParkingTicket) => parkingticket.vehicle?.licenseplate == null
                ? '—'
                : parkingticket.vehicle?.licenseplate,
            Header: 'License Plate',
            id: 'vehicle.licenseplate',
        },
        {
            accessor: (parkingticket: ParkingTicket) => parkingticket.vehicle?.state == null
                ? '—'
                : parkingticket.vehicle?.state,
            Header: 'State',
            id: 'vehicle.state',
        },
        {
            accessor: (parkingticket: ParkingTicket) => parkingticket.vehicle?.vin == null
                ? '—'
                : parkingticket.vehicle?.vin,
            Header: 'VIN',
            id: 'vehicle.vin',
        },
        {
            accessor: (parkingticket: ParkingTicket) => parkingticket.citation_number == null
                ? '—'
                : parkingticket.citation_number,
            Header: 'Citation Number',
            id: 'citation_number',
        },
        {
            accessor: (parkingticket: ParkingTicket) => parkingticket.is_towable !== null && parkingticket.is_towable
                ? 'Yes'
                : 'No',
            Header: 'Is Towable',
            id: 'is_towable',
        },
        {
            accessor: (parkingticket: ParkingTicket) => parkingticket.issue_date == null
                ? '—'
                : moment(parkingticket.issue_date).local().format('MM/DD/YYYY h:mm a'),
            Header: 'Issue Date',
            id: 'issue_date',
        },
        {
            accessor: (parkingticket: ParkingTicket) => parkingticket.amount == null
                ? '—'
                : `$${(+parkingticket.amount).toFixed(2)}`,
            Header: 'Amount',
            id: 'amount',
        },
        {
            accessor: (parkingticket: ParkingTicket) => parkingticket.due_date == null
                ? '—'
                : moment(parkingticket.due_date).local().format('MM/DD/YYYY h:mm a'),
            Header: 'Due Date',
            id: 'due_date',
        },
        {
            accessor: (parkingticket: ParkingTicket) => parkingticket.status == null
                ? '—'
                : parkingticket.status,
            Header: 'Status',
            id: 'status',
        },
    ];
    return (
        <div className='parkingtickets'>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h4" align="left">
                        Parking Tickets
                    </Typography>
                </Grid>
                <Divider />
                <div className={classes.grow}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item md={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DealershipSelect
                                dealershipId={dealershipId}
                                setDealershipId={setDealershipId}
                                dealershipGroupId={dealershipGroupId}
                                setDealershipGroupId={setDealershipGroupId}
                                searchType={searchType}
                                setSearchType={setSearchType}
                                inputProps={
                                    {
                                        color: 'black',
                                        backgroundColor: ''
                                    }
                                }
                            />
                        </Grid>
                        <div className={classes.grow} />
                        <Grid item xs={12} md={3}>
                            <TopFormSearch
                                onSearchInputChange={handleSearchInputChange}
                                onSearchTypeChange={handleSearchTypeChange}
                                searchInput={state.searchInput}
                                searchOptions={searchOptions}
                                marginTop={30}
                            />
                        </Grid>
                        <Grid xs={12} md={3}>
                            <DateRangePicker
                                endDate={state.endDate == null ? null : state.endDate}
                                handleEndDateChange={handleEndDateChange}
                                handleStartDateChange={handleStartDateChange}
                                pickTime={true}
                                startDate={state.startDate == null ? null : state.startDate}
                            />
                            <RadioFormSearch
                                onSearchTypeChange={handleTimeSearchInputChange}
                                radioSearchOptions={timestampTypeSearchOption}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={searchParkingTickets}
                            >
                                Search
                            </Button>
                        </Grid>

                    </Grid>
                    <br />
                </div>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <ReactTable
                        className="-striped -highlight"
                        columns={columns}
                        data={parkingtickets}
                        defaultPageSize={DEFAULT_PAGE_SIZE}
                        defaultSorted={defaultPaginationState.sortingRules}
                        loading={isLoading}
                        manual
                        onFetchData={handleTableFetchData}
                        page={paginationState.page}
                        pages={pages}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default ParkingTickets