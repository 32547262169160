import React, { ChangeEvent, useEffect } from 'react';
import TopFormSearch, { TopFormSearchOption } from './TopFormSearch';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Divider,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  Container,
  Button
} from '@material-ui/core';
import DateRangePicker from './date-range-picker.component';
import Dealership from '../_models/Dealership';
import DealershipSelect from './dealership-select.component';

import { API } from 'aws-amplify';
import { getAPIName } from '../config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    },
    dense: {
      marginTop: theme.spacing(2)
    },
    menu: {
      width: 200
    },
    gapBetweenSearchBtn:{
      marginBottom:'10px'
    }
  })
);

export interface TopFormState {
  readonly dealershipId: number;
  readonly endDate: Date | null;
  readonly searchInput: string;
  readonly searchType: TopFormSearchOption;
  readonly startDate: Date | null;
  readonly statusSelection?: StatusOption;
  readonly authTokenStatusSelection?: StatusOption;
}

export interface StatusOption {
  readonly name: string;
  readonly value: string;
}

export interface TopFormProperties {
  readonly onChange: (state: TopFormState) => void;
  readonly onSearch: () => void;
  readonly searchOptions: TopFormSearchOption[];
  readonly state: TopFormState;
  readonly statusOptions: StatusOption[];
  readonly authTokenStatusOptions?: StatusOption[];
}

export default function TopForm(props: TopFormProperties) {
  const classes = useStyles();

  // handlers /////////////////////////////////////////////////////////////////
  const handleStartDateChange = (date: Date | null) => {
    props.onChange({
      ...props.state,
      startDate: date
    });
  };
  const handleEndDateChange = (date: Date | null) => {
    props.onChange({
      ...props.state,
      endDate: date
    });
  };
  const handleDealershipDropdown = (id: number) => {
    props.onChange({
      ...props.state,
      dealershipId: id
    });
  };
  const handleStatusDropdown = (event: any) => {
    props.onChange({
      ...props.state,
      statusSelection: event.target.value === ''
        ? undefined
        : props.statusOptions.filter(so => so.value === event.target.value)[0],
    });
  };
  const handleAuthTokenStatusDropdown = (event: any) => {
    let authTokenStatusSelection = (props.authTokenStatusOptions === null || props.authTokenStatusOptions === undefined
      || event.target.value === '') ?
    undefined :
    props.authTokenStatusOptions.filter(so => so.value === event.target.value)[0];

    props.onChange({
      ...props.state,
      authTokenStatusSelection: authTokenStatusSelection
    });
  };
  const handleSearchInputChange = (searchInput: string): void => {
    props.onChange({
      ...props.state,
      searchInput: ['VIN', 'License Plate'].includes(props.state.searchType.label) ? searchInput.toUpperCase() : searchInput
    });
  };
  const handleSearchTypeChange = (searchType: TopFormSearchOption): void => {
    props.onChange({
      ...props.state,
      searchType: searchType
    });
  };

  // JSX.Element preprocessing /////////////////////////////////////////////////
  const statusOptionMenuItems = props.statusOptions.map(o =>
    <MenuItem key={o.value} value={o.value}>{o.name}</MenuItem>
  );
  statusOptionMenuItems.unshift(
    <MenuItem key="" value=""></MenuItem>
  );

  const authTokenStatusOptionMenuItems = (props.authTokenStatusOptions === null || props.authTokenStatusOptions === undefined) ?
  undefined
  : props.authTokenStatusOptions.map(o =>
    <MenuItem key={o.value} value={o.value}>{o.name}</MenuItem>
  );

  if (authTokenStatusOptionMenuItems !== null && authTokenStatusOptionMenuItems !== undefined){
    authTokenStatusOptionMenuItems.unshift(
      <MenuItem key="" value=""></MenuItem>
    );
  }
  

  return (
    <div className="TopForm">
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <DealershipSelect
            dealershipId={props.state.dealershipId}
            onChangeDealership={handleDealershipDropdown}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel htmlFor="statusDropdown" shrink={false}>
              Status
            </InputLabel>
            {/* We were using "zero width space" AKA \\u200B as a hidden break here. */}
            {/* Adding explicit breaks until we fix with CSS */}
            <br />
            <br />
            <br />
            <Select
              inputProps={{
                name: 'status',
                id: 'statusDropdown'
              }}
              onChange={handleStatusDropdown}
              value={props.state.statusSelection == null ? '' : props.state.statusSelection.value}
            >
              {statusOptionMenuItems}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} md={3}>
          <TopFormSearch
            onSearchInputChange={handleSearchInputChange}
            onSearchTypeChange={handleSearchTypeChange}
            searchInput={props.state.searchInput}
            searchOptions={props.searchOptions}
          />
        </Grid>

        <Grid item xs={6} md={3}>
          <DateRangePicker
            endDate={props.state.endDate || null}
            handleEndDateChange={handleEndDateChange}
            handleStartDateChange={handleStartDateChange}
            pickTime={true}
            startDate={props.state.startDate || null}
          />
        </Grid>
        {props.authTokenStatusOptions && 
        <Grid item xs={6} md={3}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel htmlFor="authTokenstatusDropdown" shrink={false}>
              Auth Status
            </InputLabel>
            {/* We were using "zero width space" AKA \\u200B as a hidden break here. */}
            {/* Adding explicit breaks until we fix with CSS */}
            <br />
            <br />
            <br />
            <Select
              inputProps={{
                name: 'authTokenstatus',
                id: 'authTokenstatusDropdown'
              }}
              onChange={handleAuthTokenStatusDropdown}
              value={props.state.authTokenStatusSelection == null ? '' : props.state.authTokenStatusSelection.value}
            >
              {authTokenStatusOptionMenuItems}
            </Select>
          </FormControl>
        </Grid>
        }
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={props.onSearch} className={classes.gapBetweenSearchBtn}>
            Search
          </Button>
        </Grid>

      </Grid>
    </div>
  );
}
