import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Grid
} from '@material-ui/core';
import moment from 'moment';


export default function ConfirmationPopup(props: any) {

  const handleClose = () => {
    props.toggleOpen();
  };
  const handleAgree = () => {
    props.confirmedAction();
    handleClose();
  }
  return (
    <div>
      <Dialog
        open={props.isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Contract Summary"}</DialogTitle>
        <DialogContent>

          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>Status</Grid><Grid item xs={8}>: {props.data.type ? props.data.type : "N/A"}</Grid>
                <Grid item xs={4}>First Name</Grid><Grid item xs={8}>: {props.data.firstName ? props.data.firstName : "N/A"}</Grid>
                <Grid item xs={4}>Last Name</Grid><Grid item xs={8}>: {props.data.lastName ? props.data.lastName : "N/A"}</Grid>
                <Grid item xs={4}>Email</Grid><Grid item xs={8}>: {props.data.email ? props.data.email : "N/A"}</Grid>
                <Grid item xs={4}>Service Advisor ID</Grid><Grid item xs={8}>: {props.data.serviceAdvisorId ? props.data.serviceAdvisorId : "N/A"}</Grid>
                <Grid item xs={4}>RO Number</Grid><Grid item xs={8}>: {props.data.roNumber ? props.data.roNumber : 'N/A'}</Grid>
                <Grid item xs={4}>Loaner In</Grid><Grid item xs={8}>: { props.data.dateIn ? moment(props.data.dateIn).local().format('MM/DD/YYYY h:mm a') : 'N/A'}</Grid>
                <Grid item xs={4}>Loaner Out</Grid><Grid item xs={8}>: { props.data.dateOut ? moment(props.data.dateOut).local().format('MM/DD/YYYY h:mm a') : 'N/A'}</Grid>
                <Grid item xs={4}>Licenseplate</Grid><Grid item xs={8}>: {props.data.licenseplate ? props.data.licenseplate : "N/A"}</Grid>
                <Grid item xs={4}>Licenseplate state</Grid><Grid item xs={8}>: {props.data.state ? props.data.state : "N/A"}</Grid>

            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={handleAgree} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}